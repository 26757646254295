import { FunctionComponent, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  FormControl,
  FormLabel,
  FormLabelProps,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  StyleProps,
} from '@chakra-ui/react';
import { CaretDownIcon16x16 } from '../Icons/Icons';
import { useDeliveryRules } from '../../hooks/useStores';
import { format } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './react-date-theme.scss'; // theme css file
import { isEmpty } from 'lodash';

import { Calendar } from 'react-date-range';

export interface DateInputProps extends StyleProps {
  id: string;
  label: string;
  labelProps: FormLabelProps;
  value?: Date;
  onChange: (date: Date) => void;
  fontSize?: string;
  enforceDeliveryRules?: boolean;
}

export type PureDateInputProps = {
  deliveryRulesEnabled?: boolean;
  deliveryRules?: any;
} & DateInputProps;

const DateInput: FunctionComponent<DateInputProps> = ({
  enforceDeliveryRules,
  ...props
}) => {
  const { deliveryRules, deliveryRulesEnabled, getDeliveryRules } =
    useDeliveryRules();

  useEffect(() => {
    if (enforceDeliveryRules && isEmpty(deliveryRules)) {
      getDeliveryRules();
    }
  }, [enforceDeliveryRules]);

  return <PureDateInput
    enforceDeliveryRules={enforceDeliveryRules}
    deliveryRules={deliveryRules}
    deliveryRulesEnabled={deliveryRulesEnabled}
    {...props}
  />;
};

export const PureDateInput: FunctionComponent<PureDateInputProps> = ({
  id,
  label,
  labelProps,
  onChange,
  enforceDeliveryRules,
  deliveryRules,
  deliveryRulesEnabled,
  value = new Date(),
  fontSize = 'md',
  ...props
}) => {
  const [currentDate, setCurrentDate] = useState(value);

  const shouldUseDeliveryRules =
    enforceDeliveryRules && deliveryRulesEnabled && deliveryRules !== undefined;

  return (
    <Popover placement="bottom-start">
      {({ isOpen, onClose }) => (
        <>
          <FormControl {...props}>
            <FormLabel {...labelProps}>{label}</FormLabel>
            <PopoverTrigger>
              <InputGroup>
                <Input
                  cursor="pointer"
                  placeholder={label}
                  value={format(currentDate, 'EEEE d MMMM y')} // Friday 20 May 2022
                  onChange={() => {}}
                />
                <InputRightElement
                  pointerEvents="none"
                  children={<CaretDownIcon16x16 color="gray.500" />}
                ></InputRightElement>
              </InputGroup>
            </PopoverTrigger>
          </FormControl>
          <PopoverContent>
            <Calendar
              date={currentDate}
              minDate={new Date()}
              maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
              onChange={(date) => {
                onChange(date);
                setCurrentDate(date);
                onClose();
              }}
              disabledDay={(date: Date) => {
                if (shouldUseDeliveryRules) {
                  return !deliveryRules[date.getDay()]?.enabled;
                }
                return false;
              }}
              weekdayDisplayFormat="EEEEE"
              weekStartsOn={1}
              color="#07a549" // green.500
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default observer(DateInput);
