import {
  types,
  Instance,
  SnapshotIn,
  applySnapshot,
  SnapshotOrInstance,
} from 'mobx-state-tree';
import { capitalizeString, isDeliveryDateValid } from '../utils';

import Customer from './Customer';
import Supplier from './Supplier';
import OrderItem from './OrderItem';
import Product from './Product';
import Address from './Address';
import UserCompany from './UserCompany';
import moment from 'moment';

const Order = types
  .model('Order', {
    id: types.identifier,
    customer: types.maybe(Customer),
    supplier: types.maybe(Supplier),
    items: types.array(OrderItem),
    message: types.optional(types.string, ''),
    deliveryDate: types.optional(types.Date, new Date()),
    initialDeliveryDate: types.optional(types.Date, new Date()),
    isValid: types.maybeNull(types.boolean),
    delivery_address_id: types.maybeNull(types.number),
    delivery_address: types.maybeNull(types.string),
    delivery_instructions: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setCustomer(newCustomer: SnapshotOrInstance<typeof Customer> | Instance<typeof UserCompany>) {
      self.customer = Customer.create({
        id: newCustomer.id,
        name: newCustomer.name,
        logo_url: newCustomer.logo_url,
        default_address: newCustomer.default_address,
        default_instructions: newCustomer.default_instructions,
      });
      if (self.delivery_address === null && newCustomer?.default_address) {
        self.delivery_address = newCustomer.default_address;
      }
      if (
        self.delivery_instructions === null &&
        newCustomer?.default_instructions
      ) {
        self.delivery_instructions = newCustomer.default_instructions;
      }
    },
    setDeliveryDate(newDate: Date) {
      self.deliveryDate = newDate;
    },
    reset() {
      applySnapshot(self, { id: self.id });
    },
    setAddress(
      newAddress:
        | {
            id?: number;
            full_address: string;
            instructions: string;
          }
        | SnapshotOrInstance<typeof Address>,
    ) {
      self.delivery_address_id = newAddress.id || null;
      self.delivery_address = newAddress.full_address || null;
      self.delivery_instructions = newAddress.instructions || null;
    },
    setDeliveryAddress(newAddress: string) {
      self.delivery_address = newAddress;
    },
    setDeliveryInstructions(newInstructions: string) {
      self.delivery_instructions = newInstructions;
    },
    setMessage(newMessage: string) {
      self.message = newMessage;
    },
    setSupplierCompany(
      supplier: SnapshotIn<typeof Supplier> | SnapshotOrInstance<typeof UserCompany>,
    ) {

      const deliveryRulesProps = ('delivery_rules' in supplier) ? {
        delivery_rules: supplier.delivery_rules,
        delivery_rules_enabled: supplier.delivery_rules_enabled,
        delivery_rules_message: supplier.delivery_rules_message,
      } : {}

      self.supplier = Supplier.create({
        id: supplier.id,
        name: supplier.name,
        logo_url: supplier.logo_url,
        ...deliveryRulesProps
      });
    },
    clearSupplierCompany() {
      self.supplier = undefined;
    },
    clearCustomer() {
      self.customer = undefined;
    },
    addOrderItem(product: SnapshotIn<typeof Product>) {
      self.items.push(
        OrderItem.create({
          unit: product?.unit,
          is_added: true,
          buyable: { type: 'product', product: Product.create(product) },
        }),
      );
      self.items.sort(
        (
          a: Instance<typeof OrderItem>,
          b: Instance<typeof OrderItem>,
        ): number => {
          let nameA = a.buyable.product.name || '';
          let nameB = b.buyable.product.name || '';
          return nameA.localeCompare(nameB);
        },
      );
    },
    removeOrderItem(orderItem: Instance<typeof OrderItem>) {
      orderItem.markForDeletion();
    },
    validate() {
      let isValid = true;
      self.items.forEach((item) => {
        item.validate();
        if (!item.isValid) {
          isValid = false;
        }
      });
      if (self.items.length === 0 && self.message.length <= 500) {
        isValid = false;
      }
      self.isValid = isValid;
    },
  }))
  .views((self) => ({
    get itemsCount() {
      return self.items.filter(
        (item) => item.amount !== 0 && item.amount !== null,
      ).length;
    },
    get currentProducts() {
      return self.items.map((item) => item.buyable.product.id);
    },
    productsList(products: any) {
      return products.map((product: any) => {
        const orderItem = self.items.find(
          (item) => item.buyable.product.id === product.id,
        );
        if (orderItem) {
          return orderItem;
        }
        return product;
      });
    },
  }))
  .views((self) => ({
    formState() {
      return {
        order: {
          message: self.message,
          delivery_date: self.deliveryDate.toLocaleDateString('en-NZ'),
          initial_delivery_date:
            self.initialDeliveryDate.toLocaleDateString('en-NZ'),
          supplier_id: self.supplier?.id,
          customer_company_id: self.customer?.id,
          order_items: self.items.map((item) => ({
            amount: item.amount,
            notes: item.notes,
            unit_id: item.unit?.id,
            buyable_type: capitalizeString(item.buyable.type),
            buyable_id: item.buyable.product.id,
          })),
        },
        address: {
          id: self.delivery_address_id,
          full_address: self.delivery_address,
          delivery_instructions: self.delivery_instructions,
        },
      };
    },
    get earliestDeliveryDate() {
      const currentDateTime = moment();
      const deliveryRules = self.supplier?.delivery_rules?.toJSON();
      if (deliveryRules) {
        for (var i = 0; i < 7; i++) {
          let currentDate = currentDateTime.add(
            i > 0 ? 1 : 0,
            'day'
          ); // check current day first, then check next day
          if (!deliveryRules[moment(currentDate).weekday()].enabled) {
            continue;
          }
          if (isDeliveryDateValid(currentDate, deliveryRules)) {
            return currentDate.toDate();
          }
        }
      }
      return currentDateTime.toDate();
    },
    get editPayload() {
      return {
        order: {
          delivery_date: self.deliveryDate.toLocaleDateString('en-NZ'),
          delivery_address: self.delivery_address,
          delivery_instructions: self.delivery_instructions,
          order_items_attributes: self.items.map((item) => ({
            id: item.id,
            _destroy: item._destroy,
            amount: item.amount,
            supplier_note: item.supplier_note,
            unit_id: item.unit?.id,
            buyable_type: capitalizeString(item.buyable.type),
            buyable_id: item.buyable.product.id,
          })),
        },
      };
    },
  }));

export default Order;
