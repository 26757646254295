import defaultTheme from '@chakra-ui/theme';

const variantPrimary = (props) => {
  const { colorScheme: c } = props;
  return {
    ...defaultTheme.components.Button.variants.solid(props),
    background: `${c}.500`,
    color: 'white',
    _active: {
      bg: `${c}.600`,
    },
    _hover: {
      backgroundImage:
        'linear-gradient(86.35deg, #008f58 0%, #0ebe57 53.76%, #85d317 86.02%, #f0e800 114.69%), linear-gradient(0deg, rgba(0, 143, 88, 0.25), rgba(0, 143, 88, 0.25))',
    },
    _disabled: {
      background: 'gray.300',
      cursor: 'not-allowed',
      _hover: {
        background: 'gray.300',
      },
    },
  };
};

const variantSecondary = (props) => {
  const { colorScheme: c } = props;
  return {
    ...defaultTheme.components.Button.variants.outline(props),
    color: `${c}.500`,
    borderColor: `${c}.500`,
    borderWidth: '2px',
    _active: {
      color: `${c}.700`,
      borderColor: `${c}.700`,
    },
    _hover: {
      color: `${c}.600`,
      borderColor: `${c}.600`,
    },
    _disabled: {
      color: 'gray.400',
      borderColor: 'gray.400',
      cursor: 'not-allowed',
      _hover: {
        color: 'gray.400',
        borderColor: 'gray.400',
      },
    },
  };
};

const variantTertiary = (props) => {
  const { colorScheme: c } = props;
  return {
    ...defaultTheme.components.Button.variants.link(props),
    color: `${c}.500`,
    _active: {
      color: `${c}.700`,
    },
    _hover: {
      color: `${c}.600`,
      position: 'relative',
      _after: {
        content: '""',
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '2px',
        backgroundColor: `${c}.600`,
        borderRadius: '2px',
      },
    },
    _disabled: {
      color: 'gray.400',
      _hover: {
        color: 'gray.400',
      },
    },
  };
};

const Button = {
  variants: {
    pageFilter: (props) => ({
      ...defaultTheme.components.Button.variants.ghost(props),
      fontWeight: '500',
      borderRadius: '9999px',
    }),
    transparentGhost: (props) => ({
      ...defaultTheme.components.Button.variants.ghost(props),
      bg: 'transparent !important',
      borderRadius: '5px',
    }),
    primary: variantPrimary,
    secondary: variantSecondary,
    tertiary: variantTertiary,
  },
  sizes: {
    xs: {
      borderRadius: '8px',
      _hover: {
        _after: {
          height: '1.25px',
        },
      },
    },
    sm: {
      borderRadius: '10px',
      _hover: {
        _after: {
          height: '1.5px',
        },
      },
    },
    md: {
      borderRadius: '12px',
      _hover: {
        _after: {
          height: '1.75px',
        },
      },
    },
    lg: {
      borderRadius: '12px',
      _hover: {
        _after: {
          height: '2px',
        },
      },
    },
  },
};

export default Button;
