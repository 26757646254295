import { Flex } from '@chakra-ui/react';

function OneUpScrollPanel({ children }: any) {
  return (
    <Flex flexDirection="column" flex="1" overflowY="auto">
      <Flex height="100vh" flexDirection="column" bg="white">
        {children}
      </Flex>
    </Flex>
  );
}

export default OneUpScrollPanel;
