import { useEffect, useState } from 'react';
import AccountingConnectorCard from './AccountingConnectorCard';

import { useAccounting } from '../../../contexts/accounting';

import xeroImage from '../../../images/xero.png';
import myobImage from '../../../images/myob.png';
import infusionImage from '../../../images/infusion.png';

import InfusionConnectionDetails from './Infusion/InfusionConnectionDetails';
import XeroConnectionDetails from './Xero/XeroConnectionDetails';
import MyobConnectionDetails from './Myob/MyobConnectionDetails';

import { Stack } from '@chakra-ui/layout';
import { Body2 } from '../../../components/Typography/Typography';

const Integrations = () => {
  const { currentAccountingConnection, getCurrentAccountingConnection } =
    useAccounting();
  const [dataIsLoading, setDataIsLoading] = useState(true);

  // TODO: Get current accounting connector

  useEffect(() => {
    getCurrentAccountingConnection(() => {
      setDataIsLoading(false);
    });
  }, []);

  return (
    <>
      {dataIsLoading && <p>Loading connection data...</p>}
      {!dataIsLoading && currentAccountingConnection && (
        <>
          {currentAccountingConnection.key === 'infusion' && (
            <InfusionConnectionDetails
              connection={currentAccountingConnection}
            />
          )}
          {currentAccountingConnection.key === 'xero' && (
            <XeroConnectionDetails connection={currentAccountingConnection} />
          )}
          {currentAccountingConnection.key === 'myob' && (
            <MyobConnectionDetails connection={currentAccountingConnection} />
          )}
        </>
      )}
      {!dataIsLoading && !currentAccountingConnection && (
        <Stack spacing="16px">
          <Body2 mb="22px" color="gray.600">
            Create invoices in your accounting software for orders received in
            HospoConnect
          </Body2>
          <AccountingConnectorCard
            logoImageUrl={xeroImage}
            platformName="Xero"
            platformKey="xero"
          />
          <AccountingConnectorCard
            logoImageUrl={myobImage}
            platformName="MYOB"
            platformKey="myob"
          />
          <AccountingConnectorCard
            logoImageUrl={infusionImage}
            platformName="Infusion"
            platformKey="infusion"
            manualConnection={true}
          />
        </Stack>
      )}
    </>
  );
};

export default Integrations;
