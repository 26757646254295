import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useCurrentUser, useOrders } from '../../../hooks/useStores';
import useRealtimeData, {
  RealtimeDataEventPayloadType,
} from '../../../hooks/useRealtimeData';
import AddOrderModal from '../AddOrderModal/AddOrderModal';
import { AddOrderProvider } from '../../../contexts/addOrder';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import ShowOrder from './ShowOrder';
import EditOrder from './EditOrder';
import { EditableOrderProvider } from '../../../contexts/editableOrder';

type OrderDetailProps = {
  orderId: any;
};

const OrderDetail = ({ orderId }: OrderDetailProps): JSX.Element => {
  const { currentOrder, getOrder, setCurrentOrder, getOrders } = useOrders();
  const { isBuyer } = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const [initialLoadComplete, setIsInitialLoadComplete] = useState(false);

  const onReceived = (payload: RealtimeDataEventPayloadType) => {
    if (
      currentOrder &&
      payload.entityType == 'order' &&
      payload.entityIds.includes(currentOrder.id)
    ) {
      if (payload.eventType == 'update') {
        getOrder(currentOrder.id, () => {});
      } else if (payload.eventType == 'delete') {
        currentOrder.setIsDeleted(true);
      }
    }
  };
  const onTabReactivate = () => {
    if (currentOrder) {
      getOrder(
        currentOrder.id,
        () => {},
        () => {
          // If the response fails, assume the order has been deleted
          // This is safe to assume for now.
          currentOrder.setIsDeleted(true);
        }
      );
    }
  };
  useRealtimeData(
    'DataFeedChannel',
    onReceived,
    [currentOrder],
    onTabReactivate,
  );

  useEffect(() => {
    if (orderId && orderId !== 'new') {
      setLoading(true);
      getOrder(orderId, () => {
        setLoading(false);
        setIsInitialLoadComplete(true);
      });
    } else if (initialLoadComplete) {
      // We need to clear the order.
      // And request orders to refresh the page.
      // This fixes an issue where deleting the open order does not refresh the view.
      setCurrentOrder(undefined);
      getOrders(
        () => {},
        () => { },
        isBuyer ? 'outgoing' : 'incoming'
      );
    }
  }, [orderId]);

  return loading ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/orders/new">
        <EmptyDetail />
        <AddOrderProvider>
          <AddOrderModal />
        </AddOrderProvider>
      </Route>
      <Route path="/orders/:orderId/edit">
        {currentOrder && (
          <EditableOrderProvider>
            <EditOrder order={currentOrder} />
          </EditableOrderProvider>
        )}
      </Route>
      <Route path="/orders/:orderId">
        {currentOrder && <ShowOrder order={currentOrder} />}
      </Route>
      <Route path="/orders">
        <EmptyDetail />
      </Route>
    </Switch>
  );
};

export default observer(OrderDetail);
