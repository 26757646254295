import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Alert,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  IconButton,
  HStack,
  VStack,
  Box,
  Tooltip,
  Input,
  Link,
  AlertIcon,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';

import useAPI from '../../hooks/useAPI';
import AddProductModal from '../AddProductModal/AddProductModal';
import SelectUnit from '../SelectUnit/SelectUnit';
import { useDeliveryRules } from '../../hooks/useStores';
import { CrossIcon } from '../Icons/Icons';
import ProductsListView from '../ProductModalContent/ProductsListView';
import { Button } from '../Button/Button';
import { Subhead } from '../Typography/Typography';

const StandingOrderCell = observer(
  ({
    item,
    amount,
    hasAmount,
    onChange,
    unit,
    showUnit,
    isEditable,
    isDisabled,
  }: any) => {
    return isEditable ? (
      <Td>
        <Input
          type="number"
          min={0}
          disabled={isDisabled}
          defaultValue={item[amount]}
          placeholder="0"
          onChange={onChange}
          textAlign="right"
          width="100%"
        />
      </Td>
    ) : isDisabled ? (
      <Td color="gray.400">-</Td>
    ) : hasAmount ? (
      <Td>
        {item[amount]}
        {showUnit && ' ' + unit.name}
      </Td>
    ) : (
      <Td color="gray.400">0</Td>
    );
  },
);

const StandingOrderRow = observer(
  ({ item, isEditable, standingOrder }: any) => {
    const {
      buyable: { product },
      unit,
    } = item;
    let showUnit = true;

    const isInactive = product.active === false;
    const onClickRemove = () => {
      item.remove();
    };

    if (item.destroy) return <></>;

    return isEditable ? (
      <Tr>
        <Td textAlign="start">
          <HStack>
            <Tooltip hasArrow label="Remove">
              <IconButton
                aria-label="Remove"
                colorScheme="red"
                color="red.600"
                _hover={{ backgroundColor: 'red.100' }}
                variant="ghost"
                size="sm"
                icon={<CrossIcon />}
                onClick={onClickRemove}
              />
            </Tooltip>
            <Box ml="8">
              <ProductsListView product={product} />
            </Box>
          </HStack>
        </Td>
        <Td>
          <SelectUnit
            units={product.units}
            unit={unit}
            isInactive={isInactive}
            onChange={(unit) => {
              item.setUnit(unit.id);
            }}
          />
        </Td>
        {standingOrder.days.map(
          (day: { name: string; key: string; enabled: boolean }) => {
            const hasAmount = !!item[day.key];
            const standingOrderCell = (
              <StandingOrderCell
                key={day.key}
                item={item}
                amount={day.key}
                onChange={(e: any) => {
                  item.setAmount(day.key, Number(e.target.value));
                }}
                unit={unit}
                hasAmount={hasAmount}
                showUnit={showUnit}
                isEditable={isEditable}
                isDisabled={!day.enabled}
              />
            );
            if (hasAmount) showUnit = false;
            return standingOrderCell;
          },
        )}
      </Tr>
    ) : (
      <Tr>
        <Td textAlign="start">
          <ProductsListView product={product} />
        </Td>
        {standingOrder.days.map(
          (day: { name: string; key: string; enabled: boolean }) => {
            const hasAmount = !!item[day.key];
            const standingOrderCell = (
              <StandingOrderCell
                key={day.key}
                item={item}
                amount={day.key}
                hasAmount={hasAmount}
                unit={unit}
                showUnit={showUnit}
                isEditable={isEditable}
                isDisabled={!day.enabled}
              />
            );
            if (hasAmount) showUnit = false;
            return standingOrderCell;
          },
        )}
      </Tr>
    );
  },
);

function StandingOrderTable({ standingOrder, isEditable }: any) {
  const { deliveryRules, deliveryRulesEnabled, getDeliveryRules } =
    useDeliveryRules();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getProductById] = useAPI({
    method: 'GET',
  });

  useEffect(() => {
    if (isEmpty(deliveryRules)) {
      getDeliveryRules();
    }
  });

  useEffect(() => {
    if (isEditable && !standingOrder.id && !standingOrder.hasProducts) {
      setIsModalOpen(true);
    }
  }, []);

  const showDeliveryRulesError =
    !isEmpty(deliveryRules) && !deliveryRulesEnabled;

  return (
    <VStack alignItems="start">
      <Subhead fontWeight="600">Standing order</Subhead>
      {standingOrder.hasProducts ? (
        <Table variant="standingorder" className={isEditable && 'editing'}>
          <Thead>
            <Tr>
              <Th textAlign="start" minWidth={{ base: '100px', xl: '300px' }}>
                Deliver on
              </Th>
              {isEditable && <Th minW="100px" maxW="100px" />}
              {standingOrder.days.map((day: any) => (
                <Th
                  key={day.key}
                  minWidth={{ base: '58px', xl: '68px' }}
                  textAlign={isEditable ? 'center' : 'right'}
                >
                  {day.enabled ? (
                    day.name
                  ) : (
                    <Text color="gray.400" as="s">
                      {day.name}
                    </Text>
                  )}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {standingOrder.items.map((item: any) => (
              <StandingOrderRow
                key={item.id}
                item={item}
                isEditable={isEditable}
                standingOrder={standingOrder}
              />
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text>No products added yet</Text>
      )}
      {isEditable && (
        <Button
          leftIcon={<AddIcon />}
          marginTop="4"
          onClick={() => setIsModalOpen(true)}
        >
          {standingOrder.hasProducts ? 'Add another product' : 'Add a product'}
        </Button>
      )}

      {isModalOpen && (
        <AddProductModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          addedProducts={standingOrder.currentProducts}
          onAdd={(product: any) => {
            getProductById(`/v4/products/${product.id}`).then((data) => {
              standingOrder.addProduct(data);
            });
          }}
        />
      )}

      {showDeliveryRulesError ? (
        <Alert status="error" borderRadius="6px">
          <AlertIcon />
          <AlertDescription>
            You have not set up delivery days and cut-off times. We recommend
            setting this up in Settings so that standing orders are received
            within your cut-off times.{' '}
            <Link color="gray.900" href="/settings/delivery-days">
              Set up now
            </Link>
          </AlertDescription>
        </Alert>
      ) : (
        <Text color="gray.600" fontSize="xs">
          Standing orders will appear in your Orders before the cut-off time.
          <br />
          <Link
            color="gray.900"
            fontWeight={400}
            href="/settings/delivery-days"
          >
            Set up delivery days &amp; cut-off times
          </Link>{' '}
          in Settings.
        </Text>
      )}
    </VStack>
  );
}

export default observer(StandingOrderTable);
