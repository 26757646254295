import {
  ModalFooter,
  ButtonGroup,
  Button,
  ButtonProps,
  Spacer,
  Tooltip,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

type ActionButtonProps = {
  text?: string;
  props?: ButtonProps;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  tooltip?: string | null;
};

type ModalFooterActionsProps = {
  leftComponent?: ReactNode;
  primaryButton?: ActionButtonProps;
  secondaryButton?: ActionButtonProps;
  shadow?: boolean;
};

const ModalFooterActions = ({
  primaryButton,
  secondaryButton,
  leftComponent,
  shadow,
}: ModalFooterActionsProps) => (
  <ModalFooter boxShadow={shadow ? 'dark-lg' : ''} zIndex={1}>
    {leftComponent}
    <Spacer />
    <ButtonGroup>
      {secondaryButton && (
        <Button
          size="sm"
          variant="secondary"
          colorScheme="green"
          onClick={secondaryButton.onClick}
        >
          {secondaryButton.text || 'Cancel'}
        </Button>
      )}
      {primaryButton && (
        <Tooltip hasArrow isDisabled={!primaryButton.tooltip}  label={primaryButton?.tooltip}>
          <Button
            size="sm"
            colorScheme="green"
            variant="primary"
            isDisabled={primaryButton.isDisabled}
            onClick={primaryButton.onClick}
            {...primaryButton.props}
          >
            {primaryButton.text || 'Done'}
          </Button>
        </Tooltip>
      )}
    </ButtonGroup>
  </ModalFooter>
);

export default ModalFooterActions;
