import { useState, useEffect, useCallback, ReactNode } from 'react';
import debounce from 'lodash/debounce';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';
import { observer } from 'mobx-react';
import { Instance, SnapshotIn, SnapshotOrInstance } from 'mobx-state-tree';
import { ModalContent, ModalHeader, ModalBody } from '@chakra-ui/react';

import useAPI from '../../hooks/useAPI';
import { useAuth } from '../../contexts/auth';
import { useScroll } from '../../hooks/useScroll';
import Product from '../../models/Product';
import ProductsModalList from './ProductsModalList';
import Order from '../../models/Order';
import ProductModalHeader from './ProductModalHeader';
import { omit } from 'lodash';
import { useCurrentUser } from '../../hooks/useStores';

const supplierFilters = {
  category_id: '',
  status: 'active',
};

const buyerFilters = {
  category_id: '',
};

const buyerFilterTabs = [
  {
    label: 'Categories',
    value: 'categories',
  },
  {
    label: 'Favourites',
    value: 'favourites',
  },
];

type ProductsAPIData = {
  results: SnapshotIn<typeof Product>[];
  total_count: number;
};

const ProductModalContent = observer(
  ({
    order,
    defaultStatusFilter = 'active',
    headerUpperChildren,
    footerChildren,
  }: {
    order: Instance<typeof Order>;
    defaultStatusFilter?: '' | 'active';
    headerUpperChildren: ReactNode;
    footerChildren: ReactNode;
  }) => {
    const { isBuyer } = useCurrentUser();

    const baseFilters = isBuyer
      ? buyerFilters
      : { ...supplierFilters, status: defaultStatusFilter };

    const limit = 20;
    const [currentQueryFieldValue, setCurrentQueryFieldValue] = useState('');
    const [currentQuery, setCurrentQuery] = useState('');
    const [filters, setFilters] = useState(baseFilters);
    const [categories, setCategories] = useState([]);
    const [filterTab, setFilterTab] = useState(isBuyer ? 'categories' : null);

    const [callAPI] = useAPI({
      method: 'GET',
    });

    const filterString =
      filterTab === 'categories'
        ? Object.entries(filters)
            .map(([key, value]) => (value ? `&${key}=${value}` : ''))
            .join('')
        : '';

    const favouritesString =
      filterTab === 'favourites' ? '&favourites_only=true' : '';

    const getUrl = (pageIndex: number) =>
      `/v4/products?page=${pageIndex + 1}&limit=${limit}${
        currentQuery && '&q=' + currentQuery
      }${filterString}${favouritesString}${
        order.supplier?.id && '&supplier_id=' + order.supplier?.id
      }`;

    const { data, setSize, size, isLoading } = useSWRInfinite<ProductsAPIData>(
      getUrl,
      callAPI,
    );

    const products = data?.reduce(
      (
        prev: SnapshotIn<typeof Product>[],
        pageResult: { results: SnapshotIn<typeof Product>[] },
      ) => [...prev, ...pageResult.results],
      [],
    ) || [];
    const resultCount = data ? data[0].total_count : 0;

    const loadMore = () => {
      if (data && size * limit < data[0].total_count) {
        setSize(size + 1);
      }
    };

    const clearResults = () => {
      setSize(1);
    };

    const clearFilters = () => {
      clearResults();
      setCurrentQueryFieldValue('');
      setCurrentQuery('');
      setFilters(baseFilters);
    };

    const handleSearch = useCallback(
      debounce((value: string) => {
        clearResults();
        setCurrentQuery(value);
      }, 400),
      [],
    );

    const handleSetFilterTab = (value: string) => {
      setFilterTab(value);
      clearResults();
    };

    useEffect(() => {
      callAPI(`/v2/companies/${order.supplier?.id}/categories`).then(
        (data: any) => {
          setCategories(data);
        },
      );
    }, []);

    return (
      <ModalContent>
        <ModalHeader
          px={6}
          pb={0}
          bg="gray.100"
          borderTopRadius="var(--chakra-radii-md)"
          boxShadow="inset 0px -1px 0px #D1D5DB"
        >
          {headerUpperChildren}
          <ProductModalHeader
            setFilter={(key, value) => {
              setFilters({ ...filters, [key]: value });
            }}
            handleSearch={handleSearch}
            categories={categories}
            filters={
              filterTab === 'categories'
                ? filters
                : omit(filters, 'category_id')
            }
            setFilterTab={handleSetFilterTab}
            filterTabs={isBuyer ? buyerFilterTabs : null}
          />
        </ModalHeader>
        <ModalBody p={0}>
          <ProductsModalList
            order={order}
            products={products}
            isLoading={isLoading}
            resultCount={resultCount}
            loadMore={loadMore}
            clearFilters={clearFilters}
          />
        </ModalBody>

        {footerChildren}
      </ModalContent>
    );
  },
);

export default ProductModalContent;
