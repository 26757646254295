import { observable, makeObservable, action, computed } from 'mobx';
import { Instance, SnapshotIn } from 'mobx-state-tree';
import authApi from '../../api/auth';
import User from '../../models/User';
import * as Sentry from '@sentry/browser';
import UserCompany from '../../models/UserCompany';

type UserInfo = {
  authentication_token: string;
  fullname: string;
  id: number;
  company: {
    id: string;
    name: string;
    logo_url?: string;
    account_type: string;
  };
};

export class UserStore {
  authToken?: string;
  @observable user?: Instance<typeof User>;
  @observable isLoading: boolean = false;

  constructor() {
    let userInfo: UserInfo = JSON.parse(
      localStorage.getItem('userInfo') as string,
    );
    
    if (userInfo) {
      this.authToken = userInfo.authentication_token;
      this.loadUser()
    }
    makeObservable(this);
  }

  @action loadUser() {
    this.isLoading = true;
    authApi.getCurrentUser().then((user: SnapshotIn<typeof User>) => {
      const userData: SnapshotIn<typeof User> = {
        id: user.id,
        fullname: user.fullname,
        avatar_url: user.avatar_url,
        email: user.email,
        firstname: user.firstname,
        surname: user.surname,
        phone: user.phone,
        job_title: user.job_title,
        account_type: user.account_type,
        authentication_token: this.authToken,
        company_role: user.company_role,
        company: UserCompany.create(user.company),
      };
      this.user = User.create(userData);
      this.isLoading = false;
      Sentry.configureScope(function (scope) {
        scope.setUser({
          id: `${user.id}`,
          companyId: user.company && user.company.id,
        });
      });
    });
  }
    

  @computed
  get isBuyer() {
      const userInfo: UserInfo | Instance<typeof User> = this.isLoading ? JSON.parse(
        localStorage.getItem('userInfo') as string,
      ) : this.user;

      return userInfo?.company?.account_type === 'restaurant';
  }

  @computed
  get company() {
    return this.user?.company;
  }

  @computed
  get companyAddresses() {
    return this.user?.company.addresses || [];
  }

  @computed
  get defaultAddress() {
    return this.user?.company.addresses.find((address) => address.is_default) || null;
  }
}

export default new UserStore();
