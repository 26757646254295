import React, { Fragment, useState } from 'react';
import { useAuth } from '../../../contexts/auth';

import {
  Avatar,
  Box,
  HStack,
  Spacer,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';

type AccountingConnectorCardProps = {
  logoImageUrl?: string;
  platformKey: string;
  platformName: string;
  manualConnection?: boolean;
};

const AccountingConnectorCard = ({
  logoImageUrl,
  platformKey,
  platformName,
  manualConnection = false,
}: AccountingConnectorCardProps): JSX.Element => {
  const { user } = useAuth();
  const [connectButtonIsLoading, setConnectButtonIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const authToken = user && user.authentication_token;

  const connectFormIdPrefix = `connectForm-${platformKey}`;
  const formId = `${connectFormIdPrefix}-form`;
  const authFieldId = `${connectFormIdPrefix}-authentication_token_field`;
  const returnUrlFieldId = `${connectFormIdPrefix}-return_url`;

  const connectUrl = `${process.env.REACT_APP_API_URL}/auth/${platformKey}`;
  return (
    <Fragment>
      <Card maxW={{ base: '566px', '2xl': '720px' }}>
        <HStack spacing="4">
          <Avatar src={logoImageUrl} size="lg"></Avatar>
          <Text fontWeight="600">{platformName}</Text>
          <Spacer />

          <Box>
            <form
              id={formId}
              method="post"
              name="connectForm"
              action={connectUrl}
            >
              <input
                name="authentication_token"
                id={authFieldId}
                type="hidden"
              />
              <input
                name="return_url"
                id={returnUrlFieldId}
                type="hidden"
                value={window.location.href.split('?')[0]}
              />
            </form>
            <Button
              variant="primary"
              isLoading={connectButtonIsLoading}
              // name='Connect'
              onClick={() => {
                if (!manualConnection) {
                  setConnectButtonIsLoading(true);
                  let authenticationTokenField =
                    document.getElementById(authFieldId);
                  authenticationTokenField!.setAttribute('value', authToken);
                  let form = document.forms.namedItem(formId);
                  form!.submit();
                } else {
                  onOpen();
                }
              }}
            >
              Connect
            </Button>
          </Box>
        </HStack>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{platformName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Connecting your HospoConnect account to {platformName} is a manual
            process. Please contact us to discuss.
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Ok</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default AccountingConnectorCard;
