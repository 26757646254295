import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Text, VStack, Divider, Box, BoxProps } from '@chakra-ui/react';

import { useCustomerGroups } from '../../../hooks/useStores';
import { Label } from '../../../components/Typography/Typography';
import { PricingTiersTable, PricingTiersTableRow } from './PricingTiersTable';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';

const ShowPricingTable = ({ product }: any) => {
  const { pricingTiersGroupSet } = useCustomerGroups();

  return (
    <>
      <Divider />
      <VStack align="left" spacing={6}>
        <Label>Pricing</Label>
        <FormFieldWrapper
              fieldLabel="Price"
              fieldName="price"
              subLabel="Optional"
            >
          <Text>
            ${Number(product.default_price).toFixed(2)} /{' '}
            {product.pricing_unit && product.pricing_unit.name}
          </Text>
        </FormFieldWrapper>
        <PricingTiersTable
          header={
            <>
              <Text>Price tier</Text>
              <Text textAlign="right">
                Price {`(${product.pricing_unit?.name || 'Units'})`}
              </Text>
            </>
          }
          rows={[
            ...product.pricing_tiers?.map((tier: any) => {
              const group = pricingTiersGroupSet?.groups?.find(
                (group: any) => group.id === tier.id,
              );
              return (
                <ShowPricingTierRow
                  key={`pricing-row-${tier.id}`}
                  name={tier.name}
                  price={tier.price || product.default_price}
                  customerCount={group?.customerCount}
                />
              );
            }),
            <PricingTiersTableRow key="pricing-row-unassigned">
              <Text as="i" fontSize="15px" color="gray.500">
                Unassigned customers will see the default price
              </Text>
            </PricingTiersTableRow>,
          ]}
        />
      </VStack>
    </>
  );
};

const ShowPricingTierRow = ({
  name,
  price,
  customerCount,
  containerProps = {},
}: {
  name: string;
  price?: string;
  customerCount?: number;
  containerProps?: BoxProps;
}) => (
  <PricingTiersTableRow {...containerProps}>
    <Box>
      <Box>
        <Text fontSize="15px">{name}</Text>
        <Text fontSize="13px" color="gray.500">
          {customerCount} customers
        </Text>
      </Box>
    </Box>
    <Text fontSize="15px" color="gray.600">${Number(price).toFixed(2)}</Text>
  </PricingTiersTableRow>
);

export default observer(ShowPricingTable);
