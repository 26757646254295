import { makeObservable, observable, action, computed } from 'mobx';

import { OrderStore } from './orderStore';

export interface BaseOrderProps {
  id: number;
  order_number: string;
  date: Date;
  delivery_date: string;
  status: string;
  source: string;
  customer: any;
  customer_company: any;
  supplier: any;
  read_by_supplier_at: string | null;
}

export interface OrderProps extends BaseOrderProps {
  order_items: any;
  accounting_invoice: any;
  message: string;
  initial_delivery_date: string;
  delivery_address: string;
  initial_delivery_address: string;
  delivery_instructions: string;
  initial_delivery_instructions: string;
  delivery_instructions_status: string;
  delivery_address_status: string;
}

export class Order {
  id: number;
  @observable store: OrderStore;

  order_number: string = '';
  date: Date | null = null;
  @observable message: string = '';
  @observable initial_delivery_date: string = '';
  @observable delivery_date: string = '';
  @observable delivery_address: string = '';
  @observable initial_delivery_address: string = '';
  @observable delivery_address_status: string = '';
  @observable delivery_instructions: string = '';
  @observable initial_delivery_instructions: string = '';
  @observable delivery_instructions_status: string = '';
  source: string = '';
  customer: any;
  @observable customer_company: any;
  @observable supplier: any;
  @observable read_by_supplier_at: string | null = null;
  order_items: any;
  accounting_invoice: any;

  @observable status: string = '';

  @observable isChecked: boolean = false;
  @observable isCurrentOrder: boolean = false;
  @observable isDeleted: boolean = false;

  @observable isFullyLoaded: boolean = false;

  constructor(store: OrderStore, props: BaseOrderProps | OrderProps) {
    this.store = store;
    const {
      id,
    } = props;

    this.id = id;
    this.addData(props);
    makeObservable(this);
  }

  @action addData = (props: BaseOrderProps | OrderProps) => {
    const {
      order_number,
      date,
      delivery_date,
      status,
      source,
      customer,
      supplier,
      read_by_supplier_at,
      customer_company,
    } = props;

    // BaseOrderProps are the data expected from the Api::V4::CompactOrderSerializer
    this.order_number = order_number;
    this.date = date;
    this.delivery_date = delivery_date;
    this.status = status;
    this.source = source;
    this.customer = customer;
    this.supplier = supplier;
    this.read_by_supplier_at = read_by_supplier_at;

    if (!this.customer_company) {
      // BaseOrderProps has a customer_company stub, so we only want to use that here if it's not already set
      this.customer_company = customer_company;
    }


    const isFullOrderProps = 'order_items' in props;
    // The full OrderProps are expected from the Api::V4::OrderSerializer
    if (isFullOrderProps) {
      this.addExtendedData(props);
    }
  }

  @computed
  get readStatus() {
    return this.read_by_supplier_at ? 'read' : 'unread';
  }

  @action addExtendedData = (props: OrderProps) => {
    Object.assign(this, props);
    this.isFullyLoaded = true;
  };

  @action toggleChecked = () => {
    this.isChecked = !this.isChecked;
  };

  @action setIsChecked = (val: boolean) => {
    this.isChecked = val;
  };

  @action setIsCurrentOrder = (value: boolean) => {
    this.isCurrentOrder = value;
  };

  @action setIsDeleted = (value: boolean) => {
    this.isDeleted = value;
  };

  @action setStatus = (value: string) => {
    this.status = value;
  };
}
