import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { useSuccessToast, useErrorToast } from '../../components/toast';

import { Switch } from '@chakra-ui/react';
import { useProducts } from '../../hooks/useStores';

const ProductActiveToggle = ({ fieldId, product }: any) => {
  const { updateProduct } = useProducts();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const handleActiveToggle = (e: ChangeEvent, product: any) => {
    const target = e.target as HTMLInputElement;
    const checked = target.checked;

    // Now update the record in the API.
    updateProduct({ active: checked }, product.id)
      .then(() => {
        product.setActive(checked);
        successToast({ description: 'Product status updated' });
      })
      .catch(() => {
        // Revert the record if it failed.
        product.active = !checked;
        errorToast({ description: 'Product status failed to update' });
      });
  };

  // position: 'relative' only required until the following PR is released
  // and we upgrade Chakra:
  // https://github.com/chakra-ui/chakra-ui/pull/4950/files
  return (
    <Switch
      position="relative"
      id={fieldId ? fieldId : `product-active-toggle-${product.id}`}
      onChange={(e) => {
        handleActiveToggle(e, product);
      }}
      isChecked={product.active}
    />
  );
};

export default observer(ProductActiveToggle);
