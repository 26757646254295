import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

export interface ButtonProps extends ChakraButtonProps {
  href?: string;
}

export const PrimaryButton = ({ variant, children, ...props }: ButtonProps) => {
  return (
    <ChakraButton variant="primary" colorScheme="green" {...props}>
      {children}
    </ChakraButton>
  );
};

export const SecondaryButton = ({
  variant,
  children,
  ...props
}: ButtonProps) => {
  return (
    <ChakraButton variant="secondary" colorScheme="green" {...props}>
      {children}
    </ChakraButton>
  );
};

export const TertiaryButton = ({
  variant,
  children,
  ...props
}: ButtonProps) => {
  return (
    <ChakraButton variant="tertiary" colorScheme="green" {...props}>
      {children}
    </ChakraButton>
  );
};

const ButtonWrapper = (props: ButtonProps) => {
  const { variant } = props;

  if (variant === 'primary') return <PrimaryButton {...props} />;
  if (variant === 'secondary') return <SecondaryButton {...props} />;
  if (variant === 'tertiary') return <TertiaryButton {...props} />;
  return <SecondaryButton {...props} />;
};

export const Button = (props: ButtonProps) => {
  const history = useHistory();
  const { href } = props;
  if (href) {
    return (
      <ButtonWrapper
        {...props}
        onClick={() => {
          history.push(href);
        }}
      />
    );
  }

  return <ButtonWrapper {...props} />;
};
