import { types } from 'mobx-state-tree';

export const DeliveryRule = types.model({
  enabled: types.boolean,
  cutoff_day: types.number,
  cutoff_time: types.string
});

export const DeliveryRules = types.map(DeliveryRule);

export default DeliveryRules;