import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { Label } from '../../../components/Typography/Typography';
import { useCustomerGroups } from '../../../hooks/useStores';
import { PlusIcon } from '../../../components/Icons/IconsNew';
import FormField from '../../../components/FormField/FormField';

const AddCustomerGroupSetModal = () => {
  const history = useHistory();
  const { customerGroupSets, createCustomerGroupSet } = useCustomerGroups();
  const regionMap = ['North', 'South', 'East', 'West'];

  return (
    <>
      <Modal
        isOpen={true}
        onClose={() => {
          history.push('/customer-group-sets');
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            px={6}
            bg="gray.100"
            borderTopRadius="var(--chakra-radii-md)"
            boxShadow="inset 0px -1px 0px #D1D5DB"
          >
            Add new set
          </ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{
              name: '',
              customer_groups_attributes: [
                { name: '', sort_order: 0 },
                { name: '', sort_order: 0 },
              ],
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .required('Required')
                .max(40, 'Name must be 40 characters or less')
                .test(
                  'unique',
                  'Another customer group set already exists with this name',
                  (value) => {
                    const duplicate = customerGroupSets.some(
                      (set) => set.name === value,
                    );
                    return !duplicate;
                  },
                ),
              customer_groups_attributes: Yup.array()
                .of(
                  Yup.object().shape({
                    name: Yup.string().max(
                      40,
                      'Name must be 40 characters or less',
                    ),
                  }),
                )
                .min(1, 'Must have at least one group'),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              const cleanedValues = {
                ...values,
                customer_groups_attributes:
                  values.customer_groups_attributes.filter(
                    (g) => g.name !== '',
                  ),
              };
              createCustomerGroupSet(cleanedValues).then(() => {
                setSubmitting(false);
                history.push('/customer-group-sets');
              });
            }}
          >
            {({ values, isSubmitting, isValid }) => (
              <Form>
                <ModalBody>
                  <VStack mt="6" mb="12" spacing="8">
                    <FormField name="name" label="Set name" direction="column">
                      {({ field }: any) => (
                        <Input {...field} placeholder="eg, Region" />
                      )}
                    </FormField>
                    <FieldArray name="customer_groups_attributes">
                      {({ push }) => (
                        <VStack alignItems="left" width="100%">
                          <Label>Groups</Label>
                          <>
                            {values.customer_groups_attributes.map(
                              (group, index) => (
                                <FormField
                                  key={index}
                                  name={`customer_groups_attributes.${index}.name`}
                                  label={`Group ${index + 1} name`}
                                  direction="column"
                                >
                                  {({ field }: { field: any }) => (
                                    <Input
                                      {...field}
                                      placeholder={`eg, ${
                                        regionMap[index % 4]
                                      }`}
                                    />
                                  )}
                                </FormField>
                              ),
                            )}
                          </>
                          <Box>
                            <Button
                              leftIcon={<PlusIcon width="24px" />}
                              onClick={() => push({ name: '', sort_order: 0 })}
                            >
                              Add another group
                            </Button>
                          </Box>
                        </VStack>
                      )}
                    </FieldArray>
                  </VStack>
                </ModalBody>
                <ModalFooter
                  backgroundColor="gray.100"
                  borderRadius="0 0 8px 8px"
                >
                  <Button
                    variant="primary"
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={!isValid}
                  >
                    Done
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(AddCustomerGroupSetModal);
