import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { findIndex } from 'lodash';
import { FieldArray, Form, Formik } from 'formik';
import { v4 as uuid4 } from 'uuid';
import * as Yup from 'yup';

import {
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  useDisclosure,
  HStack,
  Box,
} from '@chakra-ui/react';

import { parseServersideErrors } from '../../../utils';
import { H4 } from '../../../components/Typography/Typography';
import { CustomerGroup } from '../../../models/CustomerGroup';
import { CustomerGroupSet } from '../../../models/CustomerGroupSet';
import { ArrowRightIcon, PlusIcon } from '../../../components/Icons/IconsNew';
import DetailView from '../../../components/DetailView/DetailView';
import { Button } from '../../../components/Button/Button';
import EmptyTableRow from '../../../components/Table/EmptyTableRow';
import AddCustomerModal from '../../../components/AddCustomerModal/AddCustomerModal';
import EditCustomerRow from './EditCustomerRow';
import FormField from '../../../components/FormField/FormField';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';

export interface EditCustomerGroupProps {
  group?: CustomerGroup;
  groupSet: CustomerGroupSet;
}

const EditCustomerGroup = ({ group, groupSet }: EditCustomerGroupProps) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [customerErrorIds, setCustomerErrorIds] = useState<number[]>([]);
  const addCustomerErrorId = (id: number) => {
    setCustomerErrorIds([id, ...customerErrorIds]);
  };
  const removeCustomerErrorId = (id: number) => {
    setCustomerErrorIds(customerErrorIds.filter((i: number) => i !== id));
  };
  const hasCustomerError = customerErrorIds.length > 0;

  useEffect(() => {
    if (group?.customerCount === 0) {
      onOpen();
    }
  }, []);

  return (
    <Formik
      initialValues={
        group
          ? group.toFormInitialValues
          : {
              name: '',
              customer_customer_groups_attributes: [],
            }
      }
      validationSchema={Yup.object({
        name: Yup.string()
          .required('Required')
          .max(40, 'Name must be 40 characters or less')
          .test(
            'unique',
            'Another customer group already exists with this name',
            (value) => {
              const duplicate = groupSet.groups
                .filter((g) => !group || g.id !== group.id)
                .some((group) => group.name === value);
              return !duplicate;
            },
          ),
        customer_customer_groups_attributes: Yup.array(),
      })}
      onSubmit={async (values, { setFieldError }) => {
        group
          ? await group
              .update(values)
              .then((group: any) => {
                history.push(
                  `/customer-group-sets/${groupSet.id}/customer-groups/${group.id}`,
                );
              })
              .catch((errors: any) => {
                const parsedErrors = parseServersideErrors(errors);
                Object.keys(parsedErrors).forEach((key) => {
                  setFieldError(key, parsedErrors[key]);
                });
              })
          : groupSet.createGroup(values).then((group: any) => {
              history.push(
                `/customer-group-sets/${groupSet.id}/customer-groups/${group.id}`,
              );
            });
      }}
    >
      {({ values, isSubmitting, isValid, handleReset, setFieldValue }) => {
        const customers = values.customer_customer_groups_attributes;
        return (
          <DetailView
            as={Form}
            leftActions={
              <HStack>
                <H4 fontWeight="600">{groupSet.name}</H4>
                <ArrowRightIcon width="24px" color="gray.400" />
                <H4 fontWeight="600" as="h5">
                  {group ? group.name : 'New group'}
                </H4>
              </HStack>
            }
            rightActions={
              <>
                <Button
                  size="sm"
                  onClick={() => {
                    handleReset();
                    group
                      ? history.push(
                          `/customer-group-sets/${groupSet.id}/customer-groups/${group.id}`,
                        )
                      : history.push(`/customer-group-sets`);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant="primary"
                  disabled={isSubmitting || !isValid || hasCustomerError}
                >
                  Save
                </Button>
              </>
            }
          >
            <VStack width="full" align="left" spacing="8" my="10">
              {hasCustomerError && (
                <ErrorMessage
                  title={`Customer(s) are already in another group in ${groupSet.name}`}
                  description={
                    'Customers can only be in one group per set. Remove them from the other group before adding them to this one.'
                  }
                />
              )}
              <FormField name="name" label="Name" />
              <FieldArray name="customer_customer_groups_attributes">
                {({ insert, move }) => {
                  const findItem = (key: string | number) => {
                    const item = customers.filter((c) => c.key === key)[0];
                    return {
                      item,
                      index: customers.indexOf(item),
                    };
                  };
                  const moveItem = (key: string, toIndex: number) => {
                    const { index } = findItem(key);
                    move(index, toIndex);
                  };
                  const onDrop = () => {
                    customers.forEach((_, index) => {
                      setFieldValue(
                        `customer_customer_groups_attributes.${index}.sort_order`,
                        index,
                      );
                    });
                  };
                  return (
                    <>
                      <Table variant="orderitems" width="100%">
                        <Thead>
                          <Tr>
                            <Th>Customers</Th>
                            <Th />
                          </Tr>
                        </Thead>
                        <Tbody>
                          {customers.length > 0 ? (
                            customers.map((customer: any, index: number) => (
                              <EditCustomerRow
                                key={customer.key}
                                customer={customer}
                                index={index}
                                customerErrorIds={customerErrorIds}
                                findItem={findItem}
                                moveItem={moveItem}
                                onDrop={onDrop}
                                removeCustomerErrorId={removeCustomerErrorId}
                              />
                            ))
                          ) : (
                            <EmptyTableRow colSpan={2} />
                          )}
                        </Tbody>
                      </Table>

                      <Box>
                        <Button
                          size="sm"
                          leftIcon={<PlusIcon width="24px" />}
                          onClick={onOpen}
                        >
                          Add another customer
                        </Button>
                      </Box>
                      <AddCustomerModal
                        isOpen={isOpen}
                        onClose={onClose}
                        includeCustomerGroups={true}
                        addedCustomers={customers
                          .filter((c: any) => c._destroy !== true)
                          .map((c: any) => c.customer_id)}
                        onAdd={(customer: any) => {
                          let index = findIndex(
                            customers,
                            (c: any) => c.customer_id === customer.id,
                          );

                          if (index === -1) {
                            index = customers.length;
                            insert(index, {
                              key: uuid4(),
                              name: customer.name,
                              customer_id: customer.id,
                              sort_order: 0,
                              _destroy: false,
                            });
                          } else {
                            setFieldValue(
                              `customer_customer_groups_attributes.${index}._destroy`,
                              false,
                            );
                          }

                          const customerExistsInGroupSet = groupSet.groups
                            .map((g: CustomerGroup) => g.id)
                            .some((g: number) =>
                              customer.customer_group_ids.includes(g),
                            );

                          if (customerExistsInGroupSet) {
                            addCustomerErrorId(customer.id);
                          }
                        }}
                        onRemove={(customer: any) => {
                          const index = findIndex(
                            customers,
                            (c: any) => c.customer_id === customer.id,
                          );
                          setFieldValue(
                            `customer_customer_groups_attributes.${index}._destroy`,
                            true,
                          );

                          removeCustomerErrorId(customer.id);
                        }}
                      />
                    </>
                  );
                }}
              </FieldArray>
            </VStack>
          </DetailView>
        );
      }}
    </Formik>
  );
};

export default observer(EditCustomerGroup);
