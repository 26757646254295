import { useState } from 'react';
import { observer } from 'mobx-react';
import { ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react';

import { useProducts } from '../../../hooks/useStores';
import SelectAllCheckbox from '../../../components/SelectAllCheckbox/SelectAllCheckbox';
import { useSuccessToast, useErrorToast } from '../../../components/toast';
import { ToggleOffIcon, ToggleOnIcon } from '../../../components/Icons/Icons';
import ProductsDeleteAction from './ProductsDeleteAction';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { pluralizeString } from '../../../utils';

const ProductsListActions = () => {
  const {
    currentProduct,
    products,
    getProductsList,
    updateBatchProducts,
    setProductsActive,
    currentBulkIds,
    setCurrentBulkIds,
  } = useProducts();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [inactiveButtonIsLoading, setInactiveButtonIsLoading] = useState(false);
  const [activeButtonIsLoading, setActiveButtonIsLoading] = useState(false);
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const currentPageIds = products
    ? products.map((object: any) => {
        return object.id;
      })
    : null;
  // We either use the checked items, or the currently selected item if there is one.
  const currentBatchActionableIDs = currentBulkIds.length
    ? currentBulkIds
    : currentProduct
    ? [currentProduct.id]
    : [];

  const openModal = (actionName: string) => {
    setModalContent(actionName);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalContent('');
    setModalIsOpen(false);
  };

  const handleBulkUpdate = (statusBool: boolean) => {
    setModalIsOpen(false);
    const data = {
      product_ids: currentBatchActionableIDs,
      attributes: {
        active: statusBool,
      },
    };
    if (statusBool) {
      setActiveButtonIsLoading(true);
    } else {
      setInactiveButtonIsLoading(true);
    }
    updateBatchProducts(
      data,
      () => {
        getProductsList(
          () => {},
          () => {},
        );
        successToast({
          description: `We updated ${currentBatchActionableIDs.length} products.`,
        });
        setProductsActive(currentBatchActionableIDs, statusBool);
        setInactiveButtonIsLoading(false);
        setActiveButtonIsLoading(false);
        setCurrentBulkIds([]);
      },
      () => {
        getProductsList(
          () => {},
          () => {},
        );
        errorToast();
        setInactiveButtonIsLoading(false);
        setActiveButtonIsLoading(false);
      },
    );
  };

  const productsAmountSummaryText = () => {
    return currentBatchActionableIDs.length > 1
      ? `${currentBatchActionableIDs.length} ${pluralizeString(
          'product',
          currentBatchActionableIDs.length,
        )}`
      : 'product';
  };

  return (
    <>
      <SelectAllCheckbox
        selectedIds={currentBulkIds}
        setSelectedIds={setCurrentBulkIds}
        currentPageIds={currentPageIds}
      />

      <ButtonGroup
        variant="ghost"
        spacing="1"
        isDisabled={!currentBatchActionableIDs.length}
      >
        <Tooltip hasArrow label="Mark products active">
          <IconButton
            onClick={() => openModal('active')}
            icon={<ToggleOnIcon />}
            isLoading={activeButtonIsLoading}
            aria-label="Mark products active"
          />
        </Tooltip>
        <Tooltip hasArrow label="Mark products inactive">
          <IconButton
            onClick={() => openModal('inactive')}
            icon={<ToggleOffIcon />}
            isLoading={inactiveButtonIsLoading}
            aria-label="Mark products inactive"
          />
        </Tooltip>

        <ProductsDeleteAction productIds={currentBatchActionableIDs} />
      </ButtonGroup>

      <ConfirmationDialog
        isOpen={modalIsOpen}
        onCancel={() => {
          closeModal();
        }}
        titleText={`Mark ${productsAmountSummaryText()} as ${modalContent}?`}
        bodyContent={
          modalContent == 'inactive'
            ? 'Inactive products are not visible to customers.'
            : 'Active products are visible to customers.'
        }
        confirmButtonText={`Mark ${productsAmountSummaryText()} ${modalContent}`}
        confirmButtonColorScheme="green"
        onConfirm={() => {
          handleBulkUpdate(modalContent === 'active');
          closeModal();
        }}
      />
    </>
  );
};

export default observer(ProductsListActions);
