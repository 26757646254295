import { types, Instance, destroy } from 'mobx-state-tree';

import Product from './Product';
import Unit from './Unit';

const OrderItem = types
  .model('OrderItem', {
    id: types.maybeNull(types.number),
    _destroy: types.maybeNull(types.boolean),
    isValid: types.maybeNull(types.boolean),
    is_added: types.boolean,
    initial_amount: types.maybeNull(types.number),
    amount: types.maybeNull(types.number),
    notes: types.maybeNull(types.string),
    supplier_note: types.maybeNull(types.string),
    initial_unit: types.maybeNull(Unit),
    unit: types.maybeNull(Unit),
    buyable: types.model({
      type: types.string,
      product: Product,
    }),
    isOrderItem: types.optional(types.literal(true), true),
  })
  .actions((self) => ({
    setAmount(newAmount: number) {
      self.amount = newAmount;
    },
    setUnit(newUnit: Instance<typeof Unit>) {
      self.unit = { ...newUnit };
    },
    setNotes(newNotes: string) {
      self.notes = newNotes;
    },
    setSupplierNote(newSupplierNote: string) {
      self.supplier_note = newSupplierNote;
    },
    markForDeletion() {
      self.amount = 0;

      if (self.id && self.is_added) {
        self._destroy = true;
      } else if (!self.id) {
        destroy(self);
      }
    },
    restore() {
      if (self.initial_amount && self.initial_unit) {
        self.amount = self.initial_amount;
        self.unit = self.initial_unit;
      }
      self._destroy = false;
    },
    validate() {
      if (self._destroy) {
        self.isValid = true;
        return;
      }

      if (self.is_added && !self.amount) {
        self.isValid = false;
      } else {
        self.isValid = true;
      }
    },
  }))
  .views((self) => ({
    get markedForDeletion() {
      if (self.id && !self.is_added) {
        return self.amount == 0;
      } else {
        return self._destroy;
      }
    },
  }));

export default OrderItem;
