import React, { useState } from 'react';
import { useDelivery } from '../../contexts/delivery';
import { DELIVERY_DAYS, DELIVERY_TIMES } from './delivery-options';
import { useSuccessToast, useErrorToast } from '../../components/toast';

import {
  Button as ChakraButton,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Select,
  Text,
  Textarea,
  VStack,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { Button } from '../../components/Button/Button';
import {
  Body2,
  Caption,
  SmallTitle,
  Subhead,
} from '../../components/Typography/Typography';

const DeliveryForm = ({ deliveryData }: any) => {
  const filteredDeliveryData = (({
    delivery_rules_enabled,
    delivery_rules_message,
    delivery_rules,
  }) => ({
    delivery_rules_enabled,
    delivery_rules_message,
    delivery_rules,
  }))(deliveryData);

  const { updateDeliveryData } = useDelivery();
  const [formData, setFormData] = useState(filteredDeliveryData);
  const [hasValidationError, setHasValidationError] = useState(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [validationTitle, setValidationTitle] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const hasDaysEnabled = () => {
    let enabledValues = Object.values(formData.delivery_rules).map(
      (day: any) => day.enabled,
    );
    if (enabledValues.includes(true)) {
      return true;
    } else {
      return false;
    }
  };

  const validateForm = () => {
    if (formData.delivery_rules_enabled && !hasDaysEnabled()) {
      setValidationTitle('You have no delivery dates');
      setValidationMessage(
        'To enable Delivery days & times, you must choose at least one delivery day.',
      );
      setHasValidationError(true);
      return false;
    } else {
      setValidationTitle('');
      setValidationMessage('');
      setHasValidationError(false);
      return true;
    }
  };

  const closeValidationModal = () => {
    setHasValidationError(false);
    setValidationMessage('');
    setValidationTitle('');
  };

  const handleSubmit = (e: React.FormEvent) => {
    setIsSubmitButtonLoading(true);
    e.preventDefault();
    if (validateForm()) {
      updateDeliveryData(
        formData,
        () => {
          successToast({
            description: 'Delivery days & cut-off time details saved.',
          });
          setIsSubmitButtonLoading(false);
        },
        () => {
          errorToast();
        },
      );
    } else {
      setIsSubmitButtonLoading(false);
    }
  };

  return (
    <>
      <Modal isOpen={hasValidationError} onClose={closeValidationModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{validationTitle}</ModalHeader>
          <ModalBody>{validationMessage}</ModalBody>
          <ModalCloseButton />
          <ModalFooter>
            <ButtonGroup>
              <ChakraButton onClick={closeValidationModal}>Ok</ChakraButton>
              <ChakraButton
                colorScheme="red"
                onClick={() => {
                  setFormData({ ...formData, delivery_rules_enabled: false });
                  closeValidationModal();
                }}
              >
                Disable Delivery days &amp; times
              </ChakraButton>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <form onSubmit={handleSubmit}>
        <Flex justify="space-between" mb="4">
          <Body2 color="gray.600">
            Let customers know which days of the week you deliver and <br />
            what the cut-off times are for each delivery day.
          </Body2>
          <Spacer />
          <ButtonGroup>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              isLoading={isSubmitButtonLoading}
            >
              Save
            </Button>
          </ButtonGroup>
        </Flex>

        <VStack spacing="6" align="left" mt="16px">
          <FormControl>
            <HStack>
              <Checkbox
                id="enable-delivery-rules"
                isChecked={formData.delivery_rules_enabled}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    delivery_rules_enabled: e.target.checked,
                  })
                }
              />
              <FormLabel as={Subhead} htmlFor="enable-delivery-rules">
                Enable delivery days & times
              </FormLabel>
            </HStack>

            <Caption as="p" mt="10px" ml="24px" color="gray.600">
              Customers can select delivery for any eligible delivery date.
              Orders can <br />
              be placed after a cut-off time, but will get a warning message.
            </Caption>
          </FormControl>

          <Divider />

          <VStack spacing="2" align="left" mt="6">
            <HStack alignItems="left">
              <SmallTitle width="20%">Delivery day</SmallTitle>
              <SmallTitle>Cut-off time for this day</SmallTitle>
            </HStack>

            {deliveryData &&
              DELIVERY_DAYS.map((day: any, dayIndex: number) => {
                const deliveryRule = deliveryData.delivery_rules[dayIndex];
                return (
                  <HStack key={dayIndex}>
                    <FormControl width="20%">
                      <HStack>
                        <Checkbox
                          bg="white"
                          id={`enable-${day}`}
                          isChecked={formData.delivery_rules[dayIndex].enabled}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              delivery_rules: {
                                ...formData.delivery_rules,
                                [dayIndex]: {
                                  ...formData.delivery_rules[dayIndex],
                                  enabled: e.target.checked,
                                },
                              },
                            })
                          }
                          isDisabled={!formData.delivery_rules_enabled}
                        />
                        <FormLabel htmlFor={`enable-${day}`}>{day}</FormLabel>
                      </HStack>
                    </FormControl>

                    <FormControl width="">
                      <HStack>
                        <Select
                          defaultValue={DELIVERY_TIMES.find(
                            (time) => time === deliveryRule.cutoff_time,
                          )}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              delivery_rules: {
                                ...formData.delivery_rules,
                                [dayIndex]: {
                                  ...formData.delivery_rules[dayIndex],
                                  cutoff_time: e.target.value,
                                },
                              },
                            })
                          }
                          isDisabled={!formData.delivery_rules_enabled}
                        >
                          {DELIVERY_TIMES.map(
                            (time: string, timeIndex: number) => (
                              <option value={time} key={timeIndex}>
                                {time}
                              </option>
                            ),
                          )}
                        </Select>
                        <span>On</span>

                        <Select
                          defaultValue={deliveryRule.cutoff_day}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              delivery_rules: {
                                ...formData.delivery_rules,
                                [dayIndex]: {
                                  ...formData.delivery_rules[dayIndex],
                                  cutoff_day: parseInt(e.target.value, 10),
                                },
                              },
                            })
                          }
                          isDisabled={!formData.delivery_rules_enabled}
                        >
                          {DELIVERY_DAYS.map((day: string, i: number) => (
                            <option key={i} value={i}>
                              {day}
                            </option>
                          ))}
                        </Select>
                      </HStack>
                    </FormControl>
                  </HStack>
                );
              })}
          </VStack>

          <FormControl>
            <FormLabel>Cut-off time warning message</FormLabel>
            <Textarea
              rows={4}
              cols={50}
              value={formData.delivery_rules_message}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  delivery_rules_message: e.target.value,
                })
              }
              maxLength={255}
              isDisabled={!formData.delivery_rules_enabled}
            ></Textarea>
          </FormControl>

          <Text>
            Customers will receive this warning message when requesting delivery
            after the cut-off time. The order will still be sent to you.
          </Text>
        </VStack>
      </form>
    </>
  );
};

export default DeliveryForm;
