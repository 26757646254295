import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  Avatar,
  Button,
  Tooltip,
  Box,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useCustomerGroups } from '../../../hooks/useStores';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import UnlinkedTag from '../../../components/UnlinkedTag/UnlinkedTag';

import StandingOrderTable from '../../../components/StandingOrders/StandingOrderTable';
import { Subhead } from '../../../components/Typography/Typography';

import CustomerGroupForm from './CustomerGroupForm';
import DetailView from '../../../components/DetailView/DetailView';
import { EditBoxIcon } from '../../../components/Icons/IconsNew';
import { Customer } from '../../../stores/customers/customer';
import CustomerCompanyFields from './CustomerCompanyFields';

const ShowCustomer = ({ customer }: { customer: Customer }) => {
  const history = useHistory();
  const { getCustomerGroupSets } = useCustomerGroups();

  const { orderFulfilmentMvpSupplierView } = useFlags();
  const orderFulfilmentOn = ['on', 'on-include-legacy'].includes(
    orderFulfilmentMvpSupplierView,
  );

  const startEdit = () => {
    history.push(`/customers/${customer.id}/edit`);
  };

  useEffect(() => {
    getCustomerGroupSets();
  }, []);
  return (
    <DetailView
      rightActions={
        <>
          {/*
            TODO: Temporarily removed because the delete customer endpoint is not hooked up!
            <CustomerDeleteAction />
          */}
          <Tooltip hasArrow label="Edit customer">
            <Button
              onClick={() => {
                history.push(`/customers/${customer.id}/edit`);
              }}
              leftIcon={<EditBoxIcon width="24px" />}
            >
              Edit
            </Button>
          </Tooltip>
        </>
      }
    >
      <Box style={{ backgroundColor: 'white', marginBottom: '80px' }}>
        <Flex direction="row" py="6">
          <Box>
            <Avatar size="xl" src={customer.logo_url} />
          </Box>
          <Box pl="5" pt="8">
            <Heading size="md" as="h4">
              {customer.name}
            </Heading>
            {customer.is_unlinked && <UnlinkedTag />}
          </Box>
        </Flex>

        <VStack align="left" spacing="8" mt="16">
          <VStack align="left" spacing="8" maxWidth="525px">
            <FormFieldWrapper
              fieldName="note"
              fieldLabel={<Subhead fontWeight="600">Customer note</Subhead>}
              subLabel="Not visible to customers"
            >
              <Text>
                {customer.notes && customer.notes}
                {!customer.notes && (
                  <Button
                    variant="secondary"
                    colorScheme="green"
                    onClick={() => {
                      startEdit();
                    }}
                  >
                    Add customer note
                  </Button>
                )}
              </Text>
              <Text mt="10px" color="gray.600" fontSize="xs">
                Customer note appears on orders from this customer, including
                downloads and prints.
              </Text>
            </FormFieldWrapper>
          </VStack>

          <Divider />

          <VStack
            width="full"
            align="left"
            maxWidth={['583px', null, null, null, null, null, null, 'full']}
          >
            {customer.standingOrder ? (
              <StandingOrderTable
                standingOrder={customer.standingOrder}
                isEditable={false}
              />
            ) : (
              <FormFieldWrapper
                fieldName="standing_order"
                fieldLabel={<Subhead fontWeight="600">Standing order</Subhead>}
              >
                <Button
                  variant="secondary"
                  colorScheme="green"
                  onClick={() => {
                    startEdit();
                    customer.createEmptyStandingOrder();
                  }}
                >
                  Create standing order
                </Button>
              </FormFieldWrapper>
            )}
          </VStack>
          <Divider />

          {orderFulfilmentOn && <CustomerGroupForm isEditable={false} />}

          <CustomerCompanyFields customer={customer} />
        </VStack>
      </Box>
    </DetailView>
  );
};
export default observer(ShowCustomer);
