import { Select, SelectComponent, SingleValue } from 'chakra-react-select';
import { FormikProps } from 'formik';
import { SnapshotOrInstance } from 'mobx-state-tree';
import { useEffect, useState } from 'react';
import { Caption } from '../../../components/Typography/Typography';
import { useCurrentUser } from '../../../hooks/useStores';
import Address, {
  AddressComponents,
  AddressFieldComponents,
} from '../../../models/Address';
import AddAddressForm from './AddAddressForm';

type OrderAddressFieldProps = {
  value: string;
  addresses: SnapshotOrInstance<typeof Address>[];
  onSelectAddress: (address: SnapshotOrInstance<typeof Address>) => void;
  addAddressFormRef?: React.RefObject<FormikProps<AddressFieldComponents>>;
} & Omit<SelectComponent, 'onChange'>;

type OptionType = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

const OrderAddressField = ({
  addresses,
  onSelectAddress,
  value,
  addAddressFormRef,
  ...rest
}: OrderAddressFieldProps) => {
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);

  const handleChange = (option: SingleValue<OptionType>) => {
    if (option) {
      if (option.value === 'new') {
        setShowNewAddressForm(true);
        onSelectAddress({ id: null });
      } else {
        const selectedAddress = addresses.find(
          (address) => `${address.id}` === option.value,
        );
        if (selectedAddress) {
          onSelectAddress(selectedAddress);
          setShowNewAddressForm(false)
        }
      }
    }
  };

  const selectedAddress = addresses.find(
    (address) => `${address.id}` === value,
  );

  const options = [
    ...addresses.map((address) => ({
      value: `${address.id}`,
      label: `${address.full_address}`,
    })),
    {
      value: 'sparator',
      label: '───',
      isDisabled: true,
    },
    {
      value: 'new',
      label: 'Add new address',
    },
  ];

  return (
    <>
      <Select
        useBasicStyles
        isMulti={false}
        onChange={handleChange}
        options={options}
        defaultValue={options.find((o) => o.value === value)}
        selectedOptionStyle="check"
        chakraStyles={{
          option: (styles) => ({
            ...styles,
            _disabled: {
              cursor: 'default',
              py: 0,
            },
            span: {
              mr: 1.5,
            },
            svg: {
              width: '10px',
              color: 'gray.900',
            },
          }),
        }}
      />
      {selectedAddress?.instructions && (
        <Caption color="gray.500">
          Instructions: {selectedAddress?.instructions}
        </Caption>
      )}
      {showNewAddressForm && <AddAddressForm mt={4} />}
    </>
  );
};

export default OrderAddressField;
