import React, { useState } from 'react';
import useAPI from '../../../hooks/useAPI';
import { useAccounting } from '../../../contexts/accounting';

import {
  Box,
  ButtonGroup,
  Button,
  Heading,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Radio,
  RadioGroup,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';

interface SettingsProps {
  currentSettings: any;
  accountingConnectionName: 'MYOB' | 'Xero' | 'Infusion';
}

const Settings = ({
  currentSettings,
  accountingConnectionName,
}: SettingsProps) => {
  const { getCurrentAccountingConnection } = useAccounting();
  const [formState, setFormState] = useState<any>({});
  const [isEditable, setIsEditable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [callSetSettingsApi] = useAPI({
    method: 'PUT',
  });

  const apiUrl = '/v4/accounting_connectors/settings';

  function resetFormState(data: any) {
    let initialFormState: any = {};
    for (const [key, value] of Object.entries(data)) {
      initialFormState[key] = value;
    }
    setFormState(initialFormState);
  }

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsSubmitting(true);
    callSetSettingsApi(apiUrl, {
      body: JSON.stringify(formState),
    })
      .then(() => {
        getCurrentAccountingConnection(() => {
          toggleEditable();
          setIsSubmitting(false);
        });
      })
      .catch(() => {});
  }

  function toggleEditable() {
    if (isEditable) {
      setIsEditable(false);
    }

    if (!isEditable) {
      resetFormState(currentSettings);
      setIsEditable(true);
    }
  }

  const invoiceDueTermOptions: selectOptionInterface[] = [
    { key: 'of_following_month', label: 'of the following month' },
    { key: 'after_invoice', label: 'day(s) after invoice' },
    {
      key: 'after_end_of_invoice_month',
      label: 'day(s) after the end of the invoice month',
    },
    { key: 'of_current_month', label: 'of the current month' },
  ];

  interface selectOptionInterface {
    key: string;
    label: string;
  }
  interface connectorMappingInterface {
    MYOB: selectOptionInterface[];
    Xero: selectOptionInterface[];
    Infusion: selectOptionInterface[];
  }

  const invoiceTaxInclusivityOptionsMap: connectorMappingInterface = {
    MYOB: [
      { key: 'exclusive', label: 'GST exclusive' },
      { key: 'inclusive', label: 'GST inclusive' },
    ],
    Xero: [
      { key: 'exclusive', label: 'GST exclusive' },
      { key: 'inclusive', label: 'GST inclusive' },
      {
        key: 'no_tax',
        label: 'No GST',
      },
    ],
    Infusion: [],
  };

  const invoiceTaxInclusivityOptions =
    invoiceTaxInclusivityOptionsMap[accountingConnectionName];

  const invoiceDateOptions: selectOptionInterface[] = [
    { key: 'order_date', label: 'Date ordered' },
    {
      key: 'delivery_date',
      label: 'Delivery date',
    },
    {
      key: 'exported_date',
      label: `Date sent to ${accountingConnectionName}`,
    },
  ];

  function invoiceTaxInclusivityText() {
    if (currentSettings) {
      if (
        'invoice_tax_inclusivity_override' in currentSettings &&
        !currentSettings.invoice_tax_inclusivity_override
      ) {
        return `Use my tax settings in ${accountingConnectionName}`;
      } else {
        const optionObj = invoiceTaxInclusivityOptions.find(
          (option: selectOptionInterface) =>
            option.key == currentSettings.invoice_tax_inclusivity,
        );
        return (optionObj && optionObj.label) || '';
      }
    } else {
      return '';
    }
  }

  function invoiceTermSummaryText() {
    if (currentSettings) {
      if (
        'invoice_due_override' in currentSettings &&
        !currentSettings.invoice_due_override
      ) {
        return `Use my due date settings in ${accountingConnectionName}`;
      } else {
        const optionObj = invoiceDueTermOptions.find(
          (option: selectOptionInterface) =>
            option.key == currentSettings.invoice_due_term,
        );

        return `${invoiceTermNumText()} ${optionObj && optionObj.label}`;
      }
    } else {
      return '';
    }
  }

  function invoiceTermNumText() {
    if (
      currentSettings.invoice_due_term == 'of_following_month' ||
      currentSettings.invoice_due_term == 'of_current_month'
    ) {
      const n = currentSettings.invoice_due_num;
      const dateOrdinal =
        31 == n || 21 == n || 1 == n
          ? 'st'
          : 22 == n || 2 == n
          ? 'nd'
          : 23 == n || 3 == n
          ? 'rd'
          : 'th';
      return `${currentSettings.invoice_due_num}${dateOrdinal}`;
    } else {
      return `${currentSettings.invoice_due_num}`;
    }
  }

  function invoiceDateText() {
    if (currentSettings) {
      const optionObj = invoiceDateOptions.find(
        (option: selectOptionInterface) =>
          option.key == currentSettings.invoice_date,
      );
      return (optionObj && optionObj.label) || '';
    } else {
      return '';
    }
  }

  return (
    <Box>
      <Heading as="h3" size="md">
        Invoice Settings
      </Heading>

      <Box pt="6">
        <form onSubmit={submitForm}>
          <VStack spacing="6" alignItems="left">
            {'invoice_date' in currentSettings && (
              <FormFieldWrapper
                fieldName="invoice_date"
                fieldLabel="Invoice Date"
              >
                {isEditable && (
                  <Select
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        invoice_date: e.target.value,
                      })
                    }
                    value={formState.invoice_date}
                  >
                    {invoiceDateOptions.map((option: selectOptionInterface) => {
                      return (
                        <option key={option.key} value={option.key}>
                          {option.label}
                        </option>
                      );
                    })}
                  </Select>
                )}
                {!isEditable && <Text>{invoiceDateText()}</Text>}
                <Text fontSize="14px" maxW="340px" pt="1">
                  Create invoices using this date. You can edit individual
                  invoices in {accountingConnectionName} to change this.
                </Text>
              </FormFieldWrapper>
            )}

            {'invoice_due_num' in currentSettings &&
              'invoice_due_term' in currentSettings && (
                <FormFieldWrapper
                  fieldName="invoice_due_override"
                  fieldLabel="Due Date"
                >
                  {isEditable && (
                    <VStack alignItems="left">
                      {'invoice_due_override' in currentSettings && (
                        <RadioGroup
                          onChange={(value) => {
                            setFormState({
                              ...formState,
                              invoice_due_override: value == 'true',
                            });
                          }}
                          value={formState.invoice_due_override.toString()}
                        >
                          <VStack alignItems="left">
                            <Radio value="false">
                              Use my due date settings in{' '}
                              {accountingConnectionName}
                            </Radio>
                            <Radio value="true">
                              Override {accountingConnectionName} settings and
                              use the following
                            </Radio>
                          </VStack>
                        </RadioGroup>
                      )}

                      <HStack>
                        <NumberInput
                          w="100px"
                          value={formState.invoice_due_num}
                          min={0}
                          isDisabled={
                            'invoice_due_override' in currentSettings &&
                            !formState.invoice_due_override
                          }
                          onChange={(value) =>
                            setFormState({
                              ...formState,
                              invoice_due_num: parseInt(value, 10),
                            })
                          }
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <Select
                          value={formState.invoice_due_term}
                          isDisabled={
                            'invoice_due_override' in currentSettings &&
                            !formState.invoice_due_override
                          }
                          onChange={(e) =>
                            setFormState({
                              ...formState,
                              invoice_due_term: e.target.value,
                            })
                          }
                        >
                          {invoiceDueTermOptions.map(
                            (option: selectOptionInterface) => {
                              return (
                                <option key={option.key} value={option.key}>
                                  {option.label}
                                </option>
                              );
                            },
                          )}
                        </Select>
                      </HStack>
                    </VStack>
                  )}
                  {!isEditable && <Text>{invoiceTermSummaryText()}</Text>}
                  <Text fontSize="14px" maxW="340px" pt="1">
                    You can edit individual invoices in{' '}
                    {accountingConnectionName} to change this.
                  </Text>
                </FormFieldWrapper>
              )}

            {'invoice_tax_inclusivity' in currentSettings && (
              <FormFieldWrapper
                fieldName="invoice_tax_inclusivity_override"
                fieldLabel="GST"
              >
                {isEditable && (
                  <VStack alignItems="left">
                    {'invoice_tax_inclusivity_override' in currentSettings && (
                      <RadioGroup
                        onChange={(value) => {
                          setFormState({
                            ...formState,
                            invoice_tax_inclusivity_override: value == 'true',
                          });
                        }}
                        value={formState.invoice_tax_inclusivity_override.toString()}
                      >
                        <VStack alignItems="left">
                          <Radio value="false">
                            Use my tax settings in {accountingConnectionName}
                          </Radio>
                          <Radio value="true">
                            Override {accountingConnectionName} settings and use
                            the following
                          </Radio>
                        </VStack>
                      </RadioGroup>
                    )}

                    <Select
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          invoice_tax_inclusivity: e.target.value,
                        })
                      }
                      value={formState.invoice_tax_inclusivity}
                      isDisabled={
                        'invoice_tax_inclusivity_override' in currentSettings &&
                        !formState.invoice_tax_inclusivity_override
                      }
                    >
                      {invoiceTaxInclusivityOptions.map(
                        (option: selectOptionInterface) => {
                          return (
                            <option key={option.key} value={option.key}>
                              {option.label}
                            </option>
                          );
                        },
                      )}
                    </Select>
                  </VStack>
                )}
                {!isEditable && <Text>{invoiceTaxInclusivityText()}</Text>}
                <Text fontSize="14px" maxW="340px" pt="1">
                  Create invoices using this tax setting. You can edit
                  individual invoices in {accountingConnectionName} to change
                  this.
                </Text>
              </FormFieldWrapper>
            )}

            {'export_type' in currentSettings && (
              <FormFieldWrapper
                fieldName="export_type"
                fieldLabel="Export Type"
              >
                {isEditable && (
                  <Select
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        export_type: e.target.value,
                      })
                    }
                    value={formState.export_type}
                  >
                    <option value="Invoice">Invoice</option>
                    <option value="Quote">Quote</option>
                  </Select>
                )}
                {!isEditable && <Text>{currentSettings.export_type}</Text>}
                <Text fontSize="14px" maxW="340px" pt="1">
                  Export your orders as an {accountingConnectionName} Invoice or
                  Quote. Your preference will depend on your workflow. If in
                  doubt, leave this as Invoice.
                </Text>
              </FormFieldWrapper>
            )}

            <ButtonGroup>
              {isEditable && (
                <>
                  <Button onClick={toggleEditable}>Cancel</Button>
                  <Button
                    type="submit"
                    colorScheme="green"
                    isLoading={isSubmitting}
                  >
                    Save settings
                  </Button>
                </>
              )}
              {!isEditable && (
                <Button onClick={toggleEditable}>Edit settings</Button>
              )}
            </ButtonGroup>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default Settings;
