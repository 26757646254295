import { Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { H3 } from '../Typography/Typography';

interface ActionBarProps {
  title: string;
  rightChildren?: ReactNode;
  children?: ReactNode;
}

const ActionBar = ({ title, children, rightChildren }: ActionBarProps) => {
  return (
    <Box
      width="100%"
      h="full"
      bg="background"
      pt="44px"
      pl="24px"
      pr="16px"
      pb={children ? '16px' : '24px'}
    >
      <Flex
        justifyContent="space-between"
        direction={{ base: 'column', sm: 'row' }}
      >
        <H3>{title}</H3>
        {rightChildren}
      </Flex>
      {children && <Box mt="24px">{children}</Box>}
    </Box>
  );
};

export default ActionBar;
