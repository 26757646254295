import {
  useState,
} from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Input,
  HStack,
  Select,
  InputGroup,
  InputRightElement,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import TabSwitchInput from '../FullTextSwitchInput/TabSwitchInput';

const ProductModalHeader = observer(
  ({
    setFilter,
    setFilterTab = () => { },
    handleSearch,
    filters,
    categories,
    filterTabs,
  }: {
    setFilter: (id: string, value: any) => void;
    setFilterTab?: (value: string) => void;
    handleSearch: (query: string) => void;
    filters: { [key: string]: any };
    filterTabs?: { label: string; value: string }[] | null;
    categories?: any[];
  }) => {
    const [currentQueryFieldValue, setCurrentQueryFieldValue] = useState('');

    return (
      <>
        <Box mb="6">
          <InputGroup>
            <Input
              variant="outline"
              type="search"
              onChange={(e) => {
                setCurrentQueryFieldValue(e.target.value);
                handleSearch(e.target.value);
              }}
              value={currentQueryFieldValue}
              placeholder="Search products"
              name="search"
              bg="white"
              mb={3}
            />
            <InputRightElement
              pointerEvents="none"
              color="gray.500"
              children={<SearchIcon />}
            />
          </InputGroup>
          {filterTabs && (
            <TabSwitchInput
              id="filter-tabs"
              onChange={(value) => setFilterTab(value)}
              options={filterTabs}
              mb={3}
            />)
          }
          {filters.hasOwnProperty('category_id') && (
            <Select
              onChange={(e) => {
                const value = e.target.value === 'all' ? '' : e.target.value;
                setFilter('category_id', value)
              }
              }
              name="category"
              value={
                filters.category_id === '' ? 'all' : filters.category_id
              }
            >
              <option value="all">All categories</option>
              {categories &&
                categories.map((category: any, i: number) => {
                  return (
                    <option key={i} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
            </Select>
          )}
          {filters.hasOwnProperty('status') && (
            <FormControl mt="4">
              <HStack>
                <Switch
                  id="show-all-products"
                  position="relative"
                  size="sm"
                  isChecked={filters.status !== 'active'}
                  onChange={(e) => {
                    setFilter('status', e.target.checked ? '' : 'active');
                  }}
                />
                <FormLabel
                  fontSize="xs"
                  fontWeight="500"
                  htmlFor="show-all-products"
                  cursor="pointer"
                >
                  Show all products
                </FormLabel>
              </HStack>
            </FormControl>
          )}
        </Box>
      </>
    );
  },
);

export default ProductModalHeader;
