import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import { useCustomers } from '../../../hooks/useStores';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';
import ShowCustomer from './ShowCustomer';
import EditCustomer from './EditCustomer';

type CustomerDetailProps = {
  customerId: any;
};

const CustomerDetail = ({ customerId }: CustomerDetailProps): JSX.Element => {
  const { currentCustomer, getCustomer, setCurrentCustomer } = useCustomers();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    if (customerId) {
      getCustomer(customerId, () => {
        setLoading(false);
      });
    } else {
      setCurrentCustomer(undefined);
      setLoading(false);
    }
  }, [customerId]);

  return loading ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/customers/:customerId/edit">
        {currentCustomer && <EditCustomer customer={currentCustomer} />}
      </Route>
      <Route path="/customers/:customerId">
        {currentCustomer && <ShowCustomer customer={currentCustomer} />}
      </Route>
      <Route path="/customers">
        <EmptyDetail />
      </Route>
    </Switch>
  );
};

export default observer(CustomerDetail);
