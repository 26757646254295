import { types } from 'mobx-state-tree';

const Unit = types
  .model('Unit', {
    id: types.number,
    name: types.string,
  })
  .actions((self) => ({
    setId(newId: number) {
      self.id = newId;
    },
  }));

export default Unit;
