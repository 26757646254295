import { Field, useField } from 'formik';
import {
  forwardRef,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Table,
  Tbody,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import { CloseIcon, MenuIcon } from '../../../components/Icons/IconsNew';
import useDragAndDrop from '../../../hooks/useDragAndDrop';
import { DRAG_AND_DROP_TYPES } from '../../../constants';
import { ForwardedRef } from 'react';

interface EditSourceCategoryProductRowProps {
  sourceCategoryProduct: any;
  index: number;
  productErrorIds: number[];
  findItem: (id: string) => any;
  moveItem: (id: string, newIndex: number) => void;
  onDrop: (item: any) => void;
  removeProductErrorId: (id: number) => void;
}

const ForwardTr = forwardRef((props: any, ref: ForwardedRef<any>) => (
  <Tr ref={ref} {...props} />
));

const EditSourceCategoryProductRow = ({
  sourceCategoryProduct,
  index,
  productErrorIds,
  findItem,
  moveItem,
  onDrop,
  removeProductErrorId,
}: EditSourceCategoryProductRowProps) => {
  const [field, meta, helpers] = useField(
    `source_category_products_attributes.${index}`,
  );
  const { opacity, handlerId, handleRef, dragRef, dropRef, ref } =
    useDragAndDrop({
      id: sourceCategoryProduct.key,
      type: DRAG_AND_DROP_TYPES.SOURCE_CATEGORY_PRODUCT_ROW,
      moveItem,
      findItem,
      onDrop,
      item: {
        name: sourceCategoryProduct.product.name,
        product_code: sourceCategoryProduct.product.product_code,
        amount: field.value.amount,
      },
    });

  const hasError = productErrorIds.includes(sourceCategoryProduct.product_id);

  return sourceCategoryProduct._destroy ? (
    <></>
  ) : (
    <Field name={`source_category_products_attributes.${index}`}>
      {({ form, meta }: any) => {
        const showError = (!!meta.error && meta.touched) || hasError;
        return (
          <ForwardTr
            data-handler-id={handlerId}
            ref={dragRef(dropRef(ref))}
            opacity={opacity}
            backgroundColor={showError ? 'red.50' : 'inherit'}
          >
            <Td
              paddingLeft={showError ? '12px' : '4'}
              borderLeft={showError ? '4px solid' : ''}
              borderLeftColor="red.500"
            >
              <IconButton
                ref={handleRef}
                icon={<MenuIcon width="24px" />}
                aria-label="Drag"
                marginRight="4"
              />
              {sourceCategoryProduct.product.name}
            </Td>
            <Td>{sourceCategoryProduct.product.product_code}</Td>
            <Field name={`source_category_products_attributes.${index}.amount`}>
              {({ form, field }: any) =>
                form.values.amounts_required && (
                  <Td>
                    <InputGroup>
                      <Input type="number" placeholder="0" {...field} />
                      <InputRightAddon>{form.values.unit}</InputRightAddon>
                    </InputGroup>
                  </Td>
                )
              }
            </Field>
            <Td textAlign="right">
              <Tooltip hasArrow label="Remove" bg="gray.900">
                <IconButton
                  aria-label="Remove"
                  colorScheme="red"
                  color="red.600"
                  _hover={{
                    backgroundColor: 'red.100',
                  }}
                  variant="ghost"
                  size="sm"
                  icon={<CloseIcon width="24px" />}
                  onClick={() => {
                    form.setFieldValue(
                      `source_category_products_attributes.${index}._destroy`,
                      true,
                    );
                    removeProductErrorId(sourceCategoryProduct.product_id);
                  }}
                />
              </Tooltip>
            </Td>
          </ForwardTr>
        );
      }}
    </Field>
  );
};

export const EditSourceCategoryProductRowPreview = forwardRef(
  ({ item, style }: any, ref) => {
    return (
      <div
        ref={ref}
        style={{
          ...style,
          width: item.dimensions.width,
          height: item.dimensions.height,
        }}
      >
        <Table>
          <Tbody>
            <Tr opacity={0.5} onClick={() => {}} {...item.extraProps}>
              <Td>
                <IconButton
                  icon={<MenuIcon width="24px" />}
                  aria-label="Drag"
                  marginRight="4"
                />
                {item.name}
              </Td>
              <Td>{item.product_code}</Td>
              <Td>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="0"
                    value={item.amount}
                    readOnly
                  />
                  <InputRightAddon>kg</InputRightAddon>
                </InputGroup>
              </Td>
              <Td textAlign="end">
                <IconButton
                  aria-label="Remove"
                  colorScheme="red"
                  color="red.600"
                  variant="ghost"
                  size="sm"
                  icon={<CloseIcon width="24px" />}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
    );
  },
);

export default EditSourceCategoryProductRow;
