import { observer } from 'mobx-react';
import {
  Text,
  HStack,
  Avatar,
  ModalFooter,
  Button as ChakraButton,
  ModalCloseButton,
} from '@chakra-ui/react';
import {
  CaretLeftIcon,
  ShoppingCartIcon,
} from '../../../components/Icons/Icons';
import { useAddOrder } from '../../../contexts/addOrder';
import { pluralizeString } from '../../../utils';

import ProductModalContent from '../../../components/ProductModalContent/ProductModalContent';
import { Button } from '../../../components/Button/Button';
import { useCurrentUser } from '../../../hooks/useStores';

const SelectProductsScreen = ({ onSubmit, onBack }: { onSubmit: () => void, onBack: () => void }) => {
  const { order } = useAddOrder();
  const { isBuyer } = useCurrentUser();

  const isInvalid =
    order.items.length === 0 ||
    order.items.some((item) => item.amount === 0 || item.amount === null);

  return (
    <ProductModalContent
      order={order}
      headerUpperChildren={
        <>
          <ChakraButton
            onClick={onBack}
            variant="ghost"
            leftIcon={<CaretLeftIcon />}
            mb="6"
            pl="0"
          >
            Back
          </ChakraButton>
          <ModalCloseButton top={4} right={4} />
          <HStack mb="10">
            <Avatar
              size="lg"
              src={isBuyer ? order.supplier?.logo : order.customer?.logo}
            />
            <Text>{isBuyer ? order.supplier?.name : order.customer?.name}</Text>
          </HStack>
        </>
      }
      footerChildren={
        <ModalFooter
          backgroundColor="gray.100"
          borderRadius="0 0 8px 8px"
          justifyContent="space-between"
        >
          <HStack>
            <ShoppingCartIcon color="gray.400" />
            <Text color="gray.500">
              {order.items.length} {pluralizeString('item', order.items.length)}
            </Text>
          </HStack>
          <Button variant="primary" isDisabled={isInvalid} onClick={onSubmit}>
            Review order
          </Button>
        </ModalFooter>
      }
    />
  );
};

export default observer(SelectProductsScreen);
