import { ForwardedRef } from 'react';
import { Checkbox, forwardRef, HStack, StackProps } from '@chakra-ui/react';

export interface ListRowProps extends StackProps {
  isCurrent?: boolean;
  isChecked?: boolean;
  isActive?: boolean;
  onClick: () => void;

  hideCheckbox?: boolean;
  onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ListRow = forwardRef(
  (props: ListRowProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
    const {
      isCurrent = false,
      isChecked = false,
      isActive = true,
      hideCheckbox = true,
      onClick,
      onCheckboxChange,
      children,
      ...rest
    } = props;

    function bgColor() {
      if (isChecked) {
        return 'blue.100';
      } else if (isCurrent) {
        return 'blue.50';
      } else if (!isActive) {
        return 'gray.100';
      } else {
        return 'white';
      }
    }

    function hoverBgColor() {
      if (isCurrent || isChecked) {
        return bgColor();
      } else {
        return 'gray.50';
      }
    }

    function boxShadow() {
      const leftBlue = 'inset 3px 0 0 var(--chakra-colors-blue-500)';
      const bottomBlue = 'inset 0 -1px 0 var(--chakra-colors-blue-200)';
      const bottomGray = 'inset 0 -1px 0 var(--chakra-colors-gray-200)';

      if (isCurrent && isChecked) {
        return `${leftBlue}, ${bottomBlue}`;
      } else if (isCurrent) {
        return `${leftBlue}, ${bottomGray}`;
      } else if (isChecked) {
        return `${bottomBlue}`;
      } else {
        return bottomGray;
      }
    }

    function getTargetByHeirarchyLevel(
      target: HTMLInputElement,
      heirarchyLevel: number,
    ) {
      if (heirarchyLevel == 2) {
        return target.parentElement;
      } else if (heirarchyLevel == 3) {
        return target?.parentElement?.parentElement;
      } else if (heirarchyLevel == 4) {
        return target?.parentElement?.parentElement?.parentElement;
      } else {
        return target;
      }
    }

    function shouldPreventOnClick(target: HTMLInputElement) {
      let matchesCriteria = false;
      let heirarchyLevel = 1;

      // Some input elements have nested elements so we need to bubble up the heirarchy to check
      // if they are included in the inputs we want to find.
      while (!matchesCriteria && heirarchyLevel <= 4) {
        let targetToCheck = getTargetByHeirarchyLevel(target, heirarchyLevel);
        if (targetToCheck) {
          targetToCheck.classList.forEach((className) => {
            if (
              className.startsWith('chakra-checkbox') ||
              className.startsWith('chakra-switch')
            ) {
              matchesCriteria = true;
            }
          });
        }
        heirarchyLevel = heirarchyLevel + 1;
      }

      return matchesCriteria;
    }

    return (
      <HStack
        ref={ref}
        cursor="pointer"
        px={6}
        py={!hideCheckbox ? 2 : 3}
        pl={!hideCheckbox ? 2 : 6}
        bg={bgColor()}
        boxShadow={boxShadow()}
        _hover={{
          bg: hoverBgColor(),
        }}
        onClick={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          // We don't want to trigger the event if one of the child inputs is being clicked.
          if (!shouldPreventOnClick(target)) {
            onClick();
          }
        }}
        {...rest}
      >
        {!hideCheckbox && (
          <Checkbox
            variant="table"
            isChecked={isChecked}
            onChange={onCheckboxChange}
          />
        )}
        {children}
      </HStack>
    );
  },
);

export default ListRow;
