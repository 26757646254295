import { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Modal,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import useAPI from '../../../hooks/useAPI';
import { xClientHeaders } from '../../../utils';
import { useAddOrder } from '../../../contexts/addOrder';
import { useErrorToast, useSuccessToast } from '../../../components/toast';

import SelectCompanyScreen from './SelectCompanyScreen';
import SelectProductsScreen from './SelectProductsScreen';
import ReviewOrderScreen from './ReviewOrderScreen';
import CreateNewCustomerScreen from './CreateNewCustomerScreen';
import { useOrders, useCurrentUser } from '../../../hooks/useStores';
import { SnapshotOrInstance } from 'mobx-state-tree';
import Customer from '../../../models/Customer';
import UserCompany from '../../../models/UserCompany';
import Supplier from '../../../models/Supplier';
import { Instance } from 'mobx-state-tree/dist/internal';

const AddOrderModal = () => {
  const mixpanel = useMixpanel();
  const { getOrders } = useOrders();
  const { order } = useAddOrder();
  const { authToken, isBuyer } = useCurrentUser();
  const history = useHistory();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();
  const cancelAlertRef = useRef(null);

  const token = `Bearer ${authToken}`;

  const [createOrder, isLoading] = useAPI({
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      ...xClientHeaders,
    },
  });

  const sendOrder = () => {
    const formData = order.formState();
    createOrder('/v4/orders', {
      body: JSON.stringify(formData),
    })
      .then((data: any) => {
        mixpanel.track('Add Order -> Save');
        successToast({
          description: `Order added for ${order.customer?.name}`,
        });
        history.push(`/orders/${data.id}`);
        getOrders(
          () => {},
          () => {},
          isBuyer ? 'outgoing' : 'incoming',
        );
      })
      .catch(() => {
        errorToast({
          description: 'Something went wrong and your order failed to save.',
        });
      });
  };

  const handleClose = () => {
    alertOnOpen();
  };

  const confirmClose = () => {
    onClose();
    history.push('/orders');
  };

  return (
    <>
      <AlertDialog
        isOpen={alertIsOpen}
        onClose={alertOnClose}
        leastDestructiveRef={cancelAlertRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent marginTop="7.5rem">
            <AlertDialogHeader fontSize="lg" fontWeight="700">
              Quit order?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelAlertRef} onClick={alertOnClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmClose} ml={3}>
                Yes, quit order
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="xl"
        closeOnOverlayClick={false}
        autoFocus={false}
      >
        <ModalOverlay />
        <AddOrderModalContent
          submitOrder={sendOrder}
          isSubmitting={isLoading}
        />
      </Modal>
    </>
  );
};

type ModalScreen =
  | 'select-company'
  | 'select-products'
  | 'review-order'
  | 'new-customer';

const AddOrderModalContent = observer(
  ({
    submitOrder,
    isSubmitting,
  }: {
    submitOrder: () => void;
    isSubmitting: boolean;
  }) => {
    const { order } = useAddOrder();
    const { company: userCompany, authToken, isBuyer } = useCurrentUser();

    const token = `Bearer ${authToken}`;

    const [getSupplierInfo, loadingSupplier] = useAPI({
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        ...xClientHeaders,
      },
    });

    const [modalScreen, setModalScreen] =
      useState<ModalScreen>('select-company');

    const handleCompanySelect = ({
      customer,
      supplier = userCompany,
    }: {
      customer:
        | SnapshotOrInstance<typeof Customer>
        | Instance<typeof UserCompany>;
      supplier:
        | SnapshotOrInstance<typeof Supplier>
        | Instance<typeof UserCompany>
        | undefined;
    }) => {
      if (order?.customer?.id !== customer.id) {
        order.reset();
      }

      order.setCustomer(customer);
      if (isBuyer && supplier) {
        getSupplierInfo(`/v4/companies/${supplier.id}`).then((data) => {
          order.setSupplierCompany(data);
          order.setDeliveryDate(order.earliestDeliveryDate)
          setModalScreen('select-products');
        });
        if (userCompany?.default_address_obj) {
          order.setAddress({
            id: userCompany.default_address_obj.id,
            full_address: userCompany.default_address_obj.full_address,
            instructions: userCompany.default_address_obj.instructions,
          })
        }
      } else {
        if (supplier) {
          order.setSupplierCompany(supplier);
        }
        setModalScreen('select-products');
      }
    };

    switch (modalScreen) {
      case 'select-company':
        return (
          <SelectCompanyScreen
            onCompanySelect={(selectedCompany) => {
              if (userCompany) {
                handleCompanySelect({
                  customer: isBuyer ? userCompany : selectedCompany,
                  supplier: isBuyer ? selectedCompany : userCompany,
                });
              }
            }}
            endpoint={isBuyer ? 'suppliers' : 'customers'}
            onStartNewCustomer={
              !isBuyer ? () => setModalScreen('new-customer') : undefined
            }
          />
        );

      case 'select-products':
        return (
          <SelectProductsScreen
            onBack={() => {
              order.clearCustomer();
              setModalScreen('select-company');
            }}
            onSubmit={() => setModalScreen('review-order')}
          />
        );

      case 'review-order':
        return (
          <ReviewOrderScreen
            onBack={() => setModalScreen('select-products')}
            onSubmit={submitOrder}
            isLoading={isSubmitting}
          />
        );

      case 'new-customer':
        return (
          <CreateNewCustomerScreen
            onSubmit={() => setModalScreen('select-products')}
          />
        );
    }
  },
);

export default observer(AddOrderModal);
