import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_API_LIBRARIES } from '../../constants';
import {
  GoogleMap,
  GoogleMapProps,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import { Spinner } from '@chakra-ui/react';
import { ReactComponent as MarkerIcon } from './MapMarker.svg';
import { renderToStaticMarkup } from 'react-dom/server';
import { useCallback } from 'react';
import ADDRESS_MAP_TYPE from './MapTheme';

const markerSvgString = encodeURIComponent(
  renderToStaticMarkup(<MarkerIcon />),
);

const markerSvgUrl = `data:image/svg+xml,${markerSvgString}`;

type AddressMapProps = {
  lat: number;
  lng: number;
} & GoogleMapProps;

const googleMapLibraries = GOOGLE_MAPS_API_LIBRARIES;

const AddressMap = ({ lat, lng, zoom, options, ...rest }: AddressMapProps) => (
  <GoogleMap
    zoom={zoom || 14}
    center={{ lat, lng }}
    mapTypeId="address_map_style"
    options={{
      disableDefaultUI: true,
      gestureHandling: 'cooperative',
      ...options
    }}
    {...rest}
  >
    <Marker position={{ lat, lng }} icon={{
      url: markerSvgUrl,
      anchor: new google.maps.Point(19, 32),
    }} />
  </GoogleMap>
);

const MapComponent = (props: AddressMapProps) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: googleMapLibraries,
  });

  const onLoad = useCallback(
    (mapInstance) => {
      const address_map_type = new google.maps.StyledMapType(ADDRESS_MAP_TYPE, {
        name: "Styled Map"
      });
      mapInstance.mapTypes.set('address_map_style', address_map_type);
      mapInstance.setMapTypeId('address_map_style');
    },
    []
  )

  return isLoaded ? (
    <>
      <AddressMap
        mapContainerStyle={{
          width: '100%',
          height: '100%',
          borderRadius: 'inherit',
        }}
        onLoad={onLoad}
        {...props}
      />
    </>
  ) : (
      <Spinner thickness="6px" size="xl" color="gray.400" emptyColor="gray.200" />
    );
};

export default MapComponent;
