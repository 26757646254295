import { Flex } from '@chakra-ui/react';

const OneUpWrapper = ({ headerChildren, children }: any) => {
  return (
    <>
      <Flex
        flexDirection="row"
        flex="1"
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        {headerChildren}
      </Flex>

      <Flex
        flexDirection="column"
        height="100%"
        flex="1 1 auto"
        overflow="hidden"
      >
        {children}
      </Flex>
    </>
  );
};

export default OneUpWrapper;
