import { findIndex } from 'lodash';
import { toJS } from 'mobx';
import { cast, types } from 'mobx-state-tree';
import { v4 as uuid4 } from 'uuid';
import PriceTier from './PriceTier';

import SourceCategoryProduct from './SourceCategoryProduct';
import Unit from './Unit';

const Product = types
  .model('Product', {
    id: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    active: true,
    visible_to_customers: true,
    price: types.maybeNull(types.string),
    favourite: types.maybeNull(types.boolean),
    default_price: types.maybeNull(types.string),
    product_code: types.maybeNull(types.string),
    wholesale_supplier: types.maybeNull(types.string),
    company_ids: types.array(types.number),
    customer_group_ids: types.array(types.number),
    category: types.maybeNull(
      types.model({
        id: types.number,
        name: types.string,
      }),
    ),
    unit: types.maybeNull(Unit),
    pricing_unit: types.maybeNull(Unit),
    units: types.array(Unit),
    hasAddedData: false,
    sourceCategories: types.array(SourceCategoryProduct),
    isCurrentProduct: false,
    isChecked: false,
    isValid: types.maybeNull(types.boolean),
    fieldErrors: types.array(
      types.model({
        fieldName: types.string,
        message: types.string,
      }),
    ),
    pricing_tiers: types.maybeNull(types.array(PriceTier)),
  })
  .views((self) => ({
    get formState() {
      return {
        name: self.name,
        price: self.price,
        default_price: self.default_price,
        unit_id: self.unit?.id,
        category_id: self.category?.id,
        active: self.active,
        visible_to_customers: self.visible_to_customers,
        description: self.description,
        favourite: self.favourite,
        pricing_unit_id: self.pricing_unit?.id,
        product_code: self.product_code,
        wholesale_supplier: self.wholesale_supplier,
        unit_ids: self.units.map((unit) => unit.id),
        company_ids: self.company_ids,
        customer_group_ids: self.customer_group_ids,
        source_category_products_attributes: self.sourceCategories.map(
          (pcp) => pcp.formState,
        ),
        pricing_tiers: self.pricing_tiers,
      };
    },
    get checkedUnits() {
      return self.units.map((u) => u.id);
    },
  }))
  .actions((self) => ({
    setName(name: string) {
      self.name = name;
    },
    setActive(active: boolean) {
      self.active = active;
    },
    setDescription(description: string) {
      self.description = description;
    },
    setCategory(category: any) {
      self.category = toJS(category);
    },
    setIsCurrentProduct(isCurrent: boolean) {
      self.isCurrentProduct = isCurrent;
    },
    toggleChecked() {
      self.isChecked = !self.isChecked;
    },
    setIsChecked(isChecked: boolean) {
      self.isChecked = isChecked;
    },
    setUnit(unit: any) {
      self.unit = toJS(unit);
    },
    toggleUnit(unit: any) {
      let isChecked = true;
      const index = findIndex(self.units, (u) => u.id === unit.id);

      if (index > -1) {
        isChecked = false;
        self.units.splice(index, 1);

        if (self.units.length > 0) {
          self.unit = toJS(self.units[0]);
        } else {
          self.unit = null;
        }
      } else {
        const newUnit = toJS(unit);
        self.units.push(newUnit);

        if (!self.unit) {
          self.unit = toJS(newUnit);
        }
      }
    },
    setProductCode(code: string) {
      self.product_code = code;
    },
    setPrice(price: string) {
      self.price = price;
    },
    setPricingUnit(unit: any) {
      self.pricing_unit = unit;
    },
    setDefaultPrice(price: string) {
      self.default_price = price;
    },
    setHideFromCustomers(value: boolean) {
      self.visible_to_customers = !value;
    },
    setVisbileToCustomers(value: boolean) {
      self.visible_to_customers = value;
    },
    setWholesaleSupplier(supplier: string) {
      self.wholesale_supplier = supplier;
    },
    setCompanyIds(companyIds: (number | string)[]) {
      let companyNumIds = companyIds.map(Number);
      self.company_ids = cast(companyNumIds);
    },
    setCustomerGroupIds(groupIds: (number | string)[]) {
      let groupNumIds = groupIds.map(Number);
      self.customer_group_ids = cast(groupNumIds);
    },
    setFieldErrors(errors: any) {
      self.fieldErrors = errors;
    },
    addSourceCategory() {
      self.sourceCategories.push(SourceCategoryProduct.create({ id: uuid4() }));
    },
    setFavourite(value: boolean) {
      self.favourite = value
    },
    addData(product: any) {
      if (!self.hasAddedData) {
        self.hasAddedData = true;
        self.wholesale_supplier = product.wholesale_supplier;
        self.company_ids = product.company_ids;
        self.default_price = product.default_price;
        self.visible_to_customers = product.visible_to_customers;
        self.customer_group_ids = product.customer_group_ids;
        self.favourite = product.favourite;
        product.source_category_products.forEach((pcp: any) => {
          self.sourceCategories.push(SourceCategoryProduct.create(pcp));
        });
      }
    },
    addPricingTiers(pricingTiers: any) {
      self.pricing_tiers = pricingTiers;
    },
    updateData(product: any) {
      self.name = product.name;
      self.description = product.description;
      self.active = product.active;
      self.visible_to_customers = product.visible_to_customers;
      self.price = product.price;
      self.favourite = product.favourite;
      self.default_price = product.default_price;
      self.product_code = product.product_code;
      self.wholesale_supplier = product.wholesale_supplier;
      self.company_ids = product.company_ids;
      self.customer_group_ids = product.customer_group_ids;
      self.category = product.category;
      self.unit = product.unit;
      self.pricing_unit = product.pricing_unit;
      self.units = product.units;
      self.sourceCategories = product.source_category_products.map((pcp: any) =>
        SourceCategoryProduct.create(pcp),
      );
    },
  }))
  .actions((self) => ({
    validate(categories: any) {
      let isValid = true;
      let newFieldErrors = [];

      if (!self.category?.id) {
        newFieldErrors.push({
          fieldName: 'category_id',
          message: 'Category is a required field.',
        });
      }

      if (!self.name) {
        newFieldErrors.push({
          fieldName: 'name',
          message: 'Name is a required field.',
        });
      }

      if (self.price && !self.pricing_unit?.id) {
        newFieldErrors.push({
          fieldName: 'pricing_unit_id',
          message: 'A pricing unit is required if you set a price.',
        });
      }

      if (!self.unit?.id) {
        newFieldErrors.push({
          fieldName: 'unit_id',
          message: 'Allowed unit(s) is a required field.',
        });
      }

      if (self.description && self.description.length > 255) {
        newFieldErrors.push({
          fieldName: 'description',
          message: 'The description must be 255 characters or less.',
        });
      }

      if (
        categories &&
        self.sourceCategories &&
        self.sourceCategories.some((pcp) => pcp.amountsRequired(categories))
      ) {
        if (self.units.length > 1) {
          const errorCategories = self.sourceCategories
            .filter((pcp) => pcp.amountsRequired(categories))
            .map((c) =>
              categories.find((cat: any) => cat.id === c.source_category_id),
            );
          const categoryString = errorCategories.reduce(
            (a, t, i) => `${a}${i ? ', ' : ''}‘${t.name}’`,
            '',
          );
          newFieldErrors.push({
            fieldName: 'units',
            message:
              'Product can only have one unit because it is assigned to a source category which requires item sizes: ' +
              categoryString,
          });
        }
      }

      if (categories && self.sourceCategories) {
        self.sourceCategories.forEach((pcp) => {
          if (pcp._destroy !== true) {
            if (!pcp.source_category_id) {
              newFieldErrors.push({
                fieldName: `category-${pcp.id}`,
                message: 'You must select a source category.',
              });
            }
            if (
              pcp.source_category_id &&
              self.sourceCategories
                .filter((c) => c.id !== pcp.id && c._destroy !== true)
                .some((c) => c.source_category_id === pcp.source_category_id)
            ) {
              newFieldErrors.push({
                fieldName: `category-${pcp.id}`,
                message: 'You cannot select the same source category twice.',
              });
            }
            if (pcp.amountsRequired(categories)) {
              if (!pcp.amount) {
                newFieldErrors.push({
                  fieldName: `amount-${pcp.id}`,
                  message: 'Item size is required for this source category.',
                });
              }

              if (
                pcp.amount &&
                /^(\d{0,5})(\.{1}\d{0,3})?$/.test(pcp.amount) === false
              ) {
                newFieldErrors.push({
                  fieldName: `amount-${pcp.id}`,
                  message:
                    'Item size must be a number no greater than 99999.999.',
                });
              }
            }
          }
        });
      }

      if (newFieldErrors.length > 0) {
        isValid = false;
        self.setFieldErrors(newFieldErrors);
      }

      self.isValid = isValid;
    },
  }));

export default Product;
