import { SearchIcon } from '@chakra-ui/icons';
import {
  CheckboxProps,
  InputGroup,
  Input,
  InputRightElement,
  Text,
  Divider,
  Box,
} from '@chakra-ui/react';
import { useState, useEffect, useRef, Fragment, ReactNode } from 'react';
import { ViewportList } from 'react-viewport-list';
import CheckboxRow from '../Modal/CheckboxRow';

type SelectGroupProps = {
  title?: string;
  options: Option[];
  onSelect: (id: string, value: boolean) => void;
  onSelectAll?: (value: boolean) => void;
  onBack?: () => void;
  search?: boolean;
  searchPlacholder?: string;
  minSearchLength?: number;
};

type Option = {
  value: string;
  selected?: boolean;
  label?: string;
  subtext?: string | ReactNode;
  inputId?: string | null;
  disabled?: boolean;
  checkboxProps?: CheckboxProps;
};

const SelectModalBody = ({
  options,
  onSelect,
  onSelectAll,
  search = false,
  searchPlacholder = 'Search',
  minSearchLength = 1,
}: SelectGroupProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const viewportRef = useRef<HTMLDivElement | null>(null);
  const allSelected = options.every((option) => option.selected);
  const allSelectedIndeterminate =
    !allSelected && options.some((option) => option.selected && !option.disabled);

  useEffect(() => {
    if (searchValue.length >= minSearchLength) {
      setFilteredOptions(
        options.filter((option) =>
          option.label?.toLowerCase().includes(searchValue.toLowerCase()),
        ),
      );
    } else {
      setFilteredOptions(options);
    }
  }, [searchValue, options]);

  return (
    <>
      {search && (
        <Box px={6} mb={1}>
          <InputGroup mb={2}>
            <Input
              variant="outline"
              type="search"
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={searchPlacholder}
              name="search"
              backgroundColor="gray.50"
            />
            <InputRightElement
              pointerEvents="none"
              color="gray.500"
              children={<SearchIcon />}
            />
          </InputGroup>
          {onSelectAll && (
            <CheckboxRow
              key="modal-list-select-all"
              label="Select all"
              checked={allSelected}
              isIndeterminate={allSelectedIndeterminate}
              value={'select-all'}
              inputId="modal-list-select-all"
              onChange={(e) => onSelectAll(e.target.checked)}
            />
          )}
        </Box>
      )}
        <Divider />
      {!filteredOptions.length && (
        <Text color="gray.500" m={2} textAlign="center">
          No results found
        </Text>
      )}
      <Box
        minH="100%"
        display="block"
        overflowY="scroll"
        ref={viewportRef}
        px={6}
        pt={3}
      >
        <ViewportList key={'modal-list'} items={filteredOptions} overscan={10}>
          {(option, index) => (
            <Box key={`${option.inputId || option.value}-wrapper`}>
              <CheckboxRow
                key={option.inputId || `modal-list-option-${option.value}`}
                label={option.label}
                checked={option.selected}
                value={option.value}
                inputId={option.inputId || `modal-list-option-${option.value}`}
                onChange={(e) => onSelect(option.value, e.target.checked)}
                subtext={option.subtext}
                isDisabled={option.disabled}
                {...option.checkboxProps}
              />
              {index < filteredOptions.length - 1 && <Divider />}
            </Box>
          )}
        </ViewportList>
      </Box>
    </>
  );
};

export default SelectModalBody;
