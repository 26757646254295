import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Box, Center, VStack } from '@chakra-ui/react';

import { useCustomerGroups } from '../../hooks/useStores';
import PageLoadSpinner from '../../components/PageLoadSpinner/PageLoadSpinner';
import LoadingToast from '../../components/LoadingToast/LoadingToast';
import AppWrapper from '../../components/Layouts/AppWrapper';
import TwoUpWrapper from '../../components/Layouts/TwoUpWrapper';
import TwoUpLeftScrollPanel from '../../components/Layouts/TwoUpLeftScrollPanel';
import { Body2, Headline } from '../../components/Typography/Typography';
import CustomerGroupsList from './List/CustomerGroupsList';
import CustomerGroupDetail from './Detail/CustomerGroupDetail';
import CustomerGroupsPageActions from './CustomerGroupsPageActions';
import OneUpWrapper from '../../components/Layouts/OneUpWrapper';
import { Button } from '../../components/Button/Button';
import { PlusIcon } from '../../components/Icons/IconsNew';
import AddCustomerGroupSetModal from './Detail/AddCustomerGroupSetModal';

const CustomerGroupsPage = ({
  match: {
    params: { groupId, groupSetId },
  },
}: RouteComponentProps<{
  groupId: string;
  groupSetId: string;
}>): JSX.Element => {
  const {
    pagination: { totalCount },
    customerGroupSets,
    getCustomerGroupSets,
  } = useCustomerGroups();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCustomerGroupSets()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <AppWrapper>
      <Route path="/customer-group-sets/new">
        <AddCustomerGroupSetModal />
      </Route>
      {isLoading ? (
        <>
          <PageLoadSpinner />
          <LoadingToast isOpen={isLoading} />
        </>
      ) : totalCount === 0 ? (
        <OneUpWrapper headerChildren={<CustomerGroupsPageActions />}>
          <Center height="100%">
            <VStack align="left">
              <Headline>You have no groups yet</Headline>
              <Body2>Organise your customers into groups...</Body2>
              <Box>
                <Button
                  mt="10"
                  leftIcon={<PlusIcon width="24px" />}
                  href="/customer-group-sets/new"
                >
                  Add new set
                </Button>
              </Box>
            </VStack>
          </Center>
        </OneUpWrapper>
      ) : (
        <TwoUpWrapper
          headerChildren={<CustomerGroupsPageActions />}
          leftChildren={
            <>
              <TwoUpLeftScrollPanel>
                <CustomerGroupsList />
              </TwoUpLeftScrollPanel>
            </>
          }
          rightChildren={
            <>
              <CustomerGroupDetail groupId={groupId} groupSetId={groupSetId} />
            </>
          }
        />
      )}
    </AppWrapper>
  );
};

export default observer(CustomerGroupsPage);
