import React, { useState } from 'react';
import { useAccounting } from '../../../contexts/accounting';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';

import { Button, Text } from '@chakra-ui/react';

const DisconnectButton = ({
  platformName,
  requiresManualStep,
}: any): JSX.Element => {
  const { getCurrentAccountingConnection, accountDisconnect } = useAccounting();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  function disconnect() {
    setButtonIsLoading(true);
    accountDisconnect(() => {
      getCurrentAccountingConnection(() => {
        setButtonIsLoading(false);
      });
    });
    setModalIsOpen(false);
  }

  return (
    <>
      <Button
        onClick={() => setModalIsOpen(true)}
        variant="outline"
        isLoading={buttonIsLoading}
      >
        Disconnect
      </Button>

      <ConfirmationDialog
        isOpen={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
        }}
        titleText={`Disconnect ${platformName}?`}
        bodyContent={
          <>
            <Text>
              Are you sure you want to disconnect {platformName} from your
              HospoConnect account?
            </Text>
            {requiresManualStep && (
              <Text mt="2">
                To complete this process, also remove HospoConnect from your{' '}
                {platformName} settings.
              </Text>
            )}
          </>
        }
        confirmButtonText={'Disconnect'}
        onConfirm={disconnect}
      />
    </>
  );
};

export default DisconnectButton;
