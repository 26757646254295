import { Flex } from '@chakra-ui/react';
import EmptyBox from '../Detail/EmptyBox';
import PageLoadSpinner from '../PageLoadSpinner/PageLoadSpinner';

const LoadingDetail = () => (
  <Flex
    flexDirection="column"
    flex={{ base: '2', xl: '3' }}
    borderTop="1px solid"
    borderColor="gray.200"
  >
    <PageLoadSpinner />
  </Flex>
);

export default LoadingDetail;
