import { useEffect, useState, useCallback } from 'react';
import {
  Text,
  HStack,
  VStack,
  Box,
  Select,
  Input,
  Button as ChakraButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Center,
  Spinner,
  Link,
} from '@chakra-ui/react';
import { AddIcon, CheckIcon } from '@chakra-ui/icons';
import { debounce } from 'lodash';

import useAPI from '../../hooks/useAPI';
import { useAuth } from '../../contexts/auth';
import { useScroll } from '../../hooks/useScroll';
import ProductsListView from '../ProductModalContent/ProductsListView';
import { Button } from '../Button/Button';

const ProductRow = ({ product, initialIsAdded, onAdd }: any) => {
  const [isAdded, setIsAdded] = useState(initialIsAdded);
  return (
    <HStack
      justify="space-between"
      boxShadow="inset 0px -1px 0px #E5E7EB"
      px={6}
      py={3}
    >
      <Box w="100%">
        <ProductsListView product={product} />
      </Box>
      {isAdded ? (
        <ChakraButton
          variant="outline"
          width="100px"
          disabled={true}
          leftIcon={<CheckIcon />}
        >
          Added
        </ChakraButton>
      ) : (
        <ChakraButton
          variant="outline"
          width="80px"
          leftIcon={<AddIcon />}
          onClick={() => {
            setIsAdded(true);
            onAdd(product);
          }}
        >
          Add
        </ChakraButton>
      )}
    </HStack>
  );
};

const AddProductModal = ({ isOpen, onClose, onAdd, addedProducts }: any) => {
  const { user } = useAuth();

  const limit = 20;
  const [page, setPage] = useState(1);
  const [currentQueryFieldValue, setCurrentQueryFieldValue] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const [resultCount, setResultCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useScroll<HTMLDivElement>(() => {
    setPage(page + 1);
  });

  const [getProducts] = useAPI({
    method: 'GET',
  });
  const [getCategories] = useAPI({
    method: 'GET',
  });
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const clearResults = () => {
    setProducts([]);
    setPage(1);
  };

  const clearFilters = () => {
    clearResults();
    setCurrentQueryFieldValue('');
    setCurrentQuery('');
    setCurrentCategory('');
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      clearResults();
      setCurrentQuery(value);
    }, 400),
    [],
  );

  const handleCategory = (value: string) => {
    clearResults();

    if (value === 'all') {
      setCurrentCategory('');
    } else {
      setCurrentCategory(value);
    }
  };

  useEffect(() => {
    const url = `/v4/products?page=${page}&limit=${limit}${
      currentQuery && '&q=' + currentQuery
    }${currentCategory && '&category_id=' + currentCategory}`;
    setIsLoading(true);

    getProducts(url).then((data: any) => {
      setResultCount(data.total_count);
      setProducts((products) => products.concat(data.results));
      setIsLoading(false);
    });
  }, [page, currentQuery, currentCategory]);

  useEffect(() => {
    getCategories(`/v2/companies/${user.company.id}/categories`).then(
      (data: any) => {
        setCategories(data);
      },
    );
  }, [user?.company?.id]);

  return (
    <Modal isOpen={isOpen} onClose={() => {}} autoFocus={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={0} pb={0}>
          <Text px={6} mt={4} mb={6}>
            Add another product
          </Text>

          <Box
            py={4}
            px={6}
            bg="gray.100"
            boxShadow="inset 0px 1px 0px #D1D5DB, inset 0px -1px 0px #D1D5DB"
          >
            <Input
              variant="outline"
              type="search"
              onChange={(e) => {
                setCurrentQueryFieldValue(e.target.value);
                handleSearch(e.target.value);
              }}
              value={currentQueryFieldValue}
              placeholder="Search"
              name="search"
              bg="white"
              mb={3}
            />
            <Select
              onChange={(e) => handleCategory(e.target.value)}
              name="category"
              value={currentCategory === '' ? 'All' : currentCategory}
            >
              <option value="all">All</option>
              {categories &&
                categories.map((category: any, i: number) => {
                  return (
                    <option key={i} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
            </Select>
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          {products.length > 0 ? (
            <Box ref={scrollRef} maxHeight="320px" overflowY="scroll">
              {products.map((product: any) => {
                return (
                  <ProductRow
                    key={product.id}
                    product={product}
                    initialIsAdded={addedProducts.includes(product.id)}
                    onAdd={onAdd}
                  />
                );
              })}
            </Box>
          ) : (
            !isLoading &&
            resultCount == 0 && (
              <VStack px={6} py={4} spacing="4" alignItems="left">
                <Text fontSize="lg" fontWeight="500">
                  No products found
                </Text>
                <Link fontWeight="400" onClick={clearFilters}>
                  Clear filters
                </Link>
              </VStack>
            )
          )}
          {isLoading && (
            <Center height="100px">
              <Spinner thickness="6px" size="xl" color="green" />
            </Center>
          )}
        </ModalBody>
        <ModalFooter backgroundColor="gray.100" borderRadius="0 0 8px 8px">
          <Button variant="primary" onClick={onClose}>
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddProductModal;
