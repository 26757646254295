import { cssVar } from "@chakra-ui/theme-tools"

const Tooltip = {
  baseStyle: {
    color: 'white',
    bg: 'gray.900',
    [cssVar("popper-arrow-bg").variable]: 'colors.gray.900',
    borderRadius: 'base',
    padding: '8px',
    textAlign: 'center'
  }
}

export default Tooltip;
