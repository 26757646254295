import { Box, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import AddressStatus from '../../../../components/Orders/AddressStatus';
import InstructionStatus from '../../../../components/Orders/InstructionStatus';
import { SmallTitle } from '../../../../components/Typography/Typography';
import OrderItemsTable from '../OrderItemsTable';
import { ReadOnlyDetailWrapper } from './DetailWrapper';

type OrderDetailTitleRowProps = {
  showEditedFieldStatuses?: boolean;
  order: any;
};

export const OrderDetailFields = ({
  showEditedFieldStatuses = true,
  order,
}: OrderDetailTitleRowProps) => {

  return (
    <VStack spacing="6" pt="6" alignItems="left">
      {order.delivery_date && (
        <ReadOnlyDetailWrapper
          label="Delivery date"
          content={moment(order.delivery_date).format('dddd, D MMMM YYYY')}
          subText={
            order.initial_delivery_date &&
            order.delivery_date &&
            order.delivery_date !== order.initial_delivery_date
              ? moment(order.initial_delivery_date).format('ddd D MMMM YYYY')
              : undefined
          }
        />
      )}
      {(order.delivery_address || order.initial_delivery_address) && (
        <ReadOnlyDetailWrapper
          label="Delivery address"
          content={order.delivery_address || '-'}
          subText={
            order.delivery_address !== order.initial_delivery_address
              ? order.initial_delivery_address || 'None supplied'
              : undefined
          }
        >
          {showEditedFieldStatuses && order.delivery_address_status && (
            <AddressStatus mt="1.5" status={order.delivery_address_status} />
          )}
        </ReadOnlyDetailWrapper>
      )}
      {(order.delivery_instructions || order.initial_delivery_instructions) && (
        <ReadOnlyDetailWrapper
          label="Delivery instructions"
          content={order.delivery_instructions || '-'}
          subText={
            order.delivery_instructions !== order.initial_delivery_instructions
              ? order.initial_delivery_instructions || 'None supplied'
              : undefined
          }
        >
          {showEditedFieldStatuses && order.delivery_instructions_status && (
            <InstructionStatus
              mt="1.5"
              status={order.delivery_instructions_status}
            />
          )}
        </ReadOnlyDetailWrapper>
      )}
      {order.message && (
        <ReadOnlyDetailWrapper label="Message" content={order.message} />
      )}
      {order.customer_company.notes && (
        <ReadOnlyDetailWrapper
          label="Customer notes"
          content={order.customer_company.notes}
        />
      )}
      <Box>
        <SmallTitle mb="8px" color="gray.400">
          Order
        </SmallTitle>
        <OrderItemsTable orderItems={order.order_items} isEditable={false} />
      </Box>
    </VStack>
  );
};

export default OrderDetailFields;
