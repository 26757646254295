import { makeObservable, observable, action, computed } from 'mobx';
import { isEmpty } from 'lodash';
import { Instance } from 'mobx-state-tree';

import { CustomerStore } from './customerStore';
import Unit from '../../models/Unit';

export type AmountDay =
  | 'amountMon'
  | 'amountTue'
  | 'amountWed'
  | 'amountThu'
  | 'amountFri'
  | 'amountSat'
  | 'amountSun';

export interface StandingOrderItemProps {
  id?: number;
  amount_mon?: number;
  amount_tue?: number;
  amount_wed?: number;
  amount_thu?: number;
  amount_fri?: number;
  amount_sat?: number;
  amount_sun?: number;
  unit: Instance<typeof Unit>;
  buyable: {
    type: string;
    product: any;
  };
}

export class StandingOrderItem {
  id?: number;
  @observable amountMon?: number;
  @observable amountTue?: number;
  @observable amountWed?: number;
  @observable amountThu?: number;
  @observable amountFri?: number;
  @observable amountSat?: number;
  @observable amountSun?: number;
  @observable destroy: boolean = false;
  @observable unit: Instance<typeof Unit>;
  buyable: {
    type: string;
    product: any;
  };

  constructor(props: StandingOrderItemProps) {
    const {
      id,
      amount_mon,
      amount_tue,
      amount_wed,
      amount_thu,
      amount_fri,
      amount_sat,
      amount_sun,
      unit,
      buyable,
    } = props;
    this.id = id;
    this.amountMon = amount_mon;
    this.amountTue = amount_tue;
    this.amountWed = amount_wed;
    this.amountThu = amount_thu;
    this.amountFri = amount_fri;
    this.amountSat = amount_sat;
    this.amountSun = amount_sun;
    this.unit = Unit.create(unit);
    this.buyable = buyable;

    makeObservable(this);
  }

  @action setAmount = (day: AmountDay, value: number) => {
    this[day] = value;
  };
  @action setUnit = (id: number) => {
    this.unit.setId(id);
    // debugger;
  };
  @action remove = () => {
    this.destroy = true;
  };
}

export class StandingOrder {
  @observable id?: number;
  @observable store: CustomerStore;
  @observable items: StandingOrderItem[] = [];

  constructor(store: CustomerStore, props?: any) {
    this.store = store;
    if (props) {
      this.id = props.id;
      this.items = props.standing_order_items.map(
        (item: StandingOrderItemProps) => new StandingOrderItem(item),
      );
    }
    makeObservable(this);
  }

  @computed get currentProducts() {
    return this.items
      .filter((item) => !item.destroy)
      .map((item) => item.buyable.product.id);
  }

  @computed get hasProducts() {
    return this.items.filter((item) => !item.destroy).length > 0;
  }

  @computed get days() {
    const sortOrder = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    let days = [
      { name: 'Mon', key: 'amountMon', enabled: true },
      { name: 'Tue', key: 'amountTue', enabled: true },
      { name: 'Wed', key: 'amountWed', enabled: true },
      { name: 'Thu', key: 'amountThu', enabled: true },
      { name: 'Fri', key: 'amountFri', enabled: true },
      { name: 'Sat', key: 'amountSat', enabled: true },
      { name: 'Sun', key: 'amountSun', enabled: true },
    ];
    const numberToDayMap: any = {
      '0': 'Sun',
      '1': 'Mon',
      '2': 'Tue',
      '3': 'Wed',
      '4': 'Thu',
      '5': 'Fri',
      '6': 'Sat',
    };
    const deliveryRulesStore = this.store.rootStore?.deliveryRules;
    if (
      deliveryRulesStore &&
      !isEmpty(deliveryRulesStore.deliveryRules) &&
      deliveryRulesStore.deliveryRulesEnabled
    ) {
      const rules = deliveryRulesStore.deliveryRules || {};
      days = Object.values(rules).map((value, index) => {
        const dayName = numberToDayMap[index];
        return {
          name: dayName,
          key: `amount${dayName}`,
          enabled: value.enabled,
        };
      });
    }
    days.sort((a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name));

    return days;
  }

  @action addProduct = (product: any) => {
    const item = {
      buyable: { product: product, type: 'product' },
      unit: product.unit,
    };
    this.items.push(new StandingOrderItem(item));
  };
}
