import { Box, Button, ButtonGroup, HStack, IconButton, Input, InputGroup, InputRightAddon, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { PencilIcon } from "../../../components/Icons/Icons";
import { CrossIcon } from "../../../components/Icons/IconsNew";
import NoteEditor from "../../../components/NoteEditor/NoteEditor";
import SelectUnit from "../../../components/SelectUnit/SelectUnit";
import ProductsListView from "../../../components/ProductModalContent/ProductsListView";

const ReviewOrderItemRow = observer(({ orderItem, isEditing, remove }: any) => {
  const {
    buyable: { product },
  } = orderItem;

  return (
    <HStack
      justify="space-between"
      boxShadow="inset 0px -1px 0px #E5E7EB"
      px={6}
      py={3}
    >
      <Box flex={1}>
        <ProductsListView product={product} />
        {orderItem.notes && (
          <Box my="8px" bg="gray.100" p="6px 10px">
            <Text fontSize="xs">"{orderItem.notes}"</Text>
          </Box>
        )}
        <NoteEditor
          initialValue={orderItem.notes || ''}
          onSave={(newValue: string) => {
            orderItem.setNotes(newValue);
          }}
          headerText={orderItem.notes ? 'Edit note' : 'Add note'}
          triggerChildren={
            <ButtonGroup>
              <Button
                variant="ghost"
                aria-label="Add / Edit note"
                leftIcon={<PencilIcon />}
                size="xs"
                color="green"
              >
                {orderItem.notes ? 'Edit' : 'Add'} note
              </Button>
            </ButtonGroup>
          }
        />
      </Box>
      <HStack>
        {isEditing ? (
          <>
            {product.units.length > 1 ? (
              <>
                <Input
                  type="number"
                  min={0}
                  defaultValue={orderItem.amount}
                  onChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    orderItem.setAmount(Number(target.value));
                  }}
                  maxW="20"
                  textAlign="right"
                  placeholder="0"
                />
                <SelectUnit
                  units={product.units}
                  unit={orderItem.unit}
                  onChange={(unit) => {
                    orderItem.setUnit(unit);
                  }}
                  w="72px"
                />
              </>
            ) : (
              <InputGroup>
                <Input
                  type="number"
                  min={0}
                  defaultValue={orderItem.amount}
                  onChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    orderItem.setAmount(Number(target.value));
                  }}
                  maxW="20"
                  textAlign="right"
                  placeholder="0"
                />
                <InputRightAddon children={orderItem.unit.name} />
              </InputGroup>
            )}
            <IconButton
              aria-label="Remove"
              variant="ghost"
              size="sm"
              icon={<CrossIcon />}
              onClick={() => remove(orderItem)}
            />
          </>
        ) : (
          <Text>
            {orderItem.amount} {orderItem.unit.name}
          </Text>
        )}
      </HStack>
    </HStack>
  );
});

export default ReviewOrderItemRow;