import { IconButton, ScaleFade, StyleProps } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { SnapshotOrInstance } from 'mobx-state-tree';
import { useState } from 'react';
import useAPI from '../../hooks/useAPI';
import Product from '../../models/Product';
import { StarIcon } from '../Icons/IconsNew';
import { motion } from 'framer-motion';

const FavouriteButton = ({
  product,
  show = true,
  ...chakraProps
}: {
  product: SnapshotOrInstance<typeof Product>;
  show?: boolean;
} & StyleProps) => {
  const [isFavourite, setIsFavourite] = useState(!!product.favourite);
  const [addFavourite] = useAPI({
    method: 'POST',
  });
  const [removeFavourite] = useAPI({
    method: 'DELETE',
  });

  const handleFavourite = () => {
    const url = `/v1/products/${product.id}/favourite`;
    if (isFavourite) {
      removeFavourite(url).then((data: any) => {
        setIsFavourite(false);
      });
    } else {
      addFavourite(url).then((data: any) => {
        setIsFavourite(true);
      });
    }
  };

  return (
    <ScaleFade initialScale={0.5} in={show}>
      <IconButton
        aria-label="Favourite"
        icon={
          <StarIcon w="5" fill={isFavourite ? 'green.500' : 'transparent'} />
        }
        variant="ghost"
        color={isFavourite ? 'green.500' : 'gray.400'}
        onClick={handleFavourite}
        {...chakraProps}
      />
    </ScaleFade>
  );
};

export default observer(FavouriteButton);
