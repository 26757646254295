import { useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isTablet } from 'react-device-detect';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import { useOrders } from '../../hooks/useStores';
import FakeProgressModal from '../../components/FakeProgressModal/FakeProgressModal';
import OrdersUpdateStatusModal from './OrdersUpdateStatusModal';
import { pluralizeString } from '../../utils';
import { useErrorToast } from '../../components/toast';
import { DownloadIcon } from '../../components/Icons/IconsNew';
import useAPI from '../../hooks/useAPI';

export type OrdersDownloadActionProps = {
  orderIds: number[];
  buttonText?: string | null;
  tooltipText: string;
  useIconButton: boolean;
  isBuyer?: boolean;
};

const OrdersDownloadAction = ({
  orderIds,
  buttonText,
  tooltipText,
  useIconButton,
  isBuyer = false,
}: OrdersDownloadActionProps): JSX.Element => {
  const mixpanel = useMixpanel();
  const flags = useFlags();
  const showNewDocuments = flags.orderFulfilmentMvpSupplierView != 'off';
  const showOrderSummaryReport = !!flags.orderSummaryReport;
  const showLegacyPickSheet = ['off', 'on-include-legacy'].includes(
    flags.orderFulfilmentMvpSupplierView,
  );
  const { ordersList, currentBulkIds, setCurrentBulkIds } = useOrders();
  const errorToast = useErrorToast();
  let [modalIsOpen, setModalIsOpen] = useState(false);
  let [modalText, setModalText] = useState('');
  let [updateStatusModalIsOpen, setUpdateStatusModalIsOpen] = useState(false);
  let [timePerItemMs, setTimePerItemMs] = useState<number>(2500);
  let [processingIsDone, setProcessingIsDone] = useState(false);
  let [newOrderIds, setNewOrderIds] = useState<number[]>([]);

  const [postRequest] = useAPI({
    method: 'POST',
  });

  const downloadBase64 = async (response: any) => {
    setProcessingIsDone(true);

    /** First, get the filename from the disposition header
     *
     * Content-Disposition: attachment; filename="PickSheet.pdf"
     */
    const contentDispositionHeader = response.headers.get(
      'Content-Disposition',
    );
    const mimeType = response.headers.get('Content-Type');
    const filename = contentDispositionHeader
      .split(';')
      .find(
        (part: string) =>
          part.trim().substring(0, part.trim().indexOf('=')) == 'filename',
      )
      .split('=')[1]
      .replaceAll('"', '');

    const data = await response.text();
    const linkEl = document.createElement('a');
    const linkSource = `data:${mimeType};base64,${data}`;
    linkEl.href = linkSource;
    linkEl.download = filename;
    document.body.append(linkEl);
    linkEl.click();
    linkEl.remove();

    setModalIsOpen(false);
    setProcessingIsDone(false);
    conditionallyShowUpdateStatusModal();
  };

  const conditionallyShowUpdateStatusModal = () => {
    if (isBuyer) {
      clearCheckboxes();
      return;
    }
    const newOrderIds = orderIds.filter((orderId) => {
      return ordersList[orderId].status == 'new';
    });

    if (newOrderIds.length) {
      setNewOrderIds(newOrderIds);
      setUpdateStatusModalIsOpen(true);
    } else {
      clearCheckboxes();
    }
  };

  const clearCheckboxes = () => {
    // Clear the checkboxes
    if (currentBulkIds == orderIds) {
      setCurrentBulkIds([]);
    }
  };

  const handleError = () => {
    setModalIsOpen(false);
    errorToast();
  };

  const requestPdf = (type: string) => {
    setModalIsOpen(true);
    postRequest(
      `/v4/order_documents`,
      {
        body: JSON.stringify({
          order_ids: orderIds.join(','),
          type: type,
        }),
      },
      true,
    )
      .then(downloadBase64)
      .catch(handleError);
  };

  const onClick = (
    documentKey: string,
    timePerItemMs: number,
    nameForModalText: string,
    nameForMixPanelEvent?: string,
  ) => {
    mixpanel.track(`Download ${nameForMixPanelEvent || nameForModalText}`, {
      numOrders: orderIds.length,
    });
    setTimePerItemMs(timePerItemMs);
    setModalText(`Preparing ${nameForModalText} for download.`);
    requestPdf(documentKey);
  };

  const onClickLegacyPickSheet = () => {
    /**
     * For data consistency we're leaving the Mixpanel event name as "Print Pick Sheet" and have
     * rewritten the display name in the MixPanel Lexicon to "Print Legacy Pick Sheet"
     * */
    onClick(
      'legacy_pick_sheet',
      100,
      pluralizeString(
        `${showLegacyPickSheet && showNewDocuments ? 'Legacy ' : ''}Pick Sheet`,
        orderIds.length,
      ),
      'Pick Sheet',
    );
  };

  const onClickPickSheet = () => {
    /** "Print New Pick Sheet" should be renamed in the Mixpanel Lexicon to "Print Pick Sheet" */
    onClick(
      'pick_sheet',
      400,
      pluralizeString('Pick Sheet', orderIds.length),
      'New Pick Sheet',
    );
  };

  const onClickPickAllSheet = () => {
    onClick('pick_all_sheet', 400, 'Pick-all Sheet');
  };

  const onClickBuyersReceipt = () => {
    onClick('buyers_receipt', 400, 'Buyers Receipt');
  };

  const onClickProductionSheet = () => {
    onClick('production_sheet', 400, 'Production Sheet');
  };

  const onClickPackingSlip = () => {
    onClick(
      'packing_slip',
      400,
      pluralizeString('Packing Slip', orderIds.length),
    );
  };

  const onClickOrderSummaryReport = () => {
    onClick('order_summary_report', 10, 'Order Summary report');
  };

  const ariaLabel = 'Open Download menu';
  const MenuButtonComponent = () => {
    if (useIconButton) {
      return (
        <MenuButton
          as={IconButton}
          aria-label={ariaLabel}
          icon={<DownloadIcon width="24px" />}
          variant="ghost"
        />
      );
    } else {
      return (
        <MenuButton
          as={Button}
          aria-label={ariaLabel}
          leftIcon={<DownloadIcon width="24px" />}
          variant="ghost"
        >
          {buttonText}
        </MenuButton>
      );
    }
  };

  if (isTablet) {
    return <></>;
  }

  return (
    <>
      {isBuyer ? (
        <Tooltip hasArrow label={tooltipText}>
          {useIconButton ? (
            <IconButton
              aria-label={ariaLabel}
              icon={<DownloadIcon width="24px" />}
              variant="ghost"
              onClick={onClickBuyersReceipt}
            />
          ) : (
            <Button
              aria-label={ariaLabel}
              leftIcon={<DownloadIcon width="24px" />}
              variant="ghost"
              onClick={onClickBuyersReceipt}
            >
              {buttonText}
            </Button>
          )}
        </Tooltip>
      ) : (
        <Menu>
          <Tooltip
            hasArrow
            label={tooltipText}
            isDisabled={modalIsOpen} // This fixes a bug where the tooltip stays open over top of the modal
          >
            {MenuButtonComponent()}
          </Tooltip>
          <MenuList>
            {showLegacyPickSheet && !showNewDocuments && (
              <MenuItem onClick={onClickLegacyPickSheet}>
                {pluralizeString('Pick sheet', orderIds.length)}
              </MenuItem>
            )}
            {showNewDocuments && (
              <>
                <MenuItem onClick={onClickPickSheet}>
                  {pluralizeString('Pick sheet', orderIds.length)}
                </MenuItem>
                <MenuItem onClick={onClickPickAllSheet}>
                  Pick-all sheet
                </MenuItem>
                <MenuItem onClick={onClickProductionSheet}>
                  Production sheet
                </MenuItem>
              </>
            )}
            <MenuItem onClick={onClickPackingSlip}>
              {pluralizeString('Packing slip', orderIds.length)}
            </MenuItem>
            {showLegacyPickSheet && showNewDocuments && (
              <MenuItem onClick={onClickLegacyPickSheet}>
                {pluralizeString('Legacy Pick sheet', orderIds.length)}
              </MenuItem>
            )}
            {showOrderSummaryReport && (
              <MenuItem onClick={onClickOrderSummaryReport}>
                Order Summary report
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}

      <FakeProgressModal
        mainText={modalText}
        isOpen={modalIsOpen}
        isDone={processingIsDone}
        totalItems={orderIds.length}
        timePerItemMs={timePerItemMs}
      />
      <OrdersUpdateStatusModal
        orderIds={newOrderIds}
        isOpen={updateStatusModalIsOpen}
        status={'processing'}
        onClose={() => {
          clearCheckboxes();
          setUpdateStatusModalIsOpen(false);
        }}
      />
    </>
  );
};

export default OrdersDownloadAction;
