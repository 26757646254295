import { useState } from 'react';

import {
  Box,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { HiX } from 'react-icons/hi';
import moment from 'moment';
import { isTablet } from 'react-device-detect';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { SparkleIcon } from '../Icons/Icons';

/**
 * hideBanner001 (26/10/2021 - 02/11/2021) was about the upcoming app release.
 * hideBanner002 (02/11/2021 - 09/05/2022) is after the aforementioned release.
 * hideBanner003 (09/05/2022 - 28/06/2022) is about the standing orders.
 * addOrdersBanner (28/06/2022 - 12/07/2022) is about the add orders feature.
 * editOrdersBanner (15/08/2022 - 16/10/2022) is about the edit orders feature.
 * tabletOptimisations (08/02/2023 - 28/02/2023) is about the edit orders feature.
 */

export const CurrentBanner = {
  key: 'tabletOptimisations',
  startDate: moment('08/02/2023', 'DD/MM/YYYY'),
  endDate: moment('28/02/2023', 'DD/MM/YYYY'),
  description: (
    <>
      <b>You can now use HospoConnect on your tablet!</b>
      &nbsp;&nbsp; Just log in from your iPad or Android tablet.{' '}
    </>
  ),
  link: '',
  launchDarklyKey: '',
};

const TopBanner = (): JSX.Element => {
  const today = moment();
  const [isHidden, setIsHidden] = useState(
    localStorage.getItem(CurrentBanner.key) === 'true' ||
      today.isBefore(CurrentBanner.startDate) ||
      (CurrentBanner.endDate ? today.isAfter(CurrentBanner.endDate) : false),
  );
  const flags = useFlags();

  // If the launch darkly key is set, and the return value is false, exit early
  if (CurrentBanner.launchDarklyKey && !flags[CurrentBanner.launchDarklyKey]) {
    return <></>;
  }

  // NOTE: Temporary check for the `tabletOptimisations` banner - don't show the banner on tablet devices!
  if (isTablet) {
    return <></>;
  }

  function onClose() {
    localStorage.setItem(CurrentBanner.key, 'true');
    setIsHidden(true);
  }

  return (
    <>
      {!isHidden && (
        <Box
          display={!isHidden ? 'block' : 'none'}
          bg="blue.500"
          color="white"
          py="3"
          px={{ base: '3', md: '6', lg: '8' }}
        >
          <HStack spacing="3">
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              justifyContent="center"
              alignItems="center"
              spacing={{ base: '3', md: '6' }}
              width="full"
            >
              <Icon as={SparkleIcon} fontSize="2xl" h="10" />
              <Text fontWeight="medium" marginEnd="2">
                {CurrentBanner.description}
                {CurrentBanner.link && (
                  <Link color="white" href={CurrentBanner.link} isExternal>
                    Read more
                  </Link>
                )}
              </Text>
            </Stack>
            <IconButton
              fontSize="1.5em"
              variant="ghost"
              colorScheme="blue.500"
              icon={<HiX />}
              alignSelf={{ base: 'self-start', sm: 'initial' }}
              aria-label="Close banner"
              onClick={onClose}
            />
          </HStack>
        </Box>
      )}
    </>
  );
};

export default TopBanner;
