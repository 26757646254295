import { destroy, SnapshotOrInstance, types } from 'mobx-state-tree';
import { api } from '../api';
import Address, { AddressFieldComponents } from './Address';
import * as Sentry from '@sentry/browser';

export type UserCompanyEditFormValues = {
  name: string;
  logo_url: string | null;
  about: string | null;
  email: string | null;
  phone: string | null;
  website: string | null;
  addresses?: SnapshotOrInstance<typeof Address>[];
};

export const UserCompany = types
  .model('User', {
    id: types.number,
    name: types.string,
    logo_url: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    website: types.maybeNull(types.string),
    about: types.maybeNull(types.string),
    account_type: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    addresses: types.array(Address),
  })
  .views((self) => ({
    get default_address() {
      return self.addresses.find((address) => address.is_default)?.full_address;
    },
    get default_address_obj() {
      return self.addresses.find((address) => address.is_default);
    },
    get default_instructions() {
      return self.addresses.find((address) => address.is_default)?.instructions;
    },
  }))
  .views((self) => ({
    get editFormValues(): UserCompanyEditFormValues {
      return {
        name: self.name,
        logo_url: self.logo_url,
        about: self.about,
        email: self.email,
        phone: self.phone,
        website: self.website,
        addresses: self.addresses,
      };
    },
    get isBuyer() {
      return self.account_type === 'restaurant';
    }
  }))
  .actions((self) => ({
    setValues(newValues: UserCompanyEditFormValues) {
      self.name = newValues.name;
      self.about = newValues.about;
      self.email = newValues.email;
      self.phone = newValues.phone;
      self.website = newValues.website;
    },
    addAddress(address: SnapshotOrInstance<typeof Address>) {
      self.addresses.push(Address.create(address));
    },
    removeAddress(id: number) {
      const address = self.addresses.find((address) => address.id === id);
      destroy(address);
    },
  }))
  .actions((self) => ({
    updateCompany(
      newValues: UserCompanyEditFormValues,
      successCallback: () => void,
      errorCallback: () => void,
    ) {
      return api
        .put(
          `/v3/companies/${self.id}`,
          JSON.stringify({
            ...newValues,
          }),
        )
        .then(async (response) => {
          if (response.ok) {
            const data = await response.json();
            self.setValues(newValues);
            successCallback();
            return data;
          } else {
            errorCallback();
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          errorCallback();
          return error;
        });
    },
    createAddress(
      address: SnapshotOrInstance<typeof Address> | AddressFieldComponents,
    ) {
      const { id, ...addressdata } = address;
      return api
        .post(
          `/v4/addresses`,
          JSON.stringify({
            ...addressdata,
            company_id: self.id,
          }),
        )
        .then(async (response) => {
          if (response.ok) {
            const data = await response.json();
            self.addAddress(data);
            return data;
          }
          throw new Error('Error creating address: ' + response.statusText);
        })
        .catch((error) => {
          Sentry.captureException(error);
          throw error;
        });
    },
  }))
  .actions((self) => ({
    async updateAddresses(
      addresses: (
        | SnapshotOrInstance<typeof Address>
        | AddressFieldComponents
      )[],
    ) {
      const apiPromises: Promise<any>[] = self.addresses.map((adr, index) => {
        const newAddress = addresses.find(
          (newAddress) => newAddress.id === adr.id,
        );
        if (newAddress) {
          return adr.updateAddress(newAddress);
        } else {
          return adr.deleteAddress().then((res) => {
            if (res && adr.id) {
              self.removeAddress(adr.id);
            }
            return res;
          });
        }
      });
      const newAddresses = addresses.filter((adr) => !adr.id);
      newAddresses.forEach((adr) => {
        apiPromises.push(self.createAddress(adr));
      });
      return Promise.all(apiPromises);
    },
  }));

export default UserCompany;
