const Select = {
  baseStyle: {
    field: {
      bg: 'white',
      backgroundColor: 'white',
    },
  },
  variants: {
    unitSelector: {
      field: {
        bg: 'gray.100',
        backgroundColor: 'gray.100',
        borderWidth: '1px',
        borderColor: 'gray.300',
        minWidth: '62px',
      },
    },
  },
};

export default Select;
