import { useState } from 'react';
import { observer } from 'mobx-react';
import { isAndroid, isTablet } from 'react-device-detect';
import { useMixpanel } from 'react-mixpanel-browser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
  MenuButtonProps,
  forwardRef,
} from '@chakra-ui/react';
import OrdersUpdateStatusModal from './OrdersUpdateStatusModal';
import FakeProgressModal from '../../components/FakeProgressModal/FakeProgressModal';
import { useOrders } from '../../hooks/useStores';
import { pluralizeString } from '../../utils';
import { useErrorToast } from '../../components/toast';
import { PrintIcon } from '../../components/Icons/Icons';
import useAPI from '../../hooks/useAPI';
import { base64ToUrl, printInNewWindow } from '../../print';
import OrdersOpenDocumentModal from './OrdersOpenDocumentModal';

export type OrdersPrintActionProps = {
  orderIds: number[];
  buttonText?: string | null;
  tooltipText: string;
  useIconButton: boolean;
  isBuyer?: boolean;
};

const OrdersPrintAction = ({
  orderIds,
  buttonText,
  tooltipText,
  useIconButton,
  isBuyer = false,
}: OrdersPrintActionProps): JSX.Element => {
  const mixpanel = useMixpanel();
  const flags = useFlags();
  const showNewDocuments = flags['orderFulfilmentMvpSupplierView'] != 'off';
  const showLegacyPickSheet = ['off', 'on-include-legacy'].includes(
    flags['orderFulfilmentMvpSupplierView'],
  );
  const errorToast = useErrorToast();
  const { ordersList, currentBulkIds, setCurrentBulkIds } = useOrders();
  let [modalIsOpen, setModalIsOpen] = useState(false);
  let [modalText, setModalText] = useState('');

  const [popupsBlocked, setPopupsBlocked] = useState(false);

  let [updateStatusModalIsOpen, setUpdateStatusModalIsOpen] = useState(false);
  let [timePerItemMs, setTimePerItemMs] = useState<number>(2500);
  let [processingIsDone, setProcessingIsDone] = useState(false);
  let [newOrderIds, setNewOrderIds] = useState<number[]>([]);

  const {
    isOpen: printDialogIsOpen,
    onOpen: printDialogOnOpen,
    onClose: printDialogOnClose,
  } = useDisclosure();
  const [printUrl, setPrintUrl] = useState('');

  const [postRequest] = useAPI({
    method: 'POST',
  });

  const print = (url: any) => {
    printInNewWindow({
      url,
      onPopupBlocked: () => {
        setPopupsBlocked(true);
      },
    });
  };

  const printBase64 = async (response: any) => {
    setProcessingIsDone(true);
    if (typeof window === 'undefined') {
      return '';
    }

    const data = await response.text();
    const pdfUrl = base64ToUrl(data);
    setPrintUrl(pdfUrl);
    setModalIsOpen(false);
    setProcessingIsDone(false);
    printDialogOnOpen();
    print(pdfUrl);
  };

  const conditionallyShowUpdateStatusModal = () => {
    if (isBuyer) {
      clearCheckboxes()
      return;
    }
    const newOrderIds = orderIds.filter((orderId) => {
      return ordersList[orderId].status == 'new';
    });

    if (newOrderIds.length) {
      setNewOrderIds(newOrderIds);
      setUpdateStatusModalIsOpen(true);
    } else {
      clearCheckboxes();
    }
  };

  const clearCheckboxes = () => {
    // Clear the checkboxes
    if (currentBulkIds == orderIds) {
      setCurrentBulkIds([]);
    }
  };

  const handleError = () => {
    setModalIsOpen(false);
    setProcessingIsDone(false);
    errorToast();
  };

  const requestPdf = (type: string) => {
    setModalIsOpen(true);
    postRequest(
      `/v4/order_documents`,
      {
        body: JSON.stringify({
          order_ids: orderIds.join(','),
          type: type,
        }),
      },
      true,
    )
      .then(printBase64)
      .catch(handleError);
  };

  const onClick = (
    documentKey: string,
    timePerItemMs: number,
    nameForModalText: string,
    nameForMixPanelEvent?: string,
  ) => {
    mixpanel.track(`Print ${nameForMixPanelEvent || nameForModalText}`, {
      numOrders: orderIds.length,
    });
    setTimePerItemMs(timePerItemMs);
    setModalText(`Preparing ${nameForModalText} for printing.`);
    requestPdf(documentKey);
  };

  const onClickLegacyPickSheet = () => {
    /**
     * For data consistency we're leaving the Mixpanel event name as "Print Pick Sheet" and have
     * rewritten the display name in the MixPanel Lexicon to "Print Legacy Pick Sheet"
     * */
    onClick(
      'legacy_pick_sheet',
      100,
      pluralizeString(
        `${showLegacyPickSheet && showNewDocuments ? 'Legacy ' : ''}Pick Sheet`,
        orderIds.length,
      ),
      'Pick Sheet',
    );
  };

  const onClickPickSheet = () => {
    /** "Print New Pick Sheet" should be renamed in the Mixpanel Lexicon to "Print Pick Sheet" */
    onClick(
      'pick_sheet',
      400,
      pluralizeString('Pick Sheet', orderIds.length),
      'New Pick Sheet',
    );
  };

  const onClickPickAllSheet = () => {
    onClick('pick_all_sheet', 400, 'Pick-all Sheet');
  };

  const onClickBuyersReceipt = () => {
    onClick('buyers_receipt', 400, 'Buyers Receipt');
  };

  const onClickProductionSheet = () => {
    onClick('production_sheet', 400, 'Production Sheet');
  };

  const onClickPackingSlip = () => {
    onClick(
      'packing_slip',
      400,
      pluralizeString('Packing Slip', orderIds.length),
    );
  };

  const ariaLabel = 'Open Print menu';
  const MenuButtonComponent = forwardRef<MenuButtonProps, 'div'>(
    (props: MenuButtonProps, ref) => {
      if (useIconButton) {
        return (
          <MenuButton
            as={IconButton}
            aria-label={ariaLabel}
            icon={<PrintIcon />}
            variant="ghost"
            ref={ref}
          />
        );
      } else {
        return (
          <MenuButton
            as={Button}
            aria-label={ariaLabel}
            leftIcon={<PrintIcon />}
            variant="ghost"
            ref={ref}
          >
            {buttonText}
          </MenuButton>
        );
      }
    },
  );

  const showAndroidWarning = isAndroid;

  return (
    <>
      {isBuyer ? (
        <Tooltip hasArrow label={tooltipText}>
          {useIconButton ? (
            <IconButton
              aria-label={ariaLabel}
              icon={<PrintIcon />}
              variant="ghost"
              onClick={onClickBuyersReceipt}
            />
          ) : (
            <Button
              aria-label={ariaLabel}
              leftIcon={<PrintIcon />}
              variant="ghost"
              onClick={onClickBuyersReceipt}
            >
              {buttonText}
            </Button>
          )}
        </Tooltip>
      ) : (
        <Menu>
          <Tooltip
            hasArrow
            label={tooltipText}
            isDisabled={modalIsOpen} // This fixes a bug where the tooltip stays open over top of the modal
          >
            <MenuButtonComponent />
          </Tooltip>
          <MenuList>
            {showLegacyPickSheet && !showNewDocuments && (
              <MenuItem onClick={onClickLegacyPickSheet}>
                {pluralizeString('Pick sheet', orderIds.length)}
              </MenuItem>
            )}
            {showNewDocuments && (
              <>
                <MenuItem onClick={onClickPickSheet}>
                  {pluralizeString('Pick sheet', orderIds.length)}
                </MenuItem>
                <MenuItem onClick={onClickPickAllSheet}>
                  Pick-all sheet
                </MenuItem>
                <MenuItem onClick={onClickProductionSheet}>
                  Production sheet
                </MenuItem>
              </>
            )}
            <MenuItem onClick={onClickPackingSlip}>
              {pluralizeString('Packing slip', orderIds.length)}
            </MenuItem>
            {showLegacyPickSheet && showNewDocuments && (
              <MenuItem onClick={onClickLegacyPickSheet}>
                {pluralizeString('Legacy Pick sheet', orderIds.length)}
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}

      <FakeProgressModal
        mainText={modalText}
        isOpen={modalIsOpen}
        isDone={processingIsDone}
        totalItems={orderIds.length}
        timePerItemMs={timePerItemMs}
        bottomChildren={
          showAndroidWarning ? (
            <Alert status="warning" size="sm">
              <AlertIcon />
              <Box>
                <AlertTitle>Issues while using an Android device?</AlertTitle>
                <AlertDescription>
                  If printing isn't working, try another browser or use your
                  laptop / desktop computer.
                </AlertDescription>
              </Box>
            </Alert>
          ) : (
            ''
          )
        }
      />
      <OrdersUpdateStatusModal
        orderIds={newOrderIds}
        status={'processing'}
        isOpen={updateStatusModalIsOpen}
        onClose={() => {
          clearCheckboxes();
          setUpdateStatusModalIsOpen(false);
        }}
      />
      <OrdersOpenDocumentModal
        isOpen={printDialogIsOpen}
        arePopupsBlocked={popupsBlocked}
        onClose={() => {
          printDialogOnClose();
          conditionallyShowUpdateStatusModal();
        }}
        onClick={() => print(printUrl)}
      />
    </>
  );
};

export default observer(OrdersPrintAction);
