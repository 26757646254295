import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Flex, Tooltip } from '@chakra-ui/react';

import StatusDot from '../../../components/Orders/StatusDot';
import DateText from '../../../components/Orders/DateText';
import Tag from '../../../components/Tag/Tag';
import ListRow from '../../../components/ListRow/ListRow';
import { Caption } from '../../../components/Typography/Typography';
import { OrderRowProps } from './OrdersRow';

const OutgoingOrderRow = ({ order }: OrderRowProps): JSX.Element => {
  let history = useHistory();

  return (
    <ListRow
      key={`table-row-${order.id}`}
      isCurrent={order.isCurrentOrder}
      hideCheckbox={true}
      onClick={() => {
        history.push(`/orders/${order.id}`);
      }}
    >
      <Flex
        flex="1"
        justifyContent="space-between"
        direction={{ base: 'column', xl: 'row' }}
      >
        <Flex direction="column">
          <Caption fontWeight="600" color="gray.900">
            {order.supplier?.name}
          </Caption>
          <Caption color="gray.600">
            {order.source === 'standing_order' ? (
              <Tag>Standing</Tag>
            ) : order.source === 'supplier_created' ? (
              <Tag
                tooltip={`Added on behalf of customer by ${order.customer.fullname}`}
              >
                Added
              </Tag>
            ) : (
              order.customer.fullname
            )}
          </Caption>
        </Flex>
        <Flex
          display={{ base: 'none', xl: 'flex ' }}
          direction="column"
          align="flex-end"
        >
          <Tooltip
            hasArrow
            label={
              order.read_by_supplier_at ? (
                <>
                  Read on: <DateText date={order.read_by_supplier_at} />
                </>
              ) : (
                'New'
              )
            }
          >
            <Caption color="gray.500">
              <StatusDot status={order.readStatus} /> #{order.order_number}
            </Caption>
          </Tooltip>
          <DateText
            as={Caption}
            date={order.date || ''}
            color="gray.500"
            textAlign="right"
          />
        </Flex>
        <Flex display={{ base: 'flex', xl: 'none ' }} direction="column">
          <Tooltip
            hasArrow
            label={
              order.read_by_supplier_at ? (
                <DateText date={order.read_by_supplier_at} />
              ) : (
                'New'
              )
            }
          >
            <Caption color="gray.500">
              <StatusDot status={order.readStatus} />{' '}
              <DateText
                as={Caption}
                color="gray.500"
                date={order.date || ''}
                showTime={false}
              >
                , #{order.order_number}
              </DateText>
            </Caption>
          </Tooltip>
        </Flex>
      </Flex>
    </ListRow>
  );
};

export default observer(OutgoingOrderRow);
