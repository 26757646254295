import { types } from 'mobx-state-tree';
import DeliveryRules from './DeliveryRules';

const Supplier = types
  .model('Supplier', {
    id: types.number,
    name: types.string,
    logo_url: types.maybeNull(types.string),
    delivery_rules: types.maybeNull(DeliveryRules),
    delivery_rules_enabled: types.optional(types.boolean, false),
    delivery_rules_message: types.maybeNull(types.string),
  })
  .views((self) => ({
    get logo() {
      if (self.logo_url) return self.logo_url;
      return undefined;
    },
  }));

export default Supplier;
