import { VStack, Text, Textarea, Input } from '@chakra-ui/react';
import EditableFormWrapper from '../../../components/Form/EditableFormWrapper';
import { Subhead } from '../../../components/Typography/Typography';
import { Customer } from '../../../stores/customers/customer';

const CustomerCompanyFields = ({
  customer,
  isEditable = false,
}: {
  customer: Customer;
  isEditable?: boolean;
}) => {
  const canEditCompanyField = customer.can_edit_company_fields;
  const canEditAddressField = customer.is_address_supplier_provided;

  return (
    <VStack align="left" spacing="8" maxWidth="525px">
      <Subhead fontWeight="600">Contact</Subhead>
      <EditableFormWrapper
        value={customer.phone}
        editableValue={customer.formPhone}
        onChange={(e) => customer.setFormPhone(e.target.value)}
        EditComponent={Input}
        href={`tel:${customer.phone}`}
        fieldName="phone"
        fieldLabel="Phone"
        isEditable={isEditable && canEditCompanyField}
      />
      <EditableFormWrapper
        value={customer.email}
        editableValue={customer.formEmail}
        onChange={(e) => customer.setFormEmail(e.target.value)}
        EditComponent={Input}
        href={`mailto:${customer.email}`}
        fieldName="email"
        fieldLabel="Email"
        isEditable={isEditable && canEditCompanyField}
      />
      <EditableFormWrapper
        value={customer.default_address}
        editableValue={customer.formAddress}
        onChange={(e) => customer.setFormAddress(e.target.value)}
        EditComponent={Textarea}
        fieldName="delivery_address"
        fieldLabel="Delivery address"
        isEditable={isEditable && canEditAddressField}
      >
        {isEditable && canEditAddressField && !canEditCompanyField && (
          <Text mt="10px" color="gray.600" fontSize="xs">
            Buyers can override this by adding their own address in the
            HospoConnect app.
          </Text>
        )}
      </EditableFormWrapper>
      <EditableFormWrapper
        value={customer.default_instructions}
        fieldName="delivery_instructions"
        fieldLabel="Delivery Instructions"
        isEditable={false}
      />
      <EditableFormWrapper
        value={customer.website}
        editableValue={customer.formWebsite}
        onChange={(e) => customer.setFormWebsite(e.target.value)}
        EditComponent={Input}
        href={customer.website}
        fieldName="website"
        fieldLabel="Website"
        isEditable={isEditable && canEditCompanyField}
      />
    </VStack>
  );
};

export default CustomerCompanyFields;
