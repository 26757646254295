import { makeObservable, action, observable } from 'mobx';
import { CustomerGroupsStore } from './customerGroupsStore';
import { CustomerStore } from './customers/customerStore';
import { DeliveryRulesStore } from './deliveryRulesStore/deliveryRulesStore';
import { OrderStore } from './orders/orderStore';
import { SourceCategoriesStore } from './sourceCategories/sourceCategoriesStore';
import { ProductStore } from './products/productStore';
import { UserStore } from './user/userStore';

export class RootStore {
  @observable user: any = [];
  @observable orders: OrderStore;
  @observable products: ProductStore;
  @observable currentUserStore: UserStore;
  @observable customers: CustomerStore;
  @observable deliveryRules: DeliveryRulesStore;
  @observable customerGroups: CustomerGroupsStore;
  @observable sourceCategories: SourceCategoriesStore;

  constructor(user?: any) {
    this.user = user;
    this.currentUserStore = new UserStore();
    this.orders = new OrderStore(this.user);
    this.products = new ProductStore(this.user);
    this.customers = new CustomerStore(this, this.user);
    this.deliveryRules = new DeliveryRulesStore(this.user);
    this.customerGroups = new CustomerGroupsStore(this.user);
    this.sourceCategories = new SourceCategoriesStore(this.user);
    makeObservable(this);
  }
}
