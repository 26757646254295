import React, { Fragment, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import * as Constants from '../../constants';
import { useSuccessToast, useErrorToast } from '../../components/toast';
import * as Sentry from '@sentry/browser';
import { xClientHeaders } from '../../utils';

import {
  Box,
  ButtonGroup,
  HStack,
  VStack,
  Input,
  useDisclosure,
  Text,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { Button } from '../../components/Button/Button';

interface referralTypeKeys {
  [key: string]: string | number;
}

interface referralType extends referralTypeKeys {
  company_name: string;
  persons_name: string;
  email: string;
}

const CustomerReferral = () => {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState(false);
  const [referrals, setReferrals] = useState<referralType[]>([
    getEmptyReferral(),
  ]);
  const [referralErrors, setReferralErrors] = useState<referralType[]>([]);
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  function getEmptyReferral() {
    return {
      company_name: '',
      persons_name: '',
      email: '',
      phone: '',
    };
  }

  function handleChange(i: number, e: React.ChangeEvent) {
    const target = e.target as HTMLInputElement;
    let newReferrals = [...referrals];
    newReferrals[i][target.name] = target.value;
    setReferrals(newReferrals);
    clearReferralErrors();
  }

  function addReferral() {
    setReferrals([...referrals, getEmptyReferral()]);
  }

  function clearReferrals() {
    setReferrals([getEmptyReferral()]);
  }

  function clearReferralErrors() {
    setReferralErrors([]);
  }

  function removeReferral(i: number) {
    let newReferrals = [...referrals];
    newReferrals.splice(i, 1);
    setReferrals(newReferrals);
  }

  function validateForm() {
    let hasErrors = false;
    var newReferralErrors: referralType[] = [];

    referrals.map((referral: referralType) => {
      let referralErrorObject = getEmptyReferral();
      if (!referral.company_name) {
        referralErrorObject.company_name = 'Required';
        hasErrors = true;
      }
      newReferralErrors.push(referralErrorObject);
    });
    setReferralErrors(newReferralErrors);

    return !hasErrors;
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (validateForm()) {
      setSubmitButtonIsLoading(true);
      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
        body: JSON.stringify({
          basic_referrals: referrals,
        }),
      };
      const url = `${Constants.API_URL}/v4/basic_referrals`;

      fetch(url, requestOptions)
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            onSubmitFail();
          } else {
            if (response.ok) {
              onSubmitSuccess();
              return { data };
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          onSubmitFail();
          return error;
        });
    } else {
      errorToast({
        title: 'Uh oh.',
        description: "There's an error on the page. Please try again.",
      });
    }
  }

  function onSubmitSuccess() {
    setSubmitButtonIsLoading(false);
    successToast({
      title: 'Got it!',
      description: "We'll send them an invite to join HospoConnect.",
    });
    clearReferrals();
    clearReferralErrors();
    closeModal();
  }

  function onSubmitFail() {
    setSubmitButtonIsLoading(false);
    errorToast();
  }

  function closeModal() {
    onClose();
    clearReferrals();
    clearReferralErrors();
  }

  return (
    <>
      <ButtonGroup>
        <Button onClick={onOpen} variant="primary">
          Invite others
        </Button>
      </ButtonGroup>

      <Modal size="4xl" isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={onSubmit}>
            <ModalCloseButton />
            <ModalHeader>Invite others</ModalHeader>
            <ModalBody>
              <Text>
                We'll send your contacts an email invite with you and your
                company’s name, inviting them to sign up to HospoConnect for
                free.
              </Text>
              <VStack mt="6" alignItems="left" spacing="5">
                <HStack>
                  <Box w="100px"></Box>
                  <Box w="250px">
                    <HStack>
                      <Text fontWeight="500">Trading name</Text>
                      <Text fontSize="sm">(Required)</Text>
                    </HStack>
                  </Box>
                  <Box w="250px">
                    <Text fontWeight="500">Person's name</Text>
                  </Box>
                  <Box w="250px">
                    <Text fontWeight="500">Email address</Text>
                  </Box>
                  <Box w="250px">
                    <Text fontWeight="500">Mobile number</Text>
                  </Box>
                </HStack>
                {referrals.map((referral: referralType, index: number) => {
                  return (
                    <Fragment key={index}>
                      <HStack>
                        <Box w="100px">
                          <Text fontWeight="500">Invite {index + 1}</Text>
                          <Link
                            fontWeight="400"
                            fontSize="sm"
                            variant="reverse"
                            onClick={() => removeReferral(index)}
                          >
                            Remove
                          </Link>
                        </Box>
                        <Box w="250px">
                          <Input
                            name="company_name"
                            value={referral.company_name}
                            onChange={(e) => handleChange(index, e)}
                            isInvalid={
                              referralErrors[index] &&
                              referralErrors[index].company_name
                                ? true
                                : false
                            }
                          />
                        </Box>
                        <Box w="250px">
                          <Input
                            name="persons_name"
                            value={referral.persons_name}
                            onChange={(e) => handleChange(index, e)}
                          />
                          <Box></Box>
                        </Box>
                        <Box w="250px">
                          <Input
                            name="email"
                            value={referral.email}
                            onChange={(e) => handleChange(index, e)}
                          />
                          <Box></Box>
                        </Box>
                        <Box w="250px">
                          <Input
                            name="phone"
                            value={referral.phone}
                            onChange={(e) => handleChange(index, e)}
                          />
                          <Box></Box>
                        </Box>
                      </HStack>

                      {referralErrors[index] && (
                        <HStack mt="0px !important">
                          <Box w="100px"></Box>
                          <Box w="250px">
                            {referralErrors[index].company_name && (
                              <Text fontSize="sm" color="red.600">
                                Required
                              </Text>
                            )}
                          </Box>
                          <Box w="250px"></Box>
                          <Box w="250px"></Box>
                          <Box w="250px"></Box>
                        </HStack>
                      )}
                    </Fragment>
                  );
                })}

                <Box>
                  <Button onClick={addReferral} leftIcon={<AddIcon />}>
                    Add another person
                  </Button>
                </Box>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button onClick={closeModal}>Cancel</Button>
                <Button
                  variant="primary"
                  isLoading={submitButtonIsLoading}
                  isDisabled={referrals.length === 0}
                  type="submit"
                >
                  Send
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomerReferral;
