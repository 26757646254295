import { Text } from '@chakra-ui/react';
import { SnapshotOrInstance } from 'mobx-state-tree';
import moment, { Moment } from 'moment';
import { Fragment } from 'react';
import { ArrowRightIcon } from './components/Icons/IconsNew';
import DeliveryRules from './models/DeliveryRules';

export function getColourSchemeByStatus(status: string) {
  const mapping: any = {
    new: 'red',
    processing: 'yellow',
    invoiced: 'blue',
    complete: 'green',
    read: 'gray',
    unread: 'red'
  };
  return mapping[status];
}

export function capitalizeString(textToCapitalize: string) {
  const words = textToCapitalize.split(' ');
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(' ');
}

export function pluralizeString(
  textToPluralize: string,
  numItems: number,
  textAsPlural?: string,
) {
  if (numItems === 1) {
    return textToPluralize;
  }

  if (textAsPlural) return textAsPlural;
  else return `${textToPluralize}s`;
}

export function formatFloat(number: number) {
  return number.toString().replace(/\.00$/, '');
}

export function getBracketNumber(value: string): number | null {
  const matches = value.match(/\[(.*?)\]/);
  return matches ? parseInt(matches[1]) : null;
}

export function parseServersideErrors(errors: { [key: string]: [string] }): {
  [key: string]: string;
} {
  const parsedErrors: { [key: string]: string } = {};
  Object.keys(errors).forEach((key: any) => {
    if (key.match(/\[(\d+?)\]/)) {
      const newKey = key.replace(/\[(\d+?)\]/, '_attributes.$1');
      parsedErrors[newKey] = errors[key][0];
    } else {
      parsedErrors[key] = errors[key][0];
    }
  });
  return parsedErrors;
}

export type SubtextGroupItem = {
  itemLabels: string[];
  label: string;
};

export type SubtextGroupItems = {
  [id: string]: SubtextGroupItem;
};

export function ellipseString(
  str: string,
  maxLength: number,
  ellipsePos: 'end' | 'middle' | 'start' = 'end',
  ellipsis: string = '...',
) {
  if (str.length > maxLength) {
    switch (ellipsePos) {
      case 'start':
        return ellipsis + str.substring(str.length - maxLength, str.length);
      case 'middle':
        const mid = Math.floor(maxLength / 2);
        return (
          str.substring(0, mid) +
          ellipsis +
          str.substring(str.length - mid, str.length)
        );
      case 'end':
        return str.substring(0, maxLength) + ellipsis;
    }
  }
  return str;
}

// Parses an individual SubtextGroupItem into a humanised truncated string
// e.g. { label: 'Items', itemLabels: ['Item 1', 'Item 2'] } => 'Items: Item 1, Item 2'
// e.g. { label: 'Items', itemLabels: ['Item 1', 'Item 2', 'Item 3'] } => 'Items: 3 items'
export function parseSubtextGroupItem({
  subtextItem,
  maxSubItems = 3,
  itemlabel = 'item',
  pluralizedItemLabel,
  maxLabelLength = 30,
}: {
  subtextItem: SubtextGroupItem;
  maxSubItems?: number;
  itemlabel?: string;
  pluralizedItemLabel?: string;
  maxLabelLength?: number;
  extraCountStart?: number;
}) {
  const subItemsString =
    maxSubItems > 0 && subtextItem.itemLabels.length > maxSubItems
      ? `${subtextItem.itemLabels.length} ${pluralizeString(
          itemlabel,
          subtextItem.itemLabels.length,
          pluralizedItemLabel,
        )}`
      : subtextItem.itemLabels
          .map((label) => ellipseString(label, maxLabelLength, 'middle'))
          .join(', ');

  return (
    <>
      <Text as="b">
        {ellipseString(subtextItem.label, maxLabelLength, 'middle')}
        <ArrowRightIcon
          display="inline-block"
          h="1.2em"
          lineHeight="1.2em"
          verticalAlign="-0.25em"
          mx={0.5}
        />
      </Text>
      {subItemsString}.
    </>
  );
}

export function isDeliveryDateValid(deliveryDate: Date | Moment, deliveryRules: any) {
  const data = deliveryRules;
  let isAvailable = true;
  const date = deliveryDate;
  const currentDateTime = moment()
  const weekIndex = moment(date).weekday();
  const dateValue = data[weekIndex];
  if (!dateValue.enabled) {
    isAvailable = false;
  } else {
    const cutoff_day = dateValue.cutoff_day;
    let availableDay = '';
    if (weekIndex < cutoff_day) {
      // we need to support same day delivery
      availableDay = moment(date)
        .add(-(7 + weekIndex - cutoff_day), 'days')
        .format('YYYY-MM-DD')
        .toString();
    } else {
      availableDay = moment(date)
        .add(-(weekIndex - cutoff_day), 'days')
        .format('YYYY-MM-DD')
        .toString();
    }
    const availableDate = availableDay + ' ' + dateValue.cutoff_time;
    const availableDayTime = moment(availableDate, 'YYYY-MM-DD hh:mm A');
    if (currentDateTime.diff(availableDayTime) > 0) {
      isAvailable = false;
    } else {
      isAvailable = true;
    }
  }
  return isAvailable;
}

// Parses an array of SubtextGroupItem into a truncated string
// e.g. for customer and customer groups selections
export function parseSubtextGroupItems({
  subtextItems,
  maxItems = 4,
  maxSubItems = 3,
  itemlabel = 'item',
  pluralizedItemLabel,
  maxLabelLength = 30,
  extraCountStart = 0,
}: {
  subtextItems: SubtextGroupItem[];
  maxItems?: number;
  maxSubItems?: number;
  itemlabel?: string;
  pluralizedItemLabel?: string;
  maxLabelLength?: number;
  extraCountStart?: number;
}) {
  if (!subtextItems.length && extraCountStart === 0) {
    return null;
  }
  const parsedItems = subtextItems.slice(0, maxItems).map((item) => {
    return parseSubtextGroupItem({
      subtextItem: item,
      maxSubItems,
      itemlabel,
      pluralizedItemLabel,
      maxLabelLength,
    });
  });

  const extraItemCount =
    subtextItems.length > maxItems
      ? subtextItems.slice(maxItems).reduce((prevCount, item) => {
          return prevCount + item.itemLabels.length;
        }, extraCountStart)
      : extraCountStart;

  const extraItemText =
    extraItemCount > 0
      ? ` and ${extraItemCount} ${pluralizeString('other', extraItemCount)}...`
      : '';

  return (
    <>
      {parsedItems.map((item, index) => (
        <Fragment key={`subtext-item-${itemlabel}-${index}`}>
          {item}
          {index < parsedItems.length - 1 ? ' ' : ''}
        </Fragment>
      ))}
      {extraItemText}
    </>
  );
}

export const xClientHeaders = {
  'X-Client-Name': 'web',
  'X-Client-Version':
    process.env.REACT_APP_CURRENT_VERSION || 'missing_version',
};
