import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Flex } from '@chakra-ui/react';

import ProductActiveToggle from '../ProductActiveToggle';
import ListRow from '../../../components/ListRow/ListRow';
import { Caption } from '../../../components/Typography/Typography';

const ProductsRow = ({ product }: any): JSX.Element => {
  let history = useHistory();

  return (
    <ListRow
      key={`table-row-${product.id}`}
      isCurrent={product.isCurrentProduct}
      isChecked={product.isChecked}
      onClick={() => {
        history.push(`/products/${product.id}`);
      }}
      hideCheckbox={false}
      onCheckboxChange={product.toggleChecked}
    >
      <Flex flex="1" align="center" justifyContent="space-between">
        <Flex direction="column">
          <Caption fontWeight="600" color="gray.900">
            {product.name}
          </Caption>
          <Caption color="gray.900">{product.product_code}</Caption>
          <Caption display={{ base: 'block', xl: 'none' }} color="gray.600">
            {product.price &&
              `$${product.price}/${
                product.pricing_unit ? product.pricing_unit.name : ''
              }`}
          </Caption>
        </Flex>
        <Flex
          display={{ base: 'none', xl: 'flex' }}
          direction="column"
          align="center"
        >
          <Caption color="gray.600">
            {product.price &&
              `$${product.price}/${
                product.pricing_unit ? product.pricing_unit.name : ''
              }`}
          </Caption>
        </Flex>
      </Flex>
      <Flex direction="column" align="center">
        <ProductActiveToggle product={product} />
      </Flex>
    </ListRow>
  );
};

export default observer(ProductsRow);
