import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import {
  Button as ChakraButton,
  ButtonGroup,
  HStack,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import 'react-datepicker/dist/react-datepicker.css';

import { useCustomerGroups, useOrders } from '../../hooks/useStores';
import DateFilter from '../../components/DateFilter/DateFilter';
import StatusDot from '../../components/Orders/StatusDot';
import { capitalizeString } from '../../utils';
import DropdownFilter from '../../components/DropdownFilter/DropdownFilter';

const OrdersPageActions = () => {
  const {
    setCurrentPage,
    currentStatusFilter,
    setCurrentStatusFilter,
    currentDeliveryDateFrom,
    currentDeliveryDateTo,
    setCurrentDeliveryDateFrom,
    setCurrentDeliveryDateTo,
    currentReceivedDateFrom,
    currentReceivedDateTo,
    setCurrentReceivedDateFrom,
    setCurrentReceivedDateTo,
    currentGroupIdsForGroupSets,
    setGroupIdForGroupSet,
    unsetGroupIdForGroupSet,
  } = useOrders();

  const { getCustomerGroupSets, customerGroupSets } = useCustomerGroups();

  useEffect(() => {
    getCustomerGroupSets();
  }, []);

  const { orderFulfilmentMvpSupplierView } = useFlags();
  const orderFulfilmentOn = ['on', 'on-include-legacy'].includes(
    orderFulfilmentMvpSupplierView,
  );

  return (
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
        alignItems="center"
        wrap="wrap"
        rowGap="3"
        divider={<StackDivider borderColor="gray.300" mt="8px" mb="8px" />}
      >
        {orderFulfilmentOn &&
        customerGroupSets &&
        customerGroupSets.length > 0 ? (
          <DropdownFilter
            label="Status"
            value={currentStatusFilter ? currentStatusFilter : null}
            defaultValue="new"
            options={[
              { id: 'new', name: 'New', leftAddon: <StatusDot status="new" /> },
              {
                id: 'processing',
                name: 'Processing',
                leftAddon: <StatusDot status="processing" />,
              },
              {
                id: 'invoiced',
                name: 'Invoiced',
                leftAddon: <StatusDot status="invoiced" />,
              },
              {
                id: 'complete',
                name: 'Complete',
                leftAddon: <StatusDot status="complete" />,
              },
              { id: '', name: 'All' },
            ]}
            onChange={(option: any) => {
              setCurrentPage(1);
              setCurrentStatusFilter(option.id);
            }}
          />
        ) : (
          <ButtonGroup variant="pageFilter">
            {['new', 'processing', 'invoiced', 'complete'].map((status) => {
              const isActive = currentStatusFilter == status;
              return (
                <ChakraButton
                  key={`order-status-filter-button-${status}`}
                  isActive={isActive}
                  onClick={() => {
                    setCurrentPage(1);
                    setCurrentStatusFilter(status);
                  }}
                >
                  {isActive && <StatusDot mr="2" status={status} />}
                  {capitalizeString(status)}
                </ChakraButton>
              );
            })}
            <ChakraButton
              isActive={currentStatusFilter == ''}
              onClick={() => {
                setCurrentPage(1);
                setCurrentStatusFilter('');
              }}
            >
              All
            </ChakraButton>
          </ButtonGroup>
        )}

        <HStack wrap="wrap">
          <>
            {orderFulfilmentOn &&
              customerGroupSets &&
              customerGroupSets.map((groupSet) => {
                if (groupSet.groups.length > 0) {
                  const value = currentGroupIdsForGroupSets[groupSet.id]
                    ? currentGroupIdsForGroupSets[groupSet.id]
                    : null;
                  return (
                    <DropdownFilter
                      key={groupSet.id}
                      value={value}
                      label={groupSet.name}
                      options={groupSet.groups}
                      onChange={(group: any) => {
                        if (group) {
                          setGroupIdForGroupSet(group.id, groupSet.id);
                        } else {
                          unsetGroupIdForGroupSet(groupSet.id);
                        }
                      }}
                    />
                  );
                }
              })}
          </>
          <DateFilter
            labelText="Delivery"
            currentFromDate={currentDeliveryDateFrom}
            currentToDate={currentDeliveryDateTo}
            setCurrentFromDate={(date: Date | null) => {
              setCurrentPage(1);
              setCurrentDeliveryDateFrom(date);
            }}
            setCurrentToDate={(date: Date | null) => {
              setCurrentPage(1);
              setCurrentDeliveryDateTo(date);
            }}
          />
          <DateFilter
            labelText="Received"
            currentFromDate={currentReceivedDateFrom}
            currentToDate={currentReceivedDateTo}
            setCurrentFromDate={(date: Date | null) => {
              setCurrentPage(1);
              setCurrentReceivedDateFrom(date);
            }}
            setCurrentToDate={(date: Date | null) => {
              setCurrentPage(1);
              setCurrentReceivedDateTo(date);
            }}
          />
        </HStack>
      </Stack>
  );
};

export default observer(OrdersPageActions);
