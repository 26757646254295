import { Flex } from '@chakra-ui/react';
import TopBanner from '../TopBanner/TopBanner';
import { useAuth } from '../../contexts/auth';

import Sidebar from '../Sidebar/Sidebar';

function AppWrapper({ children }: any) {
  const { user } = useAuth();
  return (
    <Flex h="100vh" flexDirection="column">
      {user && <TopBanner />}
      <Flex flex="1" overflow="hidden">
        <Sidebar minWidth="96px" />

        <Flex flexDirection="column" flex="2">
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
}
export default AppWrapper;
