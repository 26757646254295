import { action, computed, makeObservable, observable, remove } from 'mobx';
import * as Sentry from '@sentry/browser';

import { api } from '../api';

import { IModel } from '../stores/Model';
import { CustomerGroupSet } from '../models/CustomerGroupSet';
import { Customer } from '../stores/customerGroupsStore';

export interface BaseCustomerGroupProps {
  id: number;
  name: string;
  sort_order: number;
  customer_count: number;
}

export interface CustomerGroupProps extends BaseCustomerGroupProps {
  customers: any[];
  customer_group_set_id: number;
  customer_group_set_name: string;
}

export class CustomerGroup implements IModel {
  id: number;
  @observable name: string;
  @observable sortOrder: number;

  @observable customer_group_set_name: string | null = null;
  @observable customer_group_set_id: number | null = null;

  @observable _customerCount: number;

  @observable isFullyLoaded: boolean = false;
  @observable isCurrent: boolean = false;
  @observable groupSet: CustomerGroupSet;

  @observable customersList: { [id: number]: Customer } = {};
  @observable customersIndex: (number | null)[] = [];

  constructor(
    groupSet: CustomerGroupSet,
    props: BaseCustomerGroupProps | CustomerGroupProps,
  ) {
    this.groupSet = groupSet;

    const { id, name, customer_count, sort_order } = props;
    this.id = id;
    this.name = name;
    this.sortOrder = sort_order;
    this._customerCount = customer_count || 0;

    if ('customers' in props) {
      this.addData(props);
    }

    makeObservable(this);
  }

  @computed
  get customerCount() {
    if (this.isFullyLoaded) {
      return this.customers.length;
    }
    return this._customerCount;
  }

  @computed
  get customers(): Customer[] {
    return this.customersIndex
      .filter((key): key is number => key !== null && typeof key === 'number')
      .map((key: number) => {
        return this.customersList[key];
      });
  }

  @computed
  get toFormInitialValues() {
    return {
      name: this.name,
      customer_customer_groups_attributes: this.customers.map(
        (customer: any) => customer.toFormInitialValues,
      ),
    };
  }

  @action
  addData = (props: CustomerGroupProps) => {
    const {
      id,
      name,
      sort_order,
      customer_group_set_name,
      customer_group_set_id,
      customers,
    } = props;
    this.id = id;
    this.name = name;
    this.customer_group_set_name = customer_group_set_name;
    this.customer_group_set_id = customer_group_set_id;
    this.sortOrder = sort_order;
    this.isFullyLoaded = true;

    customers.forEach((customer: any, index: number) => {
      if (!this.customersList[customer.id]) {
        this.customersList[customer.id] = new Customer(this, customer);
      } else {
        this.customersList[customer.id].addData(customer);
      }
      this.customersIndex[index] = customer.id;
    });
  };

  @action
  assignCustomer = (customer: any) => {
    return new Promise<Response>((resolve, reject) => {
      api
        .put(
          `/v4/customers/${customer.id}`,
          JSON.stringify({
            company: {
              customer_group_ids: [
                ...customer.customer_groups.map(
                  (cg: any) => cg.customer_group_id,
                ),
                this.id,
              ],
            },
          }),
        )
        .then(async (response) => {
          const customer = await response.json();
          if (response.ok) {
            this.addCustomer(customer);
            resolve(customer);
          }
          reject(customer);
        });
    });
  };

  @action
  addCustomer = (customer: any) => {
    this.groupSet.removeUnassignedCustomer(customer);
    if (this.isFullyLoaded) {
      this.customersList[customer.id] = new Customer(this, customer);
      this.customersIndex[this.customersIndex.length] = customer.id;
    } else {
      this._customerCount += 1;
    }
  };

  @action
  setSortOrder = (value: number) => {
    this.sortOrder = value;
  };

  @action
  setIsCurrent = (value: boolean) => {
    this.isCurrent = value;
  };

  @action
  private updateData(group: any) {
    this.name = group.name;

    const cachedCustomers = this.customers;

    this.customersList = {};
    this.customersIndex = [];
    group.customers.forEach((customer: any, index: number) => {
      if (!this.customersList[customer.id]) {
        this.customersList[customer.id] = new Customer(this, customer);
      }
      this.customersIndex[index] = customer.id;
    });

    const addedCustomers = this.customers.filter(
      (c) => !cachedCustomers.find((cc) => cc.customerId === c.customerId),
    );
    const removedCustomers = cachedCustomers.filter(
      (c) => !this.customers.find((cc) => cc.customerId === c.customerId),
    );

    addedCustomers.forEach((c: any) =>
      this.groupSet.removeUnassignedCustomer(c),
    );
    removedCustomers.forEach((c: any) =>
      this.groupSet.addUnassignedCustomer(c),
    );
  }

  @action
  update = (formData: any) => {
    return new Promise<Response>((resolve, reject) => {
      api
        .put(
          `/v4/customer_groups/${this.id}`,
          JSON.stringify({
            customer_group: {
              ...formData,
            },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.updateData(data);
            resolve(data);
          }
          reject(data);
        });
    });
  };

  @action
  delete = () => {
    return new Promise<Response>((resolve, reject) => {
      api
        .delete(`/v4/customer_groups/${this.id}`)
        .then(async (response) => {
          if (response.ok) {
            const index = this.groupSet.groupsIndex.indexOf(this.id);
            if (index > -1) {
              this.customers.forEach((c: any) =>
                this.groupSet.addUnassignedCustomer(c),
              );
              remove(this.groupSet.groupsIndex, `${index}`);
              remove(this.groupSet.groupsList, `${this.id}`);
              resolve(response);
            }
          }
          reject(response);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };
}
