import React, { useEffect, useState } from 'react';
import {
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverFooter,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { Button } from '../Button/Button';

export type NoteEditorProps = {
  initialValue: string;
  onSave: (value: string) => void;
  triggerChildren: any;
  headerText: string;
};

const NoteEditor = ({
  initialValue,
  onSave,
  triggerChildren,
  headerText,
}: NoteEditorProps): JSX.Element => {
  const initialFocusRef = React.useRef(null);
  const [currentValue, setCurrentValue] = useState<string>(initialValue);
  const maxLength = 280;

  useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  return (
    <Popover
      placement="top"
      closeOnBlur={true}
      initialFocusRef={initialFocusRef}
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>{triggerChildren}</PopoverTrigger>
          <PopoverContent textAlign="left" color="gray.900">
            <PopoverArrow />
            <PopoverBody p="16px 16px 0px">
              <FormControl id={'note'}>
                <FormLabel fontSize="sm">{headerText}</FormLabel>
                <Input
                  id={'note'}
                  placeholder={'Note'}
                  value={currentValue}
                  ref={initialFocusRef}
                  fontSize="sm"
                  size="sm"
                  borderRadius="6px"
                  maxLength={maxLength}
                  onChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    setCurrentValue(target.value);
                  }}
                  onKeyPress={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      // Note that this code is repeated in the onClick event below
                      onSave(currentValue);
                      onClose();
                    }
                  }}
                />
              </FormControl>
            </PopoverBody>
            <PopoverFooter border="0" p="12px 16px 16px">
              <HStack>
                <Text
                  textColor="gray.400"
                  fontSize="xs"
                  lineHeight="4"
                  fontWeight="500"
                >
                  {maxLength - currentValue.length} characters remaining
                </Text>
                <Spacer />
                <ButtonGroup size="xs" spacing="1">
                  <Button
                    size="xs"
                    onClick={() => {
                      setCurrentValue(initialValue);
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    size="xs"
                    onClick={() => {
                      // Note that this code is repeated in the onKeyPress event above
                      onSave(currentValue);
                      onClose();
                    }}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </HStack>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default NoteEditor;
