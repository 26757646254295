import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { useLayoutEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { CaretDownIcon, CaretUpIcon } from '../Icons/IconsNew';

import InactiveTag from '../InactiveTag/InactiveTag';
import FavouriteButton from './FavouriteButton';
import { Caption } from '../Typography/Typography';
import { useCurrentUser } from '../../hooks/useStores';

const ProductsListView = ({ product, showFavourites = false }: any) => {
  const { isBuyer } = useCurrentUser();
  const isInactive = product.active === false;

  return (
    <Box
      w="100%"
      flex={1}
      display="flex"
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        {isInactive && (
          <Box mb={1}>
            <InactiveTag />
          </Box>
        )}
        <Text color="gray.700" fontWeight="400" noOfLines={2}>
          {product.name}
        </Text>
        <Box lineHeight="24px" w="100%">
          {isBuyer ? (
            <BuyerProductsListDetails product={product} />
          ) : (
            <SupplierProductsListView product={product} />
          )}
        </Box>
      </Box>
      {isBuyer && <FavouriteButton product={product} show={showFavourites} />}
    </Box>
  );
};

const SupplierProductsListView = ({ product }: any) => (
  <Box as="span" display="flex" w="100%" position="relative">
    <Caption color="gray.500">
      {product.product_code && (
        <Text as="span" fontWeight="semibold">
          {product.product_code}
        </Text>
      )}
    </Caption>
  </Box>
);

const BuyerProductsListDetails = ({ product }: any) => {
  const { isOpen, onToggle } = useDisclosure();

  const collapseRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [showElipsis, setShowElipsis] = useState(true);

  useLayoutEffect(() => {
    const height = collapseRef.current?.getBoundingClientRect().height;
    setHeight(height || 0);
  }, [isOpen]);

  const isExpandable = product.description?.length > 135;

  return (
    <>
      <AnimateHeight
        delay={50}
        height={height || 'auto'}
        onHeightAnimationEnd={() => {
          setShowElipsis(!isOpen);
        }}
      >
        <Box
          as="span"
          display="flex"
          w="100%"
          onClick={onToggle}
          ref={collapseRef}
          position="relative"
          cursor={isExpandable ? 'pointer' : undefined}
        >
          <Caption
            noOfLines={showElipsis && !isOpen ? 2 : undefined}
            maxHeight={!isOpen ? '36.4px' : undefined}
            color="gray.500"
            mr={isExpandable ? 4 : 2}
            fontSize="12px"
            flex={1}
          >
            {product.price && (
              <Text as="span" fontWeight="semibold">
                ${Number(product.price).toFixed(2)}
                {product.description ? ' • ' : ''}
              </Text>
            )}
            {product.description}
          </Caption>
          {isExpandable && (
            <Box
              as="div"
              position="absolute"
              width="12px"
              top="24px"
              right="0"
              color="gray.500"
              marginX={1}
            >
              {isOpen ? <CaretUpIcon w="12px" /> : <CaretDownIcon w="12px" />}
            </Box>
          )}
        </Box>
      </AnimateHeight>
    </>
  );
};

export default ProductsListView;
