const Table = {
  sizes: {
    sm: {
      th: {
        px: '4',
        py: '3',
      },
      td: {
        px: '4',
        py: '3',
      },
    },
  },
  variants: {
    pagelist: {
      th: {
        fontWeight: 500,
        textTransform: 'none',
      },
      tbody: {
        backgroundColor: 'white',
      },
    },
    orderitems: {
      table: {
        borderRadius: '5px',
        borderWidth: '1px',
        borderColor: 'gray.200',
        borderCollapse: 'separate',
        borderSpacing: 0,
        borderBottom: 'none',
        width: '509px',
      },
      thead: {
        backgroundColor: 'gray.200',
        color: 'gray.600',
        borderWidth: '1px',
        borderColor: 'gray.200',
      },
      tbody: {
        borderBottom: 'none',
      },
      tr: {
        borderColor: 'gray.200',
      },
      th: {
        fontWeight: '500',
        textTransform: 'uppercase',
        fontSize: 'xs',
      },
      td: {
        verticalAlign: 'middle',
        borderBottomWidth: '1px',
        fontSize: 'md',
      },
    },
    standingorder: {
      table: {
        borderRadius: '8px',
        borderWidth: '1px',
        borderColor: 'gray.200',
        borderCollapse: 'separate',
        borderSpacing: 0,
        borderBottom: 'none',
        '&.editing': {
          td: {
            '&:first-of-type': {
              paddingLeft: '1',
            },
          },
        },
      },
      th: {
        color: 'gray.600',
        borderBottom: '1px',
        borderColor: 'gray.300',
        paddingTop: '6',
        paddingBottom: '6',
        paddingLeft: '1',
        paddingRight: '1',
        textAlign: 'end',
        '&:first-of-type': {
          paddingLeft: '3',
        },
        '&:last-child': {
          paddingRight: '3',
        },
      },
      td: {
        borderBottom: '1px',
        borderColor: 'gray.300',
        paddingTop: '3',
        paddingBottom: '3',
        paddingLeft: '1',
        paddingRight: '1',
        textAlign: 'end',
        color: 'gray.600',
        fontSize: '14px',
        '&:first-of-type': {
          paddingLeft: '3',
        },
        '&:last-child': {
          paddingRight: '3',
        },
      },
      tbody: {
        borderBottom: 'none',
        tr: {
          '&:nth-of-type(odd)': {
            backgroundColor: 'gray.100',
          },
          '&:last-of-type': {
            td: {
              '&:first-of-type': {
                borderBottomLeftRadius: '8px',
              },
              '&:last-of-type': {
                borderBottomRightRadius: '8px',
              },
            },
          },
        },
      },
    },
  },
};

export default Table;
