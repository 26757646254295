import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
  Avatar,
  Box,
  Divider,
  Flex,
  Heading,
  Text,
  Textarea,
  Input,
  VStack,
} from '@chakra-ui/react';

import { useCustomerGroups, useCustomers } from '../../../hooks/useStores';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import UnlinkedTag from '../../../components/UnlinkedTag/UnlinkedTag';

import StandingOrderTable from '../../../components/StandingOrders/StandingOrderTable';
import { Subhead } from '../../../components/Typography/Typography';
import { Button } from '../../../components/Button/Button';

import CustomerGroupForm from './CustomerGroupForm';
import DetailView from '../../../components/DetailView/DetailView';
import { useErrorToast, useSuccessToast } from '../../../components/toast';
import { Customer } from '../../../stores/customers/customer';
import CustomerCompanyFields from './CustomerCompanyFields';

const EditCustomer = ({ customer }: { customer: Customer }) => {
  const { getCustomerGroupSets } = useCustomerGroups();
  const { updateCustomer, saveButtonIsLoading, setSaveButtonIsLoading } =
    useCustomers();
  const history = useHistory();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const companyFieldsEditable = customer.can_edit_company_fields;

  const { orderFulfilmentMvpSupplierView } = useFlags();
  const orderFulfilmentOn = ['on', 'on-include-legacy'].includes(
    orderFulfilmentMvpSupplierView,
  );

  useEffect(() => {
    getCustomerGroupSets();
  }, []);

  return (
    <DetailView
      rightActions={
        <>
          <Button
            size="sm"
            onClick={() => {
              history.push(`/customers/${customer.id}`);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            isLoading={saveButtonIsLoading}
            onClick={() => {
              setSaveButtonIsLoading(true);
              updateCustomer(
                customer.formState,
                customer.id,
                () => {
                  setSaveButtonIsLoading(false);
                  successToast({ description: 'Customer updated.' });
                  history.push(`/customers/${customer.id}`);
                  window.scrollTo(0, 0);
                },
                () => {
                  setSaveButtonIsLoading(false);
                  errorToast({ description: 'Failed to save.' });
                },
              );
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <form style={{ backgroundColor: 'white', marginBottom: '80px' }}>
        <Flex direction="row" py="6">
          <Box>
            <Avatar size="xl" src={customer.logo_url} />
          </Box>
          <Box pl="5" pt="8">
            {companyFieldsEditable && (
              <Input
                onChange={(e) => customer.setFormName(e.target.value)}
                defaultValue={customer.formName}
                width="320px"
              ></Input>
            )}
            {!companyFieldsEditable && (
              <>
                <Heading size="md" as="h4">
                  {customer.name}
                </Heading>
                {customer.is_unlinked && <UnlinkedTag />}
              </>
            )}
          </Box>
        </Flex>

        <VStack align="left" spacing="8" mt="16">
          <VStack align="left" spacing="8" maxWidth="525px">
            <FormFieldWrapper
              fieldName="note"
              fieldLabel={<Subhead fontWeight="600">Customer note</Subhead>}
              subLabel="Not visible to customers"
            >
              <Textarea
                onChange={(e) => customer.setFormNotes(e.target.value)}
                width="320px"
                defaultValue={customer.formNotes}
              ></Textarea>
              <Text mt="10px" color="gray.600" fontSize="xs">
                Customer note appears on orders from this customer, including
                downloads and prints.
              </Text>
            </FormFieldWrapper>
          </VStack>

          <Divider />

          <VStack
            width="full"
            align="left"
            maxWidth={['583px', null, null, null, null, null, null, 'full']}
          >
            {customer.standingOrder ? (
              <StandingOrderTable
                standingOrder={customer.formStandingOrder}
                isEditable={true}
              />
            ) : (
              <FormFieldWrapper
                fieldName="standing_order"
                fieldLabel={<Subhead fontWeight="600">Standing order</Subhead>}
              >
                <Button
                  onClick={() => {
                    customer.createEmptyStandingOrder();
                  }}
                >
                  Create standing order
                </Button>
              </FormFieldWrapper>
            )}
          </VStack>
          <Divider />

          {orderFulfilmentOn && <CustomerGroupForm isEditable={true} />}

          <CustomerCompanyFields customer={customer} isEditable={true} />
        </VStack>
      </form>
    </DetailView>
  );
};

export default observer(EditCustomer);
