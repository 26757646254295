import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDelivery } from '../../contexts/delivery';
import { useAuth } from '../../contexts/auth';
import DeliveryForm from './DeliveryForm';

import AppWrapper from '../../components/Layouts/AppWrapper';
import OneUpWrapper from '../../components/Layouts/OneUpWrapper';
import ActionBar from '../../components/ActionBar/ActionBar';
import OneUpScrollPanel from '../../components/Layouts/OneUpScrollPanel';

const DeliveryDaysPage = (): JSX.Element => {
  const { user } = useAuth();
  const { deliveryData, getDeliveryData } = useDelivery();

  useEffect(() => {
    getDeliveryData(`/v3/companies/${user.company.id}`, user);
  }, []);

  return (
    <AppWrapper>
      <OneUpWrapper headerChildren={<ActionBar title="Delivery days" />}>
        <OneUpScrollPanel>
          <Box p="40px" bg="white">
            {deliveryData && <DeliveryForm deliveryData={deliveryData} />}
          </Box>
        </OneUpScrollPanel>
      </OneUpWrapper>
    </AppWrapper>
  );
};

export default DeliveryDaysPage;
