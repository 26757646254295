import React, { useState, useEffect } from 'react';
import { useAccounting } from '../../../../contexts/accounting';
import { ClipboardIcon } from '../../../../components/Icons/Icons';
import { useLocation } from 'react-router-dom';
import useAPI from '../../../../hooks/useAPI';
import { PasswordField } from '../../../../components/Auth/PasswordField';
import { useSuccessToast, useErrorToast } from '../../../../components/toast';
import {
  PrimaryButton,
  TertiaryButton,
} from '../../../../components/Button/Button';

import {
  Button,
  ButtonGroup,
  Center,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Select,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';

const MyobSetup = () => {
  const { getCurrentAccountingConnection } = useAccounting();
  let queryParams = new URLSearchParams(useLocation().search);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [companyFiles, setCompanyFiles] = useState([]);
  const [companyFileId, setCompanyFileId] = useState('');
  const [companyFileUsername, setCompanyFileUsername] = useState('');
  const [companyFilePassword, setCompanyFilePassword] = useState('');
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const [callAPIGet, fetchIsLoading] = useAPI({
    method: 'GET',
  });

  const [callAPIPut, submitIsLoading] = useAPI({
    method: 'PUT',
  });

  useEffect(() => {
    callAPIGet('/v4/accounting_connectors/myob/company_files')
      .then((data: any) => {
        setCompanyFiles(data);
        if (data.length == 1) {
          setCompanyFileId(data[0].id);
        }
      })
      .catch(() => {
        errorToast({
          description: 'Please refresh the page and try again.',
        });
      });

    if (queryParams.get('setup_required') == '1') {
      onOpen();
    }
  }, []);

  function onSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    callAPIPut('/v4/accounting_connectors/myob/setup', {
      body: JSON.stringify({
        company_file_id: companyFileId,
        company_file_username: companyFileUsername,
        company_file_password: companyFilePassword,
      }),
    })
      .then(() => {
        successToast({
          description:
            'MYOB is successfully set up and ready to receive Invoices.',
        });
        getCurrentAccountingConnection(() => {
          onClose();
        });
      })
      .catch(() => {
        errorToast({
          description: 'Your MYOB username and password is invalid.',
        });
      });
  }

  return (
    <>
      <Button
        rightIcon={<ClipboardIcon />}
        colorScheme="green"
        onClick={onOpen}
      >
        Finish setup
      </Button>

      <Modal size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={onSubmit}>
            <ModalHeader>Finish MYOB setup</ModalHeader>
            <ModalBody>
              <VStack spacing="8">
                <Text pb="2">
                  We’ve connected to your MYOB account. Find your MYOB business
                  name and use your AccountRight User ID &amp; password to
                  confirm setup.
                </Text>
                {fetchIsLoading && (
                  <Center py="6">
                    <Spinner thickness="6px" size="xl" color="green" />
                  </Center>
                )}
                {!fetchIsLoading && (
                  <>
                    <FormControl id="companyFile">
                      <FormLabel>Business</FormLabel>
                      <Select
                        name="companyFile"
                        defaultValue={companyFileId}
                        onChange={(e) => setCompanyFileId(e.target.value)}
                        tabIndex={1}
                      >
                        {companyFiles &&
                          companyFiles.map((companyFile: any) => {
                            return (
                              <option
                                value={companyFile.id}
                                key={companyFile.id}
                              >
                                {companyFile.name}
                              </option>
                            );
                          })}
                      </Select>
                    </FormControl>

                    <FormControl id="cf-username">
                      <FormLabel>User ID</FormLabel>
                      <Input
                        name="cf-username"
                        tabIndex={2}
                        autoComplete="off"
                        onChange={(e) => setCompanyFileUsername(e.target.value)}
                      />
                      <Text pt="2" fontSize="sm">
                        Use your MYOB Business or AccountRight login details -
                        this may be different to what you usually use to log
                        into MYOB.
                      </Text>
                    </FormControl>

                    <FormControl id="cf-password">
                      <FormLabel>Password</FormLabel>
                      <PasswordField
                        name="cf-password"
                        tabIndex={3}
                        autoComplete="off"
                        onChange={(e) => setCompanyFilePassword(e.target.value)}
                      />
                    </FormControl>
                  </>
                )}
              </VStack>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup>
                <TertiaryButton onClick={onClose} isDisabled={submitIsLoading}>
                  Cancel
                </TertiaryButton>
                <PrimaryButton
                  isLoading={submitIsLoading}
                  colorScheme="green"
                  tabIndex={4}
                  type="submit"
                >
                  Continue
                </PrimaryButton>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MyobSetup;
