import { observer } from 'mobx-react';
import { Instance, SnapshotOrInstance } from 'mobx-state-tree';
import {
  Box,
  Input,
  FormLabel,
  FormControl,
  Text,
  HStack,
  Avatar,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button as ChakraButton,
  ButtonGroup,
  ModalCloseButton,
} from '@chakra-ui/react';

import Order from '../../../models/Order';
import {
  CaretLeftIcon,
  ShoppingCartIcon,
} from '../../../components/Icons/Icons';
import { useAddOrder } from '../../../contexts/addOrder';
import OrderItem from '../../../models/OrderItem';
import { isDeliveryDateValid, pluralizeString } from '../../../utils';
import DateInput, {
  PureDateInput,
} from '../../../components/DateInput/DateInput';
import useEditSnapshot from '../../../hooks/useEditSnapshot';
import MaxLengthTextarea from '../../../components/MaxLengthTextarea/MaxLengthTextarea';
import { Button } from '../../../components/Button/Button';
import ReviewOrderItemRow from './ReviewOrderItemRow';
import OrderAddressField from './OrderAddressField';
import { useCurrentUser } from '../../../hooks/useStores';
import { Formik, FormikProps } from 'formik';
import { useRef } from 'react';
import { AddressFieldComponents } from '../../../models/Address';
import { newAddressValidationSchema } from './AddAddressForm';
import Banner from '../../../components/Banner/Banner';
import moment from 'moment';
import { WarningIcon } from '../../../components/Icons/IconsNew';

const newAddressValues: AddressFieldComponents = {};

const ReviewOrderScreen = observer(
  ({
    onBack,
    onSubmit,
    isLoading,
  }: {
    onBack: () => void;
    onSubmit: () => void;
    isLoading: boolean;
  }) => {
    const { order } = useAddOrder();
    const { company, companyAddresses, isBuyer } = useCurrentUser();

    const [
      editState,
      isEditing,
      onStartEditing,
      onSaveEditing,
      onCancelEditing,
    ] = useEditSnapshot<typeof Order>(order);

    const isInvalid =
      order.items.filter((item) => item.amount !== 0 && item.amount !== null)
        .length == 0;

    const handleAddNewAddress = (newAddress: AddressFieldComponents) => {
      if (company) {
        return company.createAddress(newAddress).then((data) => {
          if (data.id) {
            order.setAddress({
              id: data.id,
              full_address: data.full_address || '',
              instructions: data.instructions || '',
            });
          }
        });
      }
      return Promise.resolve();
    };

    const handleSubmitWithAddress = (address: AddressFieldComponents) => {
      handleAddNewAddress(address).then(() => {
        onSubmit();
      });
    };

    const orderAddressId = order.delivery_address_id !== null;

    const dateIsAvailable =
      !isBuyer || !order.deliveryDate ||
        isDeliveryDateValid(order.deliveryDate, order.supplier?.delivery_rules?.toJSON());
    
    return (
      <Formik
        initialValues={newAddressValues}
        onSubmit={(val) => handleSubmitWithAddress(val)}
        validationSchema={newAddressValidationSchema}
      >
        {(newAddressFormik) => {
          const isAddressValid =
            (newAddressFormik.dirty && newAddressFormik.isValid) ||
            (!newAddressFormik.dirty && orderAddressId);
          return (
            <ModalContent>
              <ModalHeader
                px={6}
                pb={0}
                bg="gray.100"
                borderTopRadius="var(--chakra-radii-md)"
                boxShadow="inset 0px -1px 0px #D1D5DB"
              >
                <ChakraButton
                  onClick={onBack}
                  variant="ghost"
                  leftIcon={<CaretLeftIcon />}
                  mb="6"
                  pl="0"
                >
                  Back
                </ChakraButton>
                <ModalCloseButton top={4} right={4} />
                <HStack mb="10">
                  <Avatar size="lg" src={isBuyer ? order.supplier?.logo : order.customer?.logo} />
                  <Text>
                    {isBuyer ? order.supplier?.name : order.customer?.name}
                  </Text>
                </HStack>
              </ModalHeader>
              <ModalBody p={0}>
                <Box px="6" mt="6" mb="8">
                  {isBuyer ? (
                    <>
                      <PureDateInput
                        id="delivery_date"
                        label="Delivery date"
                        mb={2}
                        labelProps={{
                          fontSize: 'lg',
                        }}
                        value={order.deliveryDate}
                        onChange={(date) => {
                          order.setDeliveryDate(date);
                          editState.setDeliveryDate(date);
                        }}
                        deliveryRules={order.supplier?.delivery_rules?.toJSON()}
                        deliveryRulesEnabled={
                          order.supplier?.delivery_rules_enabled
                        }
                        enforceDeliveryRules={true}
                      />
                    </>
                  ) : (
                    <DateInput
                      id="delivery_date"
                      label="Delivery date"
                      
                      
                      labelProps={{
                        fontSize: 'lg',
                      }}
                      value={order.deliveryDate}
                      onChange={(date) => {
                        order.setDeliveryDate(date);
                        editState.setDeliveryDate(date);
                      }}
                      enforceDeliveryRules={true}
                    />
                  )}
                  {!dateIsAvailable && (
                    <Banner
                      status="error"
                      icon={<WarningIcon color="error.amber" width="24px" />}
                      title={`${moment(order.deliveryDate).format(
                        'dddd',
                      )}'s cut-off time has passed`}
                      description="Please note that the cut-off time for the delivery date you’ve requested has already passed. We will fulfil your order as soon as possible."
                      variant="warning"
                    />
                  )}
                </Box>

                <FormControl id="delivery_address" px={6} mb={8}>
                  <FormLabel>
                    <Text fontSize="lg">Delivery Address</Text>
                  </FormLabel>
                  {isBuyer ? (
                    <OrderAddressField
                      value={`${order.delivery_address_id}`}
                      addresses={companyAddresses}
                      onSelectAddress={(address) => {
                        order.setAddress({
                          id: address.id,
                          full_address: address.full_address || '',
                          instructions: address.instructions || '',
                        });
                      }}
                    />
                  ) : (
                    <Input
                      placeholder="123 Streety Street, Streetsville, Citytown, 1234"
                      value={order.delivery_address || ''}
                      onChange={(e) => {
                        order.setDeliveryAddress(e.target.value);
                        editState.setDeliveryAddress(e.target.value);
                      }}
                    />
                  )}
                </FormControl>

                <MaxLengthTextarea
                  id="delivery_message"
                  label="Message"
                  placeholder="Optional"
                  maxLength={500}
                  onChange={(value: string) => {
                    order.setMessage(value);
                    editState.setMessage(value);
                  }}
                  px="6"
                  mb="6"
                />

                <HStack
                  px={6}
                  py={2}
                  justify="space-between"
                  boxShadow="inset 0px -1px 0px #E5E7EB"
                >
                  <Text fontSize="lg">Order</Text>
                  {isEditing ? (
                    <ButtonGroup>
                      <ChakraButton
                        variant="link"
                        colorScheme="green"
                        color="green.700"
                        onClick={onSaveEditing}
                      >
                        Save
                      </ChakraButton>
                      <ChakraButton
                        variant="link"
                        colorScheme="green"
                        color="green.700"
                        onClick={() => {
                          editState.items.forEach((item, key) => {
                            if (item.notes) {
                              order.items[key].setNotes(item.notes);
                            }
                          });
                          onCancelEditing();
                        }}
                      >
                        Cancel
                      </ChakraButton>
                    </ButtonGroup>
                  ) : (
                    <ChakraButton
                      variant="link"
                      colorScheme="green"
                      color="green.700"
                      onClick={onStartEditing}
                    >
                      Edit
                    </ChakraButton>
                  )}
                </HStack>
                {editState.items.length > 0 ? (
                  editState.items.map((item: Instance<typeof OrderItem>) => (
                    <ReviewOrderItemRow
                      key={item.buyable.product.id}
                      orderItem={item}
                      isEditing={isEditing}
                      remove={editState.removeOrderItem}
                    />
                  ))
                ) : (
                  <HStack
                    justify="space-between"
                    boxShadow="inset 0px -1px 0px #E5E7EB"
                    px={6}
                    py={3}
                  >
                    <Text>No products added</Text>
                    {!isEditing && (
                      <ChakraButton onClick={onBack}>
                        Go back to add products
                      </ChakraButton>
                    )}
                  </HStack>
                )}
              </ModalBody>
              <ModalFooter
                backgroundColor="gray.100"
                borderRadius="0 0 8px 8px"
                justifyContent="space-between"
              >
                <HStack>
                  <ShoppingCartIcon color="gray.400" />
                  <Text color="gray.500">
                    {order.items.length}{' '}
                    {pluralizeString('item', order.items.length)}
                  </Text>
                </HStack>
                <Button
                  variant="primary"
                  isDisabled={!isAddressValid || isInvalid || isEditing}
                  isLoading={isLoading}
                  onClick={() => orderAddressId ? onSubmit() : newAddressFormik.submitForm()}
                >
                  Complete order
                </Button>
              </ModalFooter>
            </ModalContent>
          );
        }}
      </Formik>
    );
  },
);

export default ReviewOrderScreen;
