import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  ButtonGroup,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';

import { useCurrentUser, useOrders } from '../../hooks/useStores';
import debounce from 'lodash/debounce';
import { Button } from '../../components/Button/Button';
import { PlusIcon } from '../../components/Icons/IconsNew';
import ActionBar from '../../components/ActionBar/ActionBar';
import OrdersPageActions from './OrdersPageActions';

const OrdersPageActionBar = ({ errorContainerRef }: any) => {
  const {
    getOrders,
    currentPage,
    setCurrentPage,
    currentStatusFilter,
    setCurrentStatusFilter,
    currentSearchQuery,
    setCurrentSearchQuery,
    currentSortKey,
    currentSortDirection,
    currentDeliveryDateFrom,
    currentDeliveryDateTo,
    currentReceivedDateFrom,
    currentReceivedDateTo,
    currentGroupIds,
  } = useOrders();

  const { isBuyer } = useCurrentUser();

  const handleSearch = useCallback(
    debounce((e: React.ChangeEvent) => {
      const target = e.target as HTMLInputElement;
      setCurrentPage(1);
      setCurrentSearchQuery(target.value);
    }, 500),
    [],
  );

  useEffect(() => {
    if (isBuyer) {
      setCurrentStatusFilter(null)
    }
    getOrders(
      () => {},
      () => { },
      isBuyer ? 'outgoing' : 'incoming'
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentDeliveryDateFrom,
    currentDeliveryDateTo,
    currentReceivedDateFrom,
    currentReceivedDateTo,
    currentStatusFilter,
    currentSearchQuery,
    currentPage,
    currentSortKey,
    currentSortDirection,
    currentGroupIds,
  ]);

  return (
    <ActionBar
      title="Orders"
      rightChildren={
        <HStack>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              variant="outline"
              type="search"
              onChange={handleSearch}
              placeholder="Search"
              name="search"
              bg="white"
            />
          </InputGroup>
          <ButtonGroup>
            <Button
              variant="primary"
              leftIcon={<PlusIcon width="24px" />}
              href="/orders/new"
            >
              Add order
            </Button>
          </ButtonGroup>
        </HStack>
      }
    >
      {!isBuyer && <OrdersPageActions />}
      <Box ref={errorContainerRef} />
    </ActionBar>
  );
};

export default observer(OrdersPageActionBar);
