import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import {
  FormControl,
  HStack,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  Link,
  Select,
  Text,
  VStack,
  Divider,
  Box,
  BoxProps,
} from '@chakra-ui/react';
import { find } from 'lodash';
import { Footnote } from '../../../components/Typography/Typography';

import { useCustomerGroups, useProducts } from '../../../hooks/useStores';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import { toJS } from 'mobx';
import { Label } from '../../../components/Typography/Typography';
import { PricingTiersTable, PricingTiersTableRow } from './PricingTiersTable';

const PricingForm = ({ product }: any) => {
  const { units } = useProducts();
  const { pricingTiersGroupSet, getCustomerGroupSets } = useCustomerGroups();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCustomerGroupSets()
      .then(() => {
        // Ensure that new products have pricing_tiers pre-populated
        if (!product.id) {
          product.addPricingTiers(
            pricingTiersGroupSet.groups?.map((group: any) => {
              return {
                id: group.id,
                name: group.name,
                price: null,
              };
            }),
          );
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const hasError = (fieldName: string) => {
    return getErrorObject(fieldName) != undefined;
  };

  const getErrorMessage = (fieldName: string) => {
    return getErrorObject(fieldName).message;
  };

  const getErrorObject = (fieldName: string) => {
    return product.fieldErrors.find(
      (errorObj: any) => errorObj.fieldName == fieldName,
    );
  };

  return (
    <>
      <Divider />
      <VStack align="left" spacing={6}>
        <Label>Pricing</Label>

        <FormFieldWrapper
          fieldLabel="Default Price"
          fieldName="default_price"
          maxW="509px"
        >
          <FormControl id="default_price">
            <HStack>
              <InputGroup flex={2}>
                <InputLeftAddon children="$" />
                <NumberInput
                  defaultValue={product && product.default_price}
                  min={0}
                  precision={2}
                  letterSpacing={0}
                  onChange={(value) => product.setDefaultPrice(value)}
                >
                  <NumberInputField
                    paddingInline={2}
                    borderLeftRadius="0"
                    borderRightRadius="md"
                  />
                </NumberInput>
              </InputGroup>
              <Text paddingInline={2}>/</Text>
              <Select
                flex={1}
                minW="45px"
                defaultValue={
                  (product &&
                    product.pricing_unit &&
                    product.pricing_unit.id) ||
                  'price-unit-placeholder'
                }
                onChange={(e) => {
                  const newUnit = find(
                    units,
                    (u) => u.id === parseInt(e.target.value, 10),
                  );
                  product.setPricingUnit(toJS(newUnit));
                }}
                isInvalid={hasError('pricing_unit_id')}
              >
                <option value="price-unit-placeholder" disabled>
                  Unit
                </option>
                {units &&
                  units.map((unit: any, i: number) => {
                    return (
                      <option key={i} value={unit.id}>
                        {unit.name}
                      </option>
                    );
                  })}
              </Select>
            </HStack>
          </FormControl>

          {hasError('pricing_unit_id') && (
            <Text mt="2" fontSize="sm" color="red">
              {getErrorMessage('pricing_unit_id')}
            </Text>
          )}
        </FormFieldWrapper>

        <VStack align="left" spacing={2}>
          <PricingTiersTable
            header={
              <>
                <Text>Price tier</Text>
                <Text textAlign="right">
                  Price {`(${product.pricing_unit?.name || 'Units'})`}
                </Text>
              </>
            }
            rows={[
              ...pricingTiersGroupSet?.groups?.map((group: any) => {
                const tier = product?.pricing_tiers?.find(
                  (tier: any) => tier.id === group.id,
                );
                return (
                  <PricingTierRow
                    key={`pricing-row-${group.id}`}
                    name={group.name}
                    price={tier?.price}
                    placeholderPrice={product.default_price}
                    setPrice={(value) => tier.setPrice(value)}
                    customerCount={group?.customerCount}
                  />
                );
              }),
              <PricingTierRow
                key={`pricing-row-unassigned`}
                name="Unassigned"
                containerProps={{ backgroundColor: 'gray.100' }}
                placeholderPrice={product.default_price}
                isReadOnly
                customerCount={pricingTiersGroupSet?.unassigned_customer_count}
              />,
            ]}
          />

          <Footnote>
            You can edit tiers in{' '}
            <Link href={`/customer-group-sets`}>Customer Groups</Link>.
          </Footnote>
        </VStack>
      </VStack>
    </>
  );
};

const PricingTierRow = ({
  name,
  price,
  placeholderPrice,
  setPrice,
  customerCount,
  isReadOnly = false,
  containerProps = {},
}: {
  name: string;
  price?: string;
  placeholderPrice: string;
  setPrice?: (price: string) => {};
  customerCount?: number;
  isReadOnly?: boolean;
  containerProps?: BoxProps;
}) => (
  <PricingTiersTableRow {...containerProps}>
    <Box>
      <Box>
        <Text
          fontSize="15px"
          as={isReadOnly ? 'i' : undefined}
          color={isReadOnly ? 'gray.600' : 'gray.700'}
        >
          {name}
        </Text>
        <Text fontSize="13px" color="gray.500">
          {customerCount} customers
        </Text>
      </Box>
    </Box>
    <Box w="92px">
      <NumberInput
        defaultValue={price}
        size="sm"
        min={0}
        max={9999.99}
        clampValueOnBlur
        letterSpacing={0}
        precision={2}
        format={(val) => (val ? `$${val}` : '')}
        onChange={
          typeof setPrice === 'function'
            ? (value) => setPrice(value)
            : undefined
        }
        isReadOnly={isReadOnly}
        isDisabled={isReadOnly}
      >
        <NumberInputField
          paddingInline={3}
          textAlign="right"
          placeholder={`$${Number(placeholderPrice)?.toFixed(2)}`}
          borderRadius="md"
        />
      </NumberInput>
    </Box>
  </PricingTiersTableRow>
);

export default observer(PricingForm);
