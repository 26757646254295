import React, { ReactNode, useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { observer } from 'mobx-react';
import DateText from '../../../components/Orders/DateText';
import OrderItemsTable from './OrderItemsTable';
import Tag from '../../../components/Tag/Tag';
import { useEditableOrder } from '../../../contexts/editableOrder';
import { useOrders } from '../../../hooks/useStores';
import { useHistory } from 'react-router-dom';
import { useSuccessToast, useErrorToast } from '../../../components/toast';
import Banner from '../../../components/Banner/Banner';

import {
  Avatar,
  Box,
  ButtonGroup,
  HStack,
  Spacer,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalFooter,
  useDisclosure,
  Textarea,
  FormLabel,
} from '@chakra-ui/react';
import DateInput from '../../../components/DateInput/DateInput';
import ProductModalContent from '../../../components/ProductModalContent/ProductModalContent';
import { Button } from '../../../components/Button/Button';
import { PlusIcon } from '../../../components/Icons/IconsNew';
import DetailView from '../../../components/DetailView/DetailView';
import moment from 'moment';

const EditOrder = ({ order }: any) => {
  const mixpanel = useMixpanel();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSaving, setIsSaving] = useState(false);
  const { setEditableOrder, editableOrder } = useEditableOrder();
  const { updateOrder } = useOrders();
  let history = useHistory();
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  useEffect(() => {
    setEditableOrder(order);
  }, [order]);

  const onCancel = () => {
    setEditableOrder(undefined);
    history.push(`/orders/${order.id}`);
  };

  const onSave = () => {
    if (editableOrder) {
      editableOrder.validate();
      if (editableOrder.isValid) {
        setIsSaving(true);
        updateOrder(
          order.id,
          editableOrder.editPayload,
          () => {
            mixpanel.track('Edit Order -> Save');
            history.push(`/orders/${order.id}`);
            successToast({
              title: 'Nice one!',
              description: 'Order has been updated.',
            });
            setIsSaving(false);
          },
          () => {
            errorToast();
            setIsSaving(false);
          },
        );
      } else {
        errorToast({
          title: 'You have added products with 0 quantity.',
          description: 'Add an amount or delete added products.',
        });
      }
    }
  };

  const deletedStyles = order.isDeleted
    ? {
        opacity: 0.4,
        filter: 'grayscale(100%)',
      }
    : {};

  return (
    <DetailView
      rightActions={
        <>
          <Button size="sm" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={onSave}
            isLoading={isSaving}
          >
            Save
          </Button>
        </>
      }
    >
      <Box height="100%" maxWidth="758px">
        <Box pt="8" pb="10" {...deletedStyles}>
          <HStack width="100%" pt="1" mb="6">
            <Box
              width={{ base: '40px', '2xl': '72px' }}
              mr={{ base: '8px', '2xl': '24px' }}
            >
              <Avatar
                h={{ base: '40px', '2xl': '72px' }}
                w={{ base: '40px', '2xl': '72px' }}
                src={order.customer_company.logo_url}
              />
            </Box>
            <Box mt="4px">
              <Text fontSize="lg" fontWeight="600">
                {order.customer_company.name}
              </Text>
              <Text fontSize="lg">
                {order.source === 'standing_order' ? (
                  <Tag>Standing</Tag>
                ) : order.source === 'supplier_created' ? (
                  <>
                    {order.customer.fullname}{' '}
                    <Tag
                      tooltip={`Added on behalf of customer by ${order.customer.fullname}`}
                    >
                      Added
                    </Tag>
                  </>
                ) : (
                  order.customer.fullname
                )}
              </Text>
            </Box>
            <Spacer />
            <Box>
              <Text fontSize="sm" textAlign="right" color="gray.600">
                Order #{order.order_number}
              </Text>
              <DateText
                fontSize="sm"
                textAlign="right"
                color="gray.600"
                date={order.date}
              />
            </Box>
          </HStack>

          <VStack spacing="6" pt="6" alignItems="left">
            {editableOrder && (
              <DetailWrapper>
                <DateInput
                  id="delivery_date"
                  label="Delivery date"
                  labelProps={{
                    fontSize: 'sm',
                    mb: '8px',
                    fontWeight: '400',
                    color: 'gray.600',
                  }}
                  mb={2}
                  maxWidth="400px"
                  value={editableOrder.deliveryDate || new Date()}
                  onChange={(date) => {
                    editableOrder.setDeliveryDate(date);
                  }}
                  enforceDeliveryRules={true}
                />
                {order.initial_delivery_date &&
                  !moment(editableOrder?.deliveryDate).isSame(
                    order.initial_delivery_date,
                    'day',
                  ) && (
                    <Text
                      fontSize="sm"
                      decoration="line-through"
                      color="gray.400"
                    >
                      {moment(order.initial_delivery_date).format(
                        'dddd, D MMMM YYYY',
                      )}
                    </Text>
                  )}
              </DetailWrapper>
            )}
            {editableOrder && (
              <DetailWrapper>
                <FormLabel
                  htmlFor="delivery_address"
                  fontSize="sm"
                  mb="8px"
                  fontWeight="400"
                  color="gray.500"
                >
                  Delivery address
                </FormLabel>
                <Textarea
                  id="delivery_address"
                  maxWidth="400px"
                  value={editableOrder.delivery_address || ''}
                  placeholder={
                    order.initial_delivery_address || 'Add delivery address'
                  }
                  onChange={(e) => {
                    editableOrder.setDeliveryAddress(e.target.value);
                  }}
                  resize="none"
                  mb={2}
                />
                {order.initial_delivery_address &&
                  order.initial_delivery_address !==
                    editableOrder.delivery_address && (
                    <Text
                      fontSize="sm"
                      decoration="line-through"
                      color="gray.400"
                    >
                      {order.initial_delivery_address || ''}
                    </Text>
                  )}
              </DetailWrapper>
            )}
            {editableOrder && (
              <DetailWrapper>
                <FormLabel
                  htmlFor="delivery_instructions"
                  fontSize="sm"
                  mb="8px"
                  fontWeight="400"
                  color="gray.500"
                >
                  Delivery instructions
                </FormLabel>
                <Textarea
                  id="delivery_instructions"
                  maxWidth="400px"
                  mb={2}
                  placeholder="Add delivery instructions"
                  value={editableOrder.delivery_instructions || ''}
                  onChange={(e) => {
                    editableOrder.setDeliveryInstructions(e.target.value);
                  }}
                />
                {order.initial_delivery_instructions &&
                  order.initial_delivery_instructions !==
                    editableOrder.delivery_instructions && (
                    <Text
                      fontSize="sm"
                      decoration="line-through"
                      color="gray.400"
                    >
                      {order.initial_delivery_instructions || ''}
                    </Text>
                  )}
              </DetailWrapper>
            )}
            {order.message && (
              <ReadOnlyDetailWrapper label="Message" content={order.message} />
            )}
            {order.customer_company.notes && (
              <ReadOnlyDetailWrapper
                label="Customer notes"
                content={order.customer_company.notes}
              />
            )}
            <Box>
              <Text fontSize="sm" mb="8px" fontWeight="md" color="gray.600">
                Order
              </Text>
              <OrderItemsTable
                orderItems={
                  editableOrder ? editableOrder.items : order.order_items
                }
                isEditable={true}
              />
            </Box>
          </VStack>
          {editableOrder && (
            <>
              <HStack mt="6">
                <ButtonGroup>
                  <Button leftIcon={<PlusIcon width="24px" />} onClick={onOpen}>
                    Add another product
                  </Button>
                </ButtonGroup>
                <Spacer />
                <ButtonGroup>
                  <Button onClick={onCancel}>Cancel</Button>
                  <Button
                    variant="primary"
                    onClick={onSave}
                    isLoading={isSaving}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </HStack>

              <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ProductModalContent
                  order={editableOrder}
                  defaultStatusFilter={''}
                  headerUpperChildren={
                    <Text px={6} mt={4} mb={6}>
                      Add another product
                    </Text>
                  }
                  footerChildren={
                    <ModalFooter>
                      <Button variant="primary" onClick={onClose}>
                        Done
                      </Button>
                    </ModalFooter>
                  }
                />
              </Modal>
            </>
          )}
        </Box>
      </Box>
    </DetailView>
  );
};

const DetailWrapper = (props: { children: React.ReactNode }) => {
  return <Box maxWidth="400px">{props.children}</Box>;
};

const ReadOnlyDetailWrapper = (props: {
  label: string;
  content: string;
  subText?: string;
  children?: ReactNode;
}) => {
  return (
    <DetailWrapper>
      <Text fontSize="sm" mb="8px" fontWeight="400" color="gray.600">
        {props.label}
      </Text>
      <Text fontSize="md">{props.content}</Text>
      {props.subText && (
        <Text fontSize="sm" decoration="line-through" color="gray.500" mt={2}>
          {props.subText}
        </Text>
      )}
      {props.children}
    </DetailWrapper>
  );
};

export default observer(EditOrder);
