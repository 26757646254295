import AppWrapper from '../../components/Layouts/AppWrapper';

import { Box } from '@chakra-ui/react';
import Integrations from './Integrations/Integrations';
import OneUpWrapper from '../../components/Layouts/OneUpWrapper';
import ActionBar from '../../components/ActionBar/ActionBar';
import OneUpScrollPanel from '../../components/Layouts/OneUpScrollPanel';

const IntegrationsPage = (): JSX.Element => {
  return (
    <AppWrapper>
      <OneUpWrapper headerChildren={<ActionBar title="Integrations" />}>
        <OneUpScrollPanel>
          <Box p="40px" bg="white">
            <Integrations />
          </Box>
        </OneUpScrollPanel>
      </OneUpWrapper>
    </AppWrapper>
  );
};

export default IntegrationsPage;
