import { observable, action, computed, makeObservable, remove } from 'mobx';
import * as Sentry from '@sentry/browser';

import { Order, BaseOrderProps, OrderProps } from './order';
import { api } from '../../api';

interface OrdersListInterface {
  [id: number]: Order;
}

export class OrderStore {
  user: any = [];
  pageLimit = 100;

  @observable ordersAreLoading: boolean = true;
  @observable currentSearchQuery: string = '';
  @observable currentDeliveryDateFrom: Date | null = null;
  @observable currentDeliveryDateTo: Date | null = null;
  @observable currentReceivedDateFrom: Date | null = null;
  @observable currentReceivedDateTo: Date | null = null;
  @observable currentStatusFilter?: string | null = 'new';
  @observable currentSortKey: string = 'date';
  @observable currentSortDirection: string = 'desc';
  @observable currentPage: number = 1;
  @observable currentGroupIdsForGroupSets: { [id: number]: number } = {};

  @observable totalCount?: number;
  @observable totalPages: number = 0;
  @observable nextPage?: number;
  @observable previousPage?: number;

  @observable ordersList: OrdersListInterface = {};
  @observable ordersIndex: (number | null)[] = [];
  @observable currentOrderId?: number;

  @observable saveButtonIsLoading: boolean = false;

  constructor(user?: any) {
    this.user = user;
    makeObservable(this);
  }

  @computed
  get orders() {
    return this.ordersIndex.map((key) => {
      if (typeof key === 'number') return this.ordersList[key];
    });
  }

  @computed
  get currentOrder(): any {
    if (this.currentOrderId) {
      return this.ordersList[this.currentOrderId];
    }
  }

  @computed
  get currentGroupIds() {
    return Object.values(this.currentGroupIdsForGroupSets);
  }

  @computed
  get currentGroupIdsString() {
    return this.currentGroupIds.join(',');
  }

  @computed
  get currentGroupIdsQueryString() {
    return (
      '&' +
      this.currentGroupIds.map((id) => `customer_group_ids[]=${id}`).join('&')
    );
  }

  @computed
  get currentlySelectedOrders(): Array<Order> {
    return Object.values(this.ordersList).filter((o) => o.isChecked);
  }

  @computed
  get currentBulkIds(): Array<number> {
    return this.currentlySelectedOrders.map((o) => o.id);
  }

  @action setUser = (user: any) => {
    this.user = user;
  };

  @action setOrders = (orders: any) => {
    this.totalCount = orders.total_count;
    this.totalPages = orders.total_pages;
    this.nextPage = orders.next_page;
    this.previousPage = orders.previous_page;
    this.ordersIndex = [];
    orders.results.forEach((order: OrderProps, index: number) => {
      if (this.ordersList[order.id]) {
        this.ordersList[order.id].addData(order);
      } else {
        this.ordersList[order.id] = new Order(this, order);
      }
      this.ordersIndex[index] = order.id;
    });
  };

  @action setCurrentOrder = (order?: OrderProps) => {
    if (this.currentOrder) {
      this.currentOrder.setIsCurrentOrder(false);
    }

    if (order) {
      const orderObject = this.ordersList[order.id];
      if (orderObject) {
        orderObject.addData(order);
        orderObject.setIsCurrentOrder(true);
      } else {
        this.ordersList[order.id] = new Order(this, order);
        this.ordersList[order.id].setIsCurrentOrder(true);
      }
      this.currentOrderId = order.id;
    } else {
      this.currentOrderId = undefined;
    }
  };

  @action setOrdersAreLoading = (value: boolean) =>
    (this.ordersAreLoading = value);
  @action setCurrentSearchQuery = (query: string) => {
    this.clearBulkIds();
    this.currentSearchQuery = query;
  };
  @action setCurrentDeliveryDateFrom = (value: Date | null) => {
    this.clearBulkIds();
    this.currentDeliveryDateFrom = value;
  };
  @action setCurrentDeliveryDateTo = (value: Date | null) => {
    this.clearBulkIds();
    this.currentDeliveryDateTo = value;
  };
  @action setCurrentReceivedDateFrom = (value: Date | null) => {
    this.clearBulkIds();
    this.currentReceivedDateFrom = value;
  };
  @action setCurrentReceivedDateTo = (value: Date | null) => {
    this.clearBulkIds();
    this.currentReceivedDateTo = value;
  };
  @action setCurrentStatusFilter = (value: string | null) => {
    this.clearBulkIds();
    this.currentStatusFilter = value;
  };
  @action setCurrentPage = (value: number) => (this.currentPage = value);
  @action setSaveButtonIsLoading = (value: boolean) =>
    (this.saveButtonIsLoading = value);
  @action setCurrentBulkIds = (currentBulkIds: Array<number>) => {
    if (currentBulkIds.length > 0) {
      currentBulkIds.forEach((id) => {
        this.ordersList[id].setIsChecked(true);
      });
    } else {
      this.clearBulkIds();
    }
  };

  @action clearBulkIds = () => {
    this.currentlySelectedOrders.forEach((order) => {
      order.setIsChecked(false);
    });
  };

  @action uncheckOrdersIfHidden = (orderIds: Array<number>) => {
    this.checkOrderIdsAgainstFilters(orderIds, (matchingOrderIds: any) => {
      let hiddenOrderIds = orderIds.filter(
        (x) => x && !matchingOrderIds.includes(x),
      );
      hiddenOrderIds.forEach((id) => {
        if (id && this.ordersList[id]) {
          this.ordersList[id].setIsChecked(false);
        }
      });
    });
  };

  @action setGroupIdForGroupSet = (id: number, groupSetId: number) => {
    this.clearBulkIds();
    this.currentGroupIdsForGroupSets[groupSetId] = id;
  };
  @action unsetGroupIdForGroupSet = (groupSetId: number) => {
    this.clearBulkIds();
    remove(this.currentGroupIdsForGroupSets, groupSetId.toString());
  };

  @action clearFilters = (
    options: { currentStatusFilter: string | null } = {
      currentStatusFilter: 'new',
    },
  ) => {
    this.currentPage = 1;
    this.currentSearchQuery = '';
    this.currentDeliveryDateFrom = null;
    this.currentDeliveryDateTo = null;
    this.currentReceivedDateFrom = null;
    this.currentReceivedDateTo = null;
    this.currentStatusFilter = options.currentStatusFilter;
    this.currentGroupIdsForGroupSets = {};
  };

  @action getOrdersParams = () => {
    const deliveryDateParam = dateFilterQueryString(
      'delivery_date',
      this.currentDeliveryDateFrom,
      this.currentDeliveryDateTo,
    );
    const receivedDateParam = dateFilterQueryString(
      'received_date',
      this.currentReceivedDateFrom,
      this.currentReceivedDateTo,
    );
    const searchParam =
      (this.currentSearchQuery && `&q=${this.currentSearchQuery}`) || '';
    const statusParam =
      (this.currentStatusFilter && `&status=${this.currentStatusFilter}`) || '';
    const sortKeyParam = `&sort_key=${this.currentSortKey}`;
    const sortDirectionParam = `&sort_direction=${this.currentSortDirection}`;
    const customerGroupIdsParam =
      this.currentGroupIds.length > 0 ? this.currentGroupIdsQueryString : '';

    return `${deliveryDateParam}${receivedDateParam}${searchParam}${statusParam}${sortKeyParam}${sortDirectionParam}${customerGroupIdsParam}`;
  };

  @action getOrders = (
    successCallback: () => any,
    errorCallback: () => any,
    sentDirection: 'incoming' | 'outgoing' = 'incoming',
  ) => {
    const path = `/v4/orders/${sentDirection}?limit=${this.pageLimit}&page=${
      this.currentPage
    }${this.getOrdersParams()}`;
    this.setOrdersAreLoading(true);

    api.get(path).then(async (response) => {
      if (response.status === 401) {
      } else {
        const data = await response.json();
        if (response.ok) {
          this.setOrders(data);
          if (successCallback) {
            successCallback();
          }
          this.setOrdersAreLoading(false);
          return data;
        } else {
          if (errorCallback) {
            errorCallback();
          }
          this.setOrdersAreLoading(false);
          return Promise.reject(data);
        }
      }
      this.setOrdersAreLoading(false);
    });
  };

  @action checkOrderIdsAgainstFilters = (
    orderIds: Array<number>,
    successCallback: (data: any) => void,
  ) => {
    const path = `/v4/orders/incoming_filter_check?order_ids=${orderIds.join(
      ',',
    )}&${this.getOrdersParams()}`;

    api.get(path).then(async (response) => {
      if (response.status === 401) {
      } else {
        const data = await response.json();
        if (response.ok) {
          successCallback(data['included_order_ids']);
        } else {
          return Promise.reject(data);
        }
      }
    });
  };

  @action getOrder = (
    orderId: string,
    successCallback: () => void,
    errorCallback?: () => void,
  ) => {
    api
      .get(`/v4/orders/${orderId}`)
      .then(async (response) => {
        if (response.status === 401) {
        } else {
          const data = await response.json();
          if (response.ok) {
            this.setCurrentOrder(data);
            if (successCallback) {
              successCallback();
            }
            return data;
          } else {
            if (errorCallback) {
              errorCallback();
            }
            return Promise.reject(data);
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        return error;
      });
  };

  @action setOrderStatus = (
    orderId: number,
    orderStatus: string,
    successCallback: (response: any) => any,
  ) => {
    api
      .put(
        `/v3/orders/${orderId}`,
        JSON.stringify({
          order: {
            status: orderStatus,
          },
        }),
      )
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          if (successCallback) {
            successCallback(response);
          }
          return data;
        } else {
          return Promise.reject(data);
        }
      });
  };

  @action updateOrder = (
    orderId: number,
    formData: any,
    successCallback: () => any,
    errorCallback: () => any,
  ) => {
    api
      .put(
        `/v4/orders/${orderId}`,
        JSON.stringify({
          ...formData,
        }),
      )
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          // If we have the order in our list already, replace it with the latest data.
          if (this.ordersList[orderId]) {
            this.ordersList[orderId] = new Order(this, data);
          }
          successCallback();
          return data;
        } else {
          errorCallback();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        errorCallback();
        return error;
      });
  };
}

const dateFilterQueryString = (
  argPrefix: string,
  fromDate: any,
  toDate: any,
) => {
  if (fromDate && toDate) {
    return `&${argPrefix}_from=${formatDate(
      fromDate,
    )}&${argPrefix}_to=${formatDate(toDate)}`;
  } else {
    return '';
  }
};

const formatDate = (fullDate: any) => {
  const day = fullDate.getDate();
  const month = fullDate.getMonth() + 1;
  const year = fullDate.getFullYear();

  const dateString = year + '-' + month + '-' + day;
  return dateString;
};

export default new OrderStore();
