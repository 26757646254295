import React from 'react';
import {
  Box,
  Flex,
  forwardRef,
  Link,
  LinkProps,
  Menu,
  MenuButtonProps,
  MenuList,
  Portal,
  useMenuButton,
} from '@chakra-ui/react';
import { Caption } from '../Typography/Typography';
import Triangle from '../Triangle/Triangle';
import { isUndefined } from 'lodash';

export interface NavItemProps extends LinkProps {
  label?: string;
  link?: string;
  icon?: React.ReactNode;
  isSelected?: boolean;
  isOpen?: boolean;
  children?: React.ReactNode;
  onOpen?: (e?: any) => void;
  onClose?: (e?: any) => void;
  arrow?: boolean;
}

const NavItemInner = (props: NavItemProps) => {
  const { label, link, icon, isSelected, isOpen, children, onOpen, ...rest } =
    props;

  return (
    <Link
      display="block"
      width="100%"
      py="3"
      color="gray.500"
      fontWeight="400"
      textDecoration="none"
      href={link}
      data-highlighted={isOpen ? 'true' : undefined}
      aria-current={isSelected ? 'page' : undefined}
      _hover={{
        color: 'green.500',
      }}
      _highlighted={{
        bg: 'whiteAlpha.400',
        color: 'gray.700',
      }}
      _activeLink={{
        fontWeight: '600',
        bg: 'gray.50',
        color: 'green.500',
        boxShadow:
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
      }}
      {...rest}
      onClick={(e) => {
        if (!isUndefined(onOpen)) {
          onOpen(e);
        }
      }}
    >
      <Flex direction="column" alignItems="center">
        {icon}
        {label && <Caption mt="0">{label}</Caption>}
      </Flex>
    </Link>
  );
};

interface NavItemMenuButtonProps extends MenuButtonProps {}

const NavItemMenuButton = forwardRef<NavItemMenuButtonProps, 'button'>(
  ({ children, ...rest }: NavItemMenuButtonProps, ref) => {
    const buttonProps = useMenuButton(rest, ref);
    return (
      <Box {...buttonProps} width="100%">
        {children}
      </Box>
    );
  },
);

const NavItem = (props: NavItemProps) => {
  const { children, arrow = true } = props;

  if (children) {
    const arrowWidth = 16;
    const { onOpen, onClose, isSelected } = props;
    return (
      <Menu
        placement="bottom"
        offset={[0, 12]}
        onOpen={() => (onOpen ? onOpen() : '')}
        onClose={() => (onClose ? onClose() : '')}
      >
        {({ isOpen }) => (
          <>
            <NavItemMenuButton>
              <NavItemInner isSelected={isSelected || isOpen} {...props} />
            </NavItemMenuButton>
            <Portal>
              <MenuList position="relative" zIndex="dropdown">
                {arrow ? (
                  <Triangle
                    color="white"
                    width={arrowWidth}
                    direction="up"
                    ratio={0.5}
                    position="absolute"
                    top={`-${arrowWidth / 2}px`}
                    left="32px"
                  />
                ) : null}
                {children}
              </MenuList>
            </Portal>
          </>
        )}
      </Menu>
    );
  }

  return <NavItemInner {...props} />;
};

export default NavItem;
