import { CrossIcon } from '../Icons/Icons';

import { Checkbox, Button, Stack } from '@chakra-ui/react';

const SelectAllCheckbox = ({
  selectedIds,
  setSelectedIds,
  currentPageIds,
}: any) => {
  const isChecked =
    currentPageIds && currentPageIds.length > 0
      ? currentPageIds.every((id: number) => selectedIds.includes(id))
      : false;

  const isIndeterminate =
    !isChecked &&
    (currentPageIds
      ? selectedIds.some((id: number) => currentPageIds.includes(id))
      : false);

  const isDisabled = !currentPageIds || currentPageIds.length == 0;

  const onChange = () => {
    if (isIndeterminate || isChecked) {
      deselectCurrentPageIDs();
    } else {
      selectCurrentPageIDs();
    }
  };

  const deselectCurrentPageIDs = () => {
    const newIds = selectedIds.filter((id: number) => {
      return currentPageIds.indexOf(id) < 0;
    });
    setSelectedIds(newIds);
  };

  const selectCurrentPageIDs = () => {
    setSelectedIds(selectedIds.concat(currentPageIds));
  };

  const clearAllSelectedIDs = () => {
    setSelectedIds([]);
  };

  return (
    <Stack
      ml="24px"
      spacing={{ base: '8px', xl: '25px' }}
      direction={{ base: 'column', xl: 'row' }}
      justifyContent={{ base: 'center', xl: 'flex-start' }}
      alignItems={{ base: 'flex-start', xl: 'center' }}
    >
      <Checkbox
        id="select-all"
        type="checkbox"
        borderColor="gray.500"
        onChange={onChange}
        isDisabled={isDisabled}
        aria-labelledby="Select all items in table"
        isChecked={isChecked}
        isIndeterminate={isIndeterminate}
      />
      {selectedIds && selectedIds.length && (
        <Button
          size="xs"
          colorScheme="blue"
          rightIcon={<CrossIcon h="8px" w="8px" />}
          onClick={clearAllSelectedIDs}
          mt="-4px"
        >
          {selectedIds.length} selected
        </Button>
      )}
    </Stack>
  );
};

export default SelectAllCheckbox;
