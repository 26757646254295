import { observer } from 'mobx-react';

import {
  Box,
} from '@chakra-ui/react';

import DateText from '../../../../components/Orders/DateText';
import Banner from '../../../../components/Banner/Banner';
import { RubbishIcon } from '../../../../components/Icons/IconsNew';
import DetailView from '../../../../components/DetailView/DetailView';
import OrdersPrintAction from '../../OrdersPrintAction';
import OrdersDownloadAction from '../../OrdersDownloadAction';
import OrderExportInvoiceAction from '../OrderExportInvoiceAction';
import StatusDot from '../../../../components/Orders/StatusDot';
import { OrderDetailTitleRow } from './OrderDetailTitleRow';
import OrderDetailFields from './OrderDetailFields';

export const ShowOutgoingOrder = ({ order }: any) => {
  const deletedStyles = order.isDeleted
    ? {
        opacity: 0.4,
        filter: 'grayscale(100%)',
      }
    : {};

  return (
    <DetailView
      leftActions={
        <>
          <Box as="span">
            <StatusDot status={order.readStatus} mr={2} />
            {order.read_by_supplier_at ? (
              <>
                Read: <DateText as={'span'} date={order.read_by_supplier_at} />
              </>
            ) : (
              'New'
            )}
          </Box>
        </>
      }
    >
      <Box mt="8">
        {order.isDeleted && (
          <Banner
            icon={<RubbishIcon width="24px" />}
            title="This order has been deleted"
            description="Someone else in your organisation is also working in this inbox, they've just deleted this order."
            mt="16px"
          />
        )}
        <OrderDetailTitleRow order={order} sentDirection="outgoing" />

        <Box height="100%" maxWidth="758px">
          <Box pb="10" {...deletedStyles}>
            <OrderDetailFields order={order} showEditedFieldStatuses={false} />
          </Box>
        </Box>
      </Box>
    </DetailView>
  );
};

export default observer(ShowOutgoingOrder);
