import React, { ForwardedRef } from 'react';
import {
  Tooltip,
  Tag as ChakraTag,
  TagProps as ChakraTagProps,
} from '@chakra-ui/react';

interface BasicTagProps extends ChakraTagProps {
  children: React.ReactNode;
}

const BasicTag = React.forwardRef(
  (
    { children, bg = 'gray.200', color = 'gray.900', ...rest }: BasicTagProps,
    ref: ForwardedRef<HTMLSpanElement>,
  ) => (
    <ChakraTag
      ref={ref}
      fontSize="xs"
      lineHeight="4"
      letterSpacing="wider"
      textTransform="uppercase"
      verticalAlign="middle"
      padding="0px 9px"
      bg={bg}
      color={color}
      {...rest}
    >
      {children}
    </ChakraTag>
  ),
);

export interface TagProps extends ChakraTagProps {
  children: React.ReactNode;
  tooltip?: string;
  tooltipIcon?: React.ReactNode;
}

const Tag = ({
  children,
  tooltip,
  tooltipIcon,
  bg = 'gray.200',
  color = 'gray.900',
  ...rest
}: TagProps) => {
  return (
    <>
      {tooltip ? (
        tooltipIcon ? (
          <>
            <BasicTag bg={bg} color={color} mr="8px" {...rest}>
              {children}
            </BasicTag>
            <Tooltip hasArrow label={tooltip} width="200px">
              {tooltipIcon}
            </Tooltip>
          </>
        ) : (
          <Tooltip hasArrow label={tooltip} width="200px">
            <BasicTag bg={bg} color={color} {...rest}>
              {children}
            </BasicTag>
          </Tooltip>
        )
      ) : (
        <BasicTag bg={bg} color={color} {...rest}>
          {children}
        </BasicTag>
      )}
    </>
  );
};

export default Tag;
