import { TextProps } from '@chakra-ui/layout';

import { Tooltip, Text, Box } from '@chakra-ui/react';
import { IconName, SizedIcon } from '../Icons/IconsNew';

type DetailStatusProps = {
  text: string;
  icon?: IconName;
  tooltip?: string;
  textProps?: TextProps;
};

const DetailStatus = (props: TextProps & DetailStatusProps): JSX.Element => {
  const { text, tooltip, color, icon, textProps, ...containerProps } = props;
  return (
    <Tooltip hasArrow label={tooltip}>
      <Box display="inline-flex" alignItems="center" {...containerProps}>
        <Text
          as="span"
          color={color}
          fontWeight="600"
          fontSize="sm"
          {...textProps}
        >
          {text}
        </Text>
        {icon && (
          <SizedIcon
            marginLeft="1.5"
            width="16px"
            height="16px"
            color={color}
            name={icon}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export { DetailStatus };

export default DetailStatus;
