import { Flex } from '@chakra-ui/react';

function TwoUpWrapper({ headerChildren, leftChildren, rightChildren }: any) {
  return (
    <>
      <Flex flexDirection="row">{headerChildren}</Flex>

      <Flex flexDirection="row" flexGrow="2" overflow="hidden">
        <Flex
          flexDirection="column"
          flex="1"
          borderRight="1px solid"
          borderColor="gray.200"
        >
          {leftChildren}
        </Flex>
        {rightChildren}
      </Flex>
    </>
  );
}

export default TwoUpWrapper;
