import { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Button, Flex } from '@chakra-ui/react';

import ProductForm from './ProductForm';
import Product from '../../../models/Product';
import { useErrorToast, useSuccessToast } from '../../../components/toast';
import { useProducts } from '../../../hooks/useStores';
import { useCustomerGroups } from '../../../hooks/useStores';
import DetailView from '../../../components/DetailView/DetailView';
import { useFlags } from 'launchdarkly-react-client-sdk';

const NewProduct = () => {
  const history = useHistory();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const {
    sourceCategories,
    createProduct,
    getProductsList,
    updatePricingTiers,
  } = useProducts();
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const flags = useFlags();
  const { pricingTiersGroupSet } = useCustomerGroups();

  const usesPricingTiers = flags.pricingTiers && pricingTiersGroupSet;

  const [editState, setEditState] = useState(Product.create());
  const [forceRenderCounter, setForceRenderCounter] = useState(0);

  const onSave = (afterSuccess: (productData: any) => void) => {
    setSaveIsLoading(true);
    editState.validate(sourceCategories);
    if (editState.isValid) {
      const { pricing_tiers, ...productFormState } = editState.formState;
      createProduct({ product: productFormState })
        .then((productData: any) => {
          const afterGetProductsList = () => {
            setSaveIsLoading(false);
            successToast({ description: 'Product created.' });
            afterSuccess(productData);
          };

          if (usesPricingTiers && productData.id != null) {
            updatePricingTiers(pricing_tiers, productData.id).then(() => {
              getProductsList(afterGetProductsList, afterGetProductsList);
            });
          } else {
            getProductsList(afterGetProductsList, afterGetProductsList);
          }
        })
        .catch(() => {
          setSaveIsLoading(false);
          errorToast({ description: 'Failed to create product.' });
        });
    } else {
      setSaveIsLoading(false);
      errorToast({
        title: 'Uh oh.',
        description: "There's an error on the page. Please try again.",
      });
    }
  };

  const handleSave = () =>
    onSave((productData: any) => {
      history.push(`/products/${productData.id}`);
    });

  const handleSaveAndAddAnother = () =>
    onSave(() => {
      setEditState(Product.create());
      setForceRenderCounter(forceRenderCounter + 1);
    });

  return (
    <DetailView
      rightActions={
        <>
          <Button
            color="gray.600"
            variant="ghost"
            onClick={() => {
              history.push('/products');
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            colorScheme="green"
            isLoading={saveIsLoading}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="primary"
            colorScheme="green"
            isLoading={saveIsLoading}
            onClick={handleSaveAndAddAnother}
          >
            Save and add another
          </Button>
        </>
      }
    >
      <Flex flexDirection="column" maxWidth="525px">
        <ProductForm
          key={forceRenderCounter}
          product={editState}
          isEditable={true}
        />
      </Flex>
    </DetailView>
  );
};

export default observer(NewProduct);
