import { Box, BoxProps } from '@chakra-ui/react';

interface CardProps extends BoxProps {}

const Card = ({ children, ...rest }: CardProps) => {
  return (
    <Box
      border="1px"
      borderColor="gray.200"
      borderRadius="24px"
      boxShadow="base"
      padding="24px"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Card;
