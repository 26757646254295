import { ReactElement, ReactNode } from 'react';

import {
  FormControl,
  Flex,
  Box,
  FormLabel,
  Text,
  BoxProps,
  FormLabelProps,
} from '@chakra-ui/react';

export type FormFieldWrapperProps = {
  children: ReactNode;
  fieldName: string;
  fieldLabel: string | ReactElement;
  subLabel?: string | ReactElement<any, any>;
  width?: string;
  fullWidth?: string;
  valueWrapperProps?: BoxProps;
  labelContainerProps?: BoxProps;
  labelProps?: FormLabelProps;
  error?: string;
} & BoxProps;

const FormFieldWrapper = ({
  children,
  fieldName,
  fieldLabel,
  subLabel,
  width,
  fullWidth = "184px",
  placeholder,
  valueWrapperProps,
  labelContainerProps,
  labelProps,
  error,
  ...containerProps
}: FormFieldWrapperProps): JSX.Element => {
  const fieldId = `field-${fieldName}`;
  return (
    <FormControl id={fieldId}>
      <Flex flexDirection="row" {...containerProps}>
        <Box width={fullWidth} minWidth={fullWidth} pr="12px" {...labelContainerProps}>
          <>
            <FormLabel mb={0} {...labelProps}>
              {typeof fieldLabel === 'string' ? (
                <Text fontSize="sm">{fieldLabel}</Text>
              ) : (
                fieldLabel
              )}
            </FormLabel>
            {subLabel && (
              <Text fontWeight="400" color="gray.600" fontSize="xs">
                {subLabel}
              </Text>
            )}
          </>
        </Box>
        <Box width={width} {...valueWrapperProps}>
          {!children && placeholder ? (
            <Text color="gray.400">{placeholder}</Text>
          ) : (
            children
          )}
        </Box>
      </Flex>
    </FormControl>
  );
};

export default FormFieldWrapper;
