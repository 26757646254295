import { observable, makeObservable } from 'mobx';
import { Instance } from 'mobx-state-tree';
import Order from '../../models/Order';

export class AddOrderStore {
  @observable order: Instance<typeof Order>;

  constructor(user?: any) {
    this.order = Order.create({ id: 'new' });
    makeObservable(this);
  }
}

export default new AddOrderStore();
