import React, { useState } from 'react';

import { useAuth } from './auth';
import editableOrderStore, {
  EditableOrderStore,
} from '../stores/orders/editable/editableOrderStore';

const EditableOrderContext =
  React.createContext<EditableOrderStore>(editableOrderStore);

const EditableOrderProvider = (props: any) => {
  const { user } = useAuth();
  const { children } = props;
  const [store, _] = useState(new EditableOrderStore(user));
  return (
    <EditableOrderContext.Provider value={store}>
      {children}
    </EditableOrderContext.Provider>
  );
};
const useEditableOrder = () => React.useContext(EditableOrderContext);
export { EditableOrderProvider, useEditableOrder };
