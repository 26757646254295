import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  FormControl,
  FormLabel,
  Heading,
  Text,
  VStack,
  Tooltip,
  Button,
  Flex,
  Box,
} from '@chakra-ui/react';

import ProductVisibilityField from './ProductVisibilityField';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import SourceCategoryForm from './SourceCategoryForm';
import DetailView from '../../../components/DetailView/DetailView';
import ProductActiveToggle from '../ProductActiveToggle';
import ProductDeleteAction from './ProductDeleteAction';
import { EditBoxIcon } from '../../../components/Icons/IconsNew';
import ShowPricingTable from './ShowPricingTable';
import { useCustomerGroups } from '../../../hooks/useStores';

const ShowProduct = ({ product }: any) => {
  const history = useHistory();
  const { orderFulfilmentMvpSupplierView, pricingTiers } = useFlags();
  const { pricingTiersGroupSet } = useCustomerGroups();
  const orderFulfilmentOn = ['on', 'on-include-legacy'].includes(
    orderFulfilmentMvpSupplierView,
  );

  const usesPricingTiers = pricingTiers && pricingTiersGroupSet;

  const onSaveProductVisibilityField = ({
    customerIds,
    customerGroupIds,
    hideFromCustomers
  }: {
    customerIds: string[];
    customerGroupIds: string[];
    hideFromCustomers: boolean;
  }) => {
    product.setCompanyIds(customerIds);
    product.setCustomerGroupIds(customerGroupIds);
  };

  function statusText() {
    if (product.active) {
      return 'Active';
    } else {
      return 'Inactive';
    }
  }

  return (
    <DetailView
      leftActions={
        <FormControl
          display="flex"
          h="29px"
          w="auto"
          flexDirection="row"
          alignItems="center"
          bg="gray.100"
          borderRadius="20px"
          p="4px 6px 4px 12px"
        >
          <FormLabel
            htmlFor="current-product-switch"
            fontWeight="500"
            m="0"
            mr="8px"
            fontSize="13px"
            cursor="pointer"
          >
            {statusText()}
          </FormLabel>
          <ProductActiveToggle
            fieldId="current-product-switch"
            product={product}
          />
        </FormControl>
      }
      rightActions={
        <>
          <ProductDeleteAction />
          <Tooltip hasArrow label="Edit product">
            <Button
              onClick={() => {
                history.push(`/products/${product.id}/edit`);
              }}
              leftIcon={<EditBoxIcon width="24px" />}
            >
              Edit
            </Button>
          </Tooltip>
        </>
      }
    >
      <Heading size="md" my="10">
        {product.name}
      </Heading>
      <Box bg="white" width="100%">
        <Flex flexDirection="column" maxWidth="525px">
          <VStack width="full" align="left" spacing="8" mb="10">
            <FormFieldWrapper
              fieldName="description"
              fieldLabel="Description"
              subLabel="Optional"
            >
              <Text>
                {product.description && product.description}
                {!product.description && '-'}
              </Text>
            </FormFieldWrapper>

            <FormFieldWrapper fieldName="category" fieldLabel="Category">
              <Text>
                {product.category && product.category.name}
                {!product.category && '-'}
              </Text>
            </FormFieldWrapper>
            <FormFieldWrapper
              fieldLabel="Allowed units"
              fieldName="allowed-units"
            >
              <Text>
                {product.units &&
                  product.units
                    .map((unit: any) => {
                      return unit.name;
                    })
                    .join(', ')}
                {product.units && !product.units.length && '-'}
              </Text>
            </FormFieldWrapper>

            <FormFieldWrapper
              fieldLabel="Default unit"
              fieldName="default-unit"
            >
              <Text>{product.unit && product.unit.name}</Text>
            </FormFieldWrapper>

            <FormFieldWrapper
              fieldLabel="Code"
              fieldName="product-code"
              subLabel="Optional, not visible to customers"
            >
              <Text>
                {product.product_code && product.product_code}
                {!product.product_code && '-'}
              </Text>
            </FormFieldWrapper>

            {!usesPricingTiers && (
              <FormFieldWrapper
                fieldLabel="Price"
                fieldName="price"
                subLabel="Optional"
              >
                <Text>
                  {product.price} /{' '}
                  {product.pricing_unit && product.pricing_unit.name}
                </Text>
              </FormFieldWrapper>
            )}
            <FormFieldWrapper
              fieldLabel="Wholesale supplier"
              fieldName="wholesale-supplier"
              subLabel="Optional, not visible to customers"
            >
              <Text>
                {product.wholesale_supplier && product.wholesale_supplier}
                {!product.wholesale_supplier && '-'}
              </Text>
            </FormFieldWrapper>

            <ProductVisibilityField
              customerIds={product.company_ids}
              customerGroupIds={product.customer_group_ids}
              visibleToCustomers={product.visible_to_customers}
              onSave={onSaveProductVisibilityField}
              isEditable={false}
            />

            {usesPricingTiers && <ShowPricingTable product={product} />}

            {orderFulfilmentOn && (
              <SourceCategoryForm currentProduct={product} isEditable={false} />
            )}
          </VStack>
        </Flex>
      </Box>
    </DetailView>
  );
};

export default observer(ShowProduct);
