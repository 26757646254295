import { observer } from 'mobx-react';

import {
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Portal,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverCloseButton,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { find } from 'lodash';

import { useCustomerGroups, useProducts } from '../../../hooks/useStores';
import ProductVisibilityField from './ProductVisibilityField';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import MaxLengthInput from '../../../components/MaxLengthInput/MaxLengthInput';
import SourceCategoryForm from './SourceCategoryForm';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { toJS } from 'mobx';
import PricingForm from './PricingForm';

const ProductForm = ({ product }: any) => {
  const { categories, units } = useProducts();
  const { pricingTiersGroupSet } = useCustomerGroups();

  const { orderFulfilmentMvpSupplierView, pricingTiers } = useFlags();
  const orderFulfilmentOn = ['on', 'on-include-legacy'].includes(
    orderFulfilmentMvpSupplierView,
  );
  const usesPricingTiers = pricingTiers && pricingTiersGroupSet;

  const hasError = (fieldName: string) => {
    return getErrorObject(fieldName) != undefined;
  };

  const getErrorMessage = (fieldName: string) => {
    return getErrorObject(fieldName).message;
  };

  const getErrorObject = (fieldName: string) => {
    return product.fieldErrors.find(
      (errorObj: any) => errorObj.fieldName == fieldName,
    );
  };

  const onSaveProductVisibilityField = ({
    customerIds,
    customerGroupIds,
    hideFromCustomers,
  }: {
    customerIds: string[];
    customerGroupIds: string[];
    hideFromCustomers: boolean;
  }) => {
    product.setCompanyIds(customerIds);
    product.setCustomerGroupIds(customerGroupIds);
    product.setHideFromCustomers(hideFromCustomers)
  };

  const headingText = () => {
    if (!product) {
      return 'New product';
    } else {
      return '';
    }
  };

  return (
    <form style={{ backgroundColor: 'white', marginBottom: '80px' }}>
      <Heading size="md" my="10">
        {headingText()}
      </Heading>
      <VStack width="full" align="left" spacing="8">
        <FormFieldWrapper fieldName="name" fieldLabel="Name">
          <Input
            type="text"
            defaultValue={product && product.name}
            onChange={(e) => product.setName(e.target.value)}
            maxLength={255}
            placeholder="eg. Asparagus (bunch)"
            isInvalid={hasError('name')}
          />
          {hasError('name') && (
            <Text mt="2" fontSize="sm" color="red">
              {getErrorMessage('name')}
            </Text>
          )}
        </FormFieldWrapper>

        <FormFieldWrapper
          fieldName="description"
          fieldLabel="Description"
          subLabel="Optional"
        >
          <MaxLengthInput
            id="description"
            label="Description"
            value={product && product.description}
            maxLength={255}
            onChange={(value) => product.setDescription(value)}
          />
          {hasError('description') && (
            <Text mt="2" fontSize="sm" color="red">
              {getErrorMessage('description')}
            </Text>
          )}
        </FormFieldWrapper>

        <FormFieldWrapper fieldName="category" fieldLabel="Category">
          <Select
            defaultValue={
              (product && product.category && product.category.id) ||
              'placeholder-category-id'
            }
            onChange={(e) => {
              const newCategory = find(
                categories,
                (c) => c.id === Number(e.target.value),
              );
              product.setCategory(newCategory);
            }}
            isInvalid={hasError('category_id')}
          >
            <option value="placeholder-category-id" disabled>
              Please select
            </option>
            {categories &&
              categories.map((category: any, i: number) => {
                return (
                  <option key={`category-${i}`} value={category.id}>
                    {category.name}
                  </option>
                );
              })}
          </Select>
          {hasError('category_id') && (
            <Text mt="2" fontSize="sm" color="red">
              {getErrorMessage('category_id')}
            </Text>
          )}
        </FormFieldWrapper>

        <FormFieldWrapper
          fieldName="units"
          fieldLabel="Units"
          subLabel={
            <Popover>
              <PopoverTrigger>
                <Link fontSize="xs">What's this?</Link>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    Select all units that customers can order. For example, if
                    you select 'kg' and 'each', customers could order 3kg of
                    bananas or 3 bananas.
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          }
        >
          <RadioGroup
            onChange={(value) => {
              const newUnit = find(units, (u) => u.id === parseInt(value, 10));
              product.setUnit(newUnit);
            }}
            value={product.unit?.id}
          >
            <VStack spacing="2" align="left" mt="6">
              <HStack spacing="0">
                <FormLabel fontSize="sm" width="160px" m="0">
                  Allowed unit(s)
                </FormLabel>
                <FormLabel fontSize="sm">Default unit</FormLabel>
              </HStack>
              {units &&
                units.map((unit: any) => {
                  const isChecked = product.checkedUnits.some((item: any) => {
                    return item === unit.id;
                  });

                  return (
                    <HStack spacing="0" key={`unit-${unit.id}`}>
                      <Checkbox
                        key={`allowedUnit-${unit.id}`}
                        width="160px"
                        isChecked={isChecked}
                        value={unit.id}
                        onChange={() => product.toggleUnit(unit)}
                      >
                        {unit.name}
                      </Checkbox>
                      <Radio
                        backgroundColor="white"
                        key={`defaultUnit-${unit.id}`}
                        value={unit.id}
                        isDisabled={!isChecked}
                      />
                    </HStack>
                  );
                })}
            </VStack>
          </RadioGroup>

          {hasError('unit_id') && (
            <Text mt="2" fontSize="sm" color="red">
              {getErrorMessage('unit_id')}
            </Text>
          )}
          {hasError('units') && (
            <Text mt="2" fontSize="sm" color="red">
              {getErrorMessage('units')}
            </Text>
          )}
        </FormFieldWrapper>

        <FormFieldWrapper
          fieldLabel="Code"
          fieldName="product-code"
          subLabel="Optional, not visible to customers"
        >
          <Input
            type="text"
            defaultValue={product && product.product_code}
            maxLength={255}
            onChange={(e) => product.setProductCode(e.target.value)}
          />
        </FormFieldWrapper>

        {!usesPricingTiers && (
          <FormFieldWrapper
            fieldLabel="Price"
            fieldName="price"
            subLabel="Optional"
          >
            <FormControl id="price">
              <HStack>
                <InputGroup w="132px">
                  <InputLeftAddon children="$"></InputLeftAddon>
                  <NumberInput
                    defaultValue={product && product.price}
                    min={0}
                    onChange={(value) => product.setPrice(value)}
                  >
                    <NumberInputField
                      borderLeftRadius="0"
                      borderRightRadius="md"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </InputGroup>
                <Text>/</Text>
                <Select
                  w="116px"
                  defaultValue={
                    (product &&
                      product.pricing_unit &&
                      product.pricing_unit.id) ||
                    'price-unit-placeholder'
                  }
                  onChange={(e) => {
                    const newUnit = find(
                      units,
                      (u) => u.id === parseInt(e.target.value, 10),
                    );
                    product.setPricingUnit(toJS(newUnit));
                  }}
                  isInvalid={hasError('pricing_unit_id')}
                >
                  <option value="price-unit-placeholder" disabled>
                    Unit
                  </option>
                  {units &&
                    units.map((unit: any, i: number) => {
                      return (
                        <option key={i} value={unit.id}>
                          {unit.name}
                        </option>
                      );
                    })}
                </Select>
              </HStack>
            </FormControl>

            {hasError('pricing_unit_id') && (
              <Text mt="2" fontSize="sm" color="red">
                {getErrorMessage('pricing_unit_id')}
              </Text>
            )}
          </FormFieldWrapper>
        )}

        <FormFieldWrapper
          fieldLabel="Wholesale supplier"
          fieldName="wholesale-supplier"
          subLabel="Optional, not visible to customers"
        >
          <Input
            type="text"
            defaultValue={product && product.wholesale_supplier}
            maxLength={255}
            onChange={(e) => product.setWholesaleSupplier(e.target.value)}
          />
        </FormFieldWrapper>

        <ProductVisibilityField
          customerIds={product.company_ids}
          customerGroupIds={product.customer_group_ids}
          visibleToCustomers={product.visible_to_customers}
          onSave={onSaveProductVisibilityField}
          isEditable={true}
        />

        {usesPricingTiers && <PricingForm product={product} />}

        {orderFulfilmentOn && (
          <SourceCategoryForm
            currentProduct={product}
            isEditable={true}
            hasError={hasError}
            getErrorMessage={getErrorMessage}
          />
        )}
      </VStack>
    </form>
  );
};

export default observer(ProductForm);
