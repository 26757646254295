import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useCustomerGroups, useCustomers } from '../../hooks/useStores';
import debounce from 'lodash/debounce';
import CustomerReferral from './CustomerReferral';

import {
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  ButtonGroup,
  Button as ChakraButton,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import ActionBar from '../../components/ActionBar/ActionBar';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DropdownFilter from '../../components/DropdownFilter/DropdownFilter';

const CustomersPageActions = () => {
  const {
    getCustomers,
    currentSearchQuery,
    currentStandingOrders,
    setCurrentSearchQuery,
    setCurrentStandingOrders,
    currentPage,
    setCurrentPage,
    currentGroupIds,
    currentGroupIdsForGroupSets,
    setGroupIdForGroupSet,
    unsetGroupIdForGroupSet,
  } = useCustomers();

  const { customerGroupSets, getCustomerGroupSets } = useCustomerGroups();
  const { orderFulfilmentMvpSupplierView } = useFlags();
  const orderFulfilmentOn = ['on', 'on-include-legacy'].includes(
    orderFulfilmentMvpSupplierView,
  );

  const handleSearch = useCallback(
    debounce((e: React.ChangeEvent) => {
      const target = e.target as HTMLInputElement;
      setCurrentPage(1);
      setCurrentSearchQuery(target.value);
    }, 500),
    [],
  );

  useEffect(() => {
    getCustomers(
      () => {},
      () => {},
    );
  }, [currentSearchQuery, currentPage, currentStandingOrders, currentGroupIds]);

  useEffect(() => {
    getCustomerGroupSets();
  }, []);

  return (
    <ActionBar
      title="Customers"
      rightChildren={
        <HStack>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              variant="outline"
              type="search"
              onChange={handleSearch}
              placeholder="Search"
              name="search"
              bg="white"
            />
          </InputGroup>
          <CustomerReferral />
        </HStack>
      }
    >
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <ButtonGroup variant="pageFilter">
          <ChakraButton
            isActive={!currentStandingOrders}
            onClick={() => setCurrentStandingOrders(false)}
          >
            All
          </ChakraButton>
          <ChakraButton
            isActive={currentStandingOrders}
            onClick={() => setCurrentStandingOrders(true)}
          >
            Standing orders
          </ChakraButton>
        </ButtonGroup>

        {orderFulfilmentOn && customerGroupSets && (
          <HStack wrap="wrap">
            {customerGroupSets.map((groupSet) => {
              if (groupSet.groups.length > 0) {
                const value = currentGroupIdsForGroupSets[groupSet.id]
                  ? currentGroupIdsForGroupSets[groupSet.id]
                  : null;
                return (
                  <DropdownFilter
                    key={groupSet.id}
                    value={value}
                    label={groupSet.name}
                    options={groupSet.groups}
                    onChange={(group: any) => {
                      if (group) {
                        setGroupIdForGroupSet(group.id, groupSet.id);
                      } else {
                        unsetGroupIdForGroupSet(groupSet.id);
                      }
                    }}
                  />
                );
              }
            })}
          </HStack>
        )}
      </Stack>
    </ActionBar>
  );
};

export default observer(CustomersPageActions);
