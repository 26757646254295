import {
  Input,
  Textarea,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Text,
  InputLeftElement,
  Divider,
  Portal,
  Box,
  Popover,
  PopoverContent,
  PopoverAnchor,
} from '@chakra-ui/react';
import { useLoadScript } from '@react-google-maps/api';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_API_LIBRARIES,
} from '../../../constants';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { AddressComponents } from '../../../models/Address';
import { SearchIcon } from '../../../components/Icons/IconsNew';

type AddressAutocompleteProps = {
  onChange?: (address: AddressComponents) => void;
  initialValue?: string;
  setUseManualAddress?: (useManualAddress: boolean) => void;
  autoFocus?: boolean;
};

const googleMapLibraries = GOOGLE_MAPS_API_LIBRARIES;

export const AddressAutocomplete = ({
  onChange = () => {},
  initialValue = '',
  setUseManualAddress = () => {},
  autoFocus = false,
}: AddressAutocompleteProps) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: googleMapLibraries,
  });
  const ref = useRef<HTMLDivElement>(null);
  const {
    placesService,
    getPlacePredictions,
    placePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: {
      input: '',
      componentRestrictions: {
        country: 'nz',
      },
    },
    debounce: 150,
  });

  const [value, setValue] = useState<string>(initialValue);
  const [isFocused, setIsFocused] = useState<boolean>(autoFocus);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  // Convert value and selection between the focus and unfocused states
  useEffect(() => {
    if (isFocused) {
      // Need to trigger the update to cursor position after the value has been updated
      Promise.resolve()
        .then(() => {
          setValue(value.replaceAll('\n', ', '));
        })
        .then(() => inputRef.current?.setSelectionRange(0, -1));
    } else {
      setValue(value.replaceAll(', ', '\n'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const onSelect = useCallback(
    (placeId: string, callback: () => void = () => {}) => {
      if (placeId) {
        placesService?.getDetails({ placeId }, (placeResult) => {
          if (placeResult?.address_components) {
            const addressComponents = placeResult.address_components.reduce(
              (adr: AddressComponents, component) => {
                if (component.types.includes('street_number')) {
                  adr.street = component.long_name;
                }
                if (component.types.includes('route')) {
                  adr.street = `${adr.street ? `${adr.street} ` : ''}${
                    component.long_name
                  }`;
                }
                if (component.types.includes('locality')) {
                  adr.city = component.long_name;
                }
                if (component.types.includes('sublocality_level_1')) {
                  adr.suburb = component.long_name;
                }
                if (component.types.includes('postal_code')) {
                  adr.post_code = component.long_name;
                }
                if (component.types.includes('subpremise')) {
                  adr.unit = component.long_name;
                }
                return adr;
              },
              {
                street: null,
                unit: null,
                suburb: null,
                city: null,
                post_code: null,
                lat: placeResult?.geometry?.location?.lat().toString() || null,
                lng: placeResult?.geometry?.location?.lng().toString() || null,
                place_id: placeResult?.place_id || null,
              },
            );
            let addressString = [
              addressComponents.street,
              addressComponents.suburb,
              addressComponents.city,
            ]
              .filter((val) => !!val)
              .join('\n');
            if (addressComponents.post_code) {
              addressString = `${addressString} ${addressComponents.post_code}`;
            }
            setValue(addressString);
            onChange(addressComponents);
            callback();
          }
        });
      }
    },
    [placesService, onChange],
  );

  const renderItem = ({
    matched_substrings,
    description,
    place_id,
  }: google.maps.places.QueryAutocompletePrediction) => {
    const itemTextElements = matched_substrings.map(
      (substringObj, index, array) => {
        const matchedSubstring = description.substring(
          substringObj.offset,
          substringObj.offset + substringObj.length,
        );
        const prev = array[index - 1];
        const prevUnmatchedString = description.substring(
          prev ? prev.offset + prev.length : 0,
          substringObj.offset,
        );
        return (
          <Fragment key={`prediction-${place_id}-${index}`}>
            <Text as="span">{prevUnmatchedString}</Text>
            <Text as="b">{matchedSubstring}</Text>
          </Fragment>
        );
      },
    );

    const lastSubstring = matched_substrings[matched_substrings.length - 1];
    if (lastSubstring) {
      itemTextElements.push(
        <Text as="span" key={`prediction-${place_id}-last`} color="gray.500">
          {description.substring(lastSubstring.offset + lastSubstring.length)}
        </Text>,
      );
    }

    return (
      <ListItem
        key={`place-pred-${place_id}`}
        _hover={{ bg: 'gray.100' }}
        m={1}
        p={2}
        pl={5}
        cursor="pointer"
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => {
          onSelect(place_id || '', () => setIsFocused(false));
        }}
      >
        {itemTextElements}
      </ListItem>
    );
  };

  return (
    <Popover isOpen={!!(isFocused && value)} autoFocus={false} matchWidth flip={false}>
      <PopoverAnchor>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.400" w={'17px'} />
          </InputLeftElement>
          <Textarea
            placeholder="Search address"
            as={isFocused ? Input : undefined}
            onChange={(e) => {
              setValue(e.target.value);
              getPlacePredictions({
                input: e.target.value,
                types: ['address'],
              });
            }}
            resize="none"
            minHeight={10}
            height={isFocused ? 10 : 28}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => setIsFocused(false)}
            value={value}
            autoFocus={isFocused}
            ref={inputRef}
            pl={8}
          />

          {isPlacePredictionsLoading && (
            <InputRightElement>
              <Spinner size="sm" />
            </InputRightElement>
          )}
        </InputGroup>
      </PopoverAnchor>

      {isFocused && (
        <PopoverContent w="100%">
          <List
            w="100%"
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="md"
            boxShadow="6px 5px 8px rgba(0,50,30,0.02)"
            background="white"
          >
            {placePredictions.map((prediction) => renderItem(prediction))}
            {placePredictions.length && (
              <Divider
                width={8}
                ml={6}
                borderWidth="1px"
                my={5}
                borderColor="gray.400"
              />
            )}
            {value && (
              <ListItem
                key="manual-entry-prompt"
                _hover={{ bg: 'gray.100' }}
                m={1}
                p={2}
                pl={5}
                cursor="pointer"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  setUseManualAddress(true);
                }}
              >
                Enter address manually
              </ListItem>
            )}
          </List>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default AddressAutocomplete;
