import { useEffect, useState } from 'react';
import { findIndex } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Instance } from 'mobx-state-tree';
import { FieldArray, Form, Formik } from 'formik';
import { v4 as uuid4 } from 'uuid';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Select,
  Switch,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';

import AddProductModal from '../../../components/AddProductModal/AddProductModal';
import DetailView from '../../../components/DetailView/DetailView';
import FormField from '../../../components/FormField/FormField';
import { PlusIcon } from '../../../components/Icons/IconsNew';
import EmptyTableRow from '../../../components/Table/EmptyTableRow';
import { H4, Footnote } from '../../../components/Typography/Typography';
import { useSourceCategories } from '../../../hooks/useStores';
import { useSuccessToast } from '../../../components/toast';
import SourceCategory from '../../../models/SourceCategory';
import EditSourceCategoryProductRow from './EditSourceCategoryProductRow';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';

interface EditSourceCategoryProps {
  sourceCategory?: Instance<typeof SourceCategory>;
}

interface SourceCategoryProduct {
  id: number | null;
  key: number | null;
  amount: string;
  product_id: number | undefined;
  sort_order: number;
  product: {
    id: number | undefined;
    name: string | undefined;
    product_code: string | null | undefined;
  };
  _destroy: boolean | null;
}

const EditSourceCategory = ({ sourceCategory }: EditSourceCategoryProps) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { updateSourceCategory, createSourceCategory } = useSourceCategories();
  const successToast = useSuccessToast();

  const [productErrorIds, setProductErrorIds] = useState<number[]>([]);
  const addProductErrorId = (id: number) => {
    setProductErrorIds([id, ...productErrorIds]);
  };
  const removeProductErrorId = (id: number) => {
    setProductErrorIds(productErrorIds.filter((i: number) => i !== id));
  };
  const hasProductError = productErrorIds.length > 0;

  useEffect(() => {
    if (sourceCategory?.productsCount === 0) {
      onOpen();
    }
  }, []);

  return (
    <Formik
      initialValues={
        sourceCategory
          ? sourceCategory.toFormInitialValues
          : {
              name: '',
              amounts_required: true,
              unit: 'kg',
              sort_order: 0,
              source_category_products_attributes:
                [] as SourceCategoryProduct[],
            }
      }
      validationSchema={Yup.object({
        name: Yup.string()
          .required('Required')
          .max(40, 'Name must be 40 characters or less'),
        amounts_required: Yup.boolean(),
        unit: Yup.string(),
        source_category_products_attributes: Yup.array().when(
          'amounts_required',
          {
            is: true,
            then: Yup.array().of(
              Yup.object().shape({
                amount: Yup.string().when('_destroy', {
                  is: (val: any) => !val,
                  then: Yup.string()
                    .required('Required')
                    .test('is-decimal', 'invalid decimal', (value?: string) =>
                      value ? /^(\d{0,5})(\.{1}\d{0,3})?$/.test(value) : false,
                    ),
                }),
              }),
            ),
          },
        ),
      })}
      onSubmit={async (values) =>
        sourceCategory
          ? await updateSourceCategory(sourceCategory, values).then(
              (sourceCategory: any) => {
                successToast({
                  description: `Source category "${sourceCategory.name}" has been updated.`,
                });
                history.push(`/source-categories/${sourceCategory.id}`);
              },
            )
          : createSourceCategory(values).then((sourceCategory: any) => {
              successToast({
                description: `New source category "${sourceCategory.name}" has been created.`,
              });
              history.push(`/source-categories/${sourceCategory.id}`);
            })
      }
    >
      {({ values, isSubmitting, isValid, handleReset, setFieldValue }) => {
        const sourceCategoryProducts =
          values.source_category_products_attributes;

        const unitErrorProducts = sourceCategoryProducts.filter(
          (p) => p.product_id && productErrorIds.includes(p.product_id),
        );
        const unitErrorString = unitErrorProducts
          .map((t) => `“${t.product?.name}”`)
          .join(', ');
        return (
          <DetailView
            as={Form}
            leftActions={
              <H4 my="3">
                {sourceCategory ? sourceCategory.name : 'New source category'}
              </H4>
            }
            rightActions={
              <>
                <Button
                  size="sm"
                  variant="secondary"
                  colorScheme="green"
                  onClick={() => {
                    handleReset();
                    sourceCategory
                      ? history.push(`/source-categories/${sourceCategory.id}`)
                      : history.push(`/source-categories`);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant="primary"
                  colorScheme="green"
                  disabled={isSubmitting || !isValid || hasProductError}
                >
                  Save
                </Button>
              </>
            }
          >
            <VStack width="full" align="left" spacing="8" my="10">
              {hasProductError && (
                <ErrorMessage
                  title="Products must have only 1 allowed unit"
                  description={
                    <>
                      <Footnote>
                        {unitErrorString} has more than 1 allowed units (eg,
                        “kg” and “each”).
                      </Footnote>
                      <Footnote>
                        You must either change the product’s settings to include
                        just 1 allowed unit, or remove the product from the
                        production category.
                      </Footnote>
                    </>
                  }
                />
              )}
              <FormField name="name" label="Name" />
              <FormField name="amounts_required" label="Item size">
                {({ field }) => (
                  <Switch size="lg" isChecked={field.value} {...field} />
                )}
              </FormField>
              <FormField name="unit" label="Unit">
                {({ field }) => (
                  <Select {...field} isDisabled={!values.amounts_required}>
                    <option value="placeholder-unit-id" disabled>
                      Please select
                    </option>
                    <option value="kg">kg</option>
                    <option value="L">L</option>
                  </Select>
                )}
              </FormField>

              <FieldArray name="source_category_products_attributes">
                {({ insert, move }) => {
                  const findItem = (key: string | number) => {
                    const item = sourceCategoryProducts.filter(
                      (c) => c.key === key,
                    )[0];
                    return {
                      item,
                      index: sourceCategoryProducts.indexOf(
                        item as SourceCategoryProduct,
                      ),
                    };
                  };
                  const moveItem = (key: string, toIndex: number) => {
                    const { index } = findItem(key);
                    move(index, toIndex);
                  };
                  const onDrop = () => {
                    sourceCategoryProducts.forEach((scp, index) => {
                      setFieldValue(
                        `source_category_products_attributes.${index}.sort_order`,
                        index,
                      );
                    });
                  };

                  return (
                    <>
                      <Table variant="orderitems" width="100%" size="sm">
                        <Thead>
                          <Tr>
                            <Th>Product</Th>
                            <Th width="10%">Code</Th>
                            {values.amounts_required && (
                              <Th width="30%">Item Size</Th>
                            )}
                            <Th p="0" width="64px" />
                          </Tr>
                        </Thead>
                        <Tbody>
                          {sourceCategoryProducts.filter(
                            (scp) => scp._destroy !== true,
                          ).length > 0 ? (
                            sourceCategoryProducts.map(
                              (product: any, index: number) => (
                                <EditSourceCategoryProductRow
                                  key={product.key}
                                  sourceCategoryProduct={product}
                                  index={index}
                                  productErrorIds={productErrorIds}
                                  findItem={findItem}
                                  moveItem={moveItem}
                                  onDrop={onDrop}
                                  removeProductErrorId={removeProductErrorId}
                                />
                              ),
                            )
                          ) : (
                            <EmptyTableRow
                              colSpan={values.amounts_required ? 5 : 4}
                            />
                          )}
                        </Tbody>
                      </Table>

                      <Box marginTop="24px">
                        <Button
                          leftIcon={<PlusIcon width="16px" />}
                          size="sm"
                          variant="secondary"
                          colorScheme="green"
                          onClick={onOpen}
                        >
                          Add product
                        </Button>
                      </Box>

                      {isOpen && (
                        <AddProductModal
                          isOpen={isOpen}
                          onClose={onClose}
                          addedProducts={sourceCategoryProducts
                            .filter((scp) => !scp._destroy)
                            .map((p: any) => p.product.id)}
                          onAdd={(product: any) => {
                            let index = findIndex(
                              sourceCategoryProducts,
                              (c: any) => c.product.id === product.id,
                            );

                            if (index === -1) {
                              index = sourceCategoryProducts.length;
                              insert(index, {
                                key: uuid4(),
                                amount: '',
                                product_id: product.id,
                                product: {
                                  id: product.id,
                                  name: product.name,
                                  product_code: product.product_code,
                                },
                                sort_order: 0,
                                _destroy: false,
                              });
                            } else {
                              setFieldValue(
                                `source_category_products_attributes.${index}._destroy`,
                                false,
                              );
                            }

                            if (product.units.length > 1) {
                              addProductErrorId(product.id);
                            }
                          }}
                        />
                      )}
                    </>
                  );
                }}
              </FieldArray>
            </VStack>
          </DetailView>
        );
      }}
    </Formik>
  );
};

export default EditSourceCategory;
