import React, { useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
} from '@chakra-ui/react';

export interface MaxLengthInputProps {
  id: string;
  label: string;
  maxLength: number;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

const MaxLengthInput = ({
  id,
  label,
  maxLength,
  value,
  placeholder,
  onChange,
}: MaxLengthInputProps) => {
  const [content, setContent] = useState<string>(value ? value : '');
  const isError = content.length > maxLength;
  return (
    <FormControl id={id} isInvalid={isError}>
      <Input
        placeholder={placeholder}
        value={content}
        onChange={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          setContent(target.value);
          onChange(target.value);
        }}
      />
      {isError ? (
        <FormErrorMessage fontSize="xs">
          {maxLength - content.length} characters remaining
        </FormErrorMessage>
      ) : (
        <FormHelperText fontSize="xs">
          {maxLength - content.length} characters remaining
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MaxLengthInput;
