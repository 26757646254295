import { Fragment, useEffect } from 'react';
import { useCurrentCompany } from '../../../contexts/currentCompany';
import {
  SubtextGroupItem,
  SubtextGroupItems,
  parseSubtextGroupItems,
  pluralizeString,
} from '../../../utils';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';

import {
  Button as ChakraButton,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useErrorToast } from '../../../components/toast';
import { CaretRightIcon, UsersIcon } from '../../../components/Icons/Icons';
import { useCustomerGroups } from '../../../hooks/useStores';
import { CustomerGroup } from '../../../models/CustomerGroup';
import SelectCustomersModal from '../../../components/SelectCustomersModal/SelectCustomersModal';
import { Caption } from '../../../components/Typography/Typography';
import { uniq } from 'lodash';

type ProductVisibilityFieldProps = {
  customerIds: number[];
  customerGroupIds?: number[];
  visibleToCustomers?: boolean;
  onSave?: (values: {
    customerIds: string[];
    customerGroupIds: string[];
    hideFromCustomers: boolean;
  }) => any;
  isEditable: boolean;
};

const ProductVisibilityField = ({
  customerIds,
  customerGroupIds,
  visibleToCustomers = true,
  onSave = () => {},
  isEditable,
}: ProductVisibilityFieldProps): JSX.Element => {
  const { customers, getCustomers } = useCurrentCompany();
  const { customerGroupSets, getCustomerGroupSets } = useCustomerGroups();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const errorToast = useErrorToast();

  useEffect(() => {
    if (!customers) {
      // Only load the customers once.
      getCustomers(onGetCustomersSuccess, onGetCustomersFail);
    }
    if (!customerGroupSets?.length) {
      getCustomerGroupSets(true);
    }
  }, []);

  const onGetCustomersSuccess = () => {};

  const onGetCustomersFail = () => {
    errorToast({ description: 'Failed to fetch customers.' });
  };

  const visibleToAll =
    visibleToCustomers && !customerIds?.length && !customerGroupIds?.length;

  const visibleOption = visibleToAll
    ? 'all'
    : visibleToCustomers
    ? 'some'
    : 'none';

  const selectedGroups = customerGroupIds?.length
    ? customerGroupSets.reduce<CustomerGroup[]>((prev, set) => {
        const selectedGroups = set.groups.filter((group) =>
          customerGroupIds?.includes(group.id),
        );
        return [...prev, ...selectedGroups];
      }, [])
    : [];

  const customerIdsFromGroups = uniq(
    selectedGroups.reduce<number[]>((prev, group) => {
      const customerIds: number[] = group.customersIndex
        .map((index) =>
          index != null ? group.customersList[index].customerId : -1,
        )
        .filter((id) => id !== -1);
      return [...prev, ...customerIds];
    }, []),
  );

  const allSelectedCustomerIds = uniq([
    ...customerIds,
    ...customerIdsFromGroups,
  ]);

  const getButtonText = () => {
    if (visibleToAll) {
      return 'All customers';
    } else if (!visibleToCustomers) {
      return 'No customers';
    } else {
      let customersText = `${allSelectedCustomerIds.length} ${
        customerGroupIds?.length ? 'total ' : ''
      }${pluralizeString('customer', allSelectedCustomerIds.length)}`;
      let groupsText = customerGroupIds?.length
        ? `${customerGroupIds.length} ${pluralizeString(
            'group',
            customerGroupIds.length,
          )}, `
        : '';

      return `${groupsText}${customersText}`;
    }
  };

  const getVisibilitySummaryItems = () => {
    if (visibleToAll) {
      return 'Visible to all customers.';
    } else if (!visibleToCustomers) {
      return 'Hidden from customers';
    }

    if (customers !== null) {
      const maxSampleSize = 4;

      const groupsListSubtextItems = selectedGroups.reduce<SubtextGroupItems>(
        (prev, group) => {
          if (group.customer_group_set_id === null) return prev;

          const prevListItem: SubtextGroupItem = prev[
            group.customer_group_set_id
          ]
            ? prev[group.customer_group_set_id]
            : { label: group.customer_group_set_name || '', itemLabels: [] };

          return {
            ...prev,
            [group.customer_group_set_id]: {
              ...prevListItem,
              itemLabels: [...prevListItem.itemLabels, group.name],
            },
          };
        },
        {},
      );

      let sampleCustomers = customerIds
        .slice(0, maxSampleSize)
        .map((customerId: number) => {
          return (
            customers.find((customer: any) => customer.id === customerId) || {}
          );
        });

      const customerSubtextItem = {
        label: 'Individuals',
        itemLabels: sampleCustomers.map((c) => c.name),
      };

      const groupsItems = parseSubtextGroupItems({
        subtextItems: Object.values(groupsListSubtextItems),
        itemlabel: 'group',
      });

      const customerItems = customerIds.length
        ? parseSubtextGroupItems({
            subtextItems: [customerSubtextItem],
            itemlabel: 'customer',
            maxSubItems: maxSampleSize,
            extraCountStart: customerIds.length - maxSampleSize,
          })
        : null;

      return (
        <>
          {groupsItems}
          {groupsItems !== null && customerItems ? ' ' : ''}
          {customerItems}
        </>
      );
    }
  };

  return (
    <FormFieldWrapper
      fieldLabel="Who can see this product"
      fieldName="customers"
    >
      {isEditable && (
        <>
          <ChakraButton
            variant="outline"
            color="gray.300"
            width="full"
            onClick={onOpen}
            isLoading={!customers}
            rightIcon={<CaretRightIcon color="gray.700" />}
            justifyContent="space-between"
          >
            <HStack color="gray.700">
              <UsersIcon />
              <Text>{getButtonText()}</Text>
            </HStack>
          </ChakraButton>
          <Caption mt="2" color="gray.500">
            {getVisibilitySummaryItems()}
          </Caption>

          {customers && customerGroupSets && isOpen && (
            <SelectCustomersModal
              customers={customers}
              groupSets={customerGroupSets}
              initialCustomerIDs={customerIds}
              initialGroupIDs={customerGroupIds}
              initialVisibilityOption={visibleOption}
              onSave={onSave}
              onClose={onClose}
              isOpen={isOpen}
            />
          )}
        </>
      )}
      {!isEditable && (
        <Text color={!customers ? 'gray.600' : 'default'}>
          {!customers && !customerGroupSets && 'Loading...'}
          {customers && (
            <>
              {getButtonText()}
              <Caption as="p" mt="2" color="gray.500">
                {getVisibilitySummaryItems()}
              </Caption>
            </>
          )}
        </Text>
      )}
    </FormFieldWrapper>
  );
};

export default ProductVisibilityField;
