import { Box } from '@chakra-ui/react';
import { HospoConnect } from '../Logos/Logos';
import * as React from 'react';

function AuthWrapper({ outterChildren, innerChildren, footerChildren }: any) {
  return (
    <Box bg="green.500" minH="100vh" py="12" px={{ base: '4', lg: '8' }}>
      <Box maxW="md" mx="auto">
        <HospoConnect mx="auto" h="20" mb={{ base: '10', md: '20' }} />
        {outterChildren}
        <Box
          bg="white"
          py="8"
          px={{ base: '4', md: '10' }}
          shadow="base"
          rounded={{ sm: 'lg' }}
          boxShadow="0px 25px 50px -12px rgb(0 0 0 / 25%)"
        >
          {innerChildren}
        </Box>
        {footerChildren}
      </Box>
    </Box>
  );
}
export default AuthWrapper;
