import { isNumber } from 'lodash';
import { types } from 'mobx-state-tree';

interface ProductProps {
  id: number;
  name: string;
  product_code: string;
}

interface SourceCategoryProductProps {
  id: number;
  amount: string;
  source_category_id: number;
  sort_order: number;
  product: ProductProps;
}

const SourceCategoryProduct = types
  .model('SourceCategoryProduct', {
    id: types.maybeNull(types.union(types.number, types.string)),
    amount: types.maybeNull(types.string),
    source_category_id: types.maybeNull(types.number),
    sort_order: types.maybeNull(types.number),
    product: types.maybeNull(
      types.model({
        id: types.number,
        name: types.string,
        product_code: types.maybeNull(types.string),
      }),
    ),
    _destroy: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get toFormInitialValues() {
      return {
        id: self.id,
        key: self.id,
        amount: self.amount || '',
        sort_order: self.sort_order || 0,
        product_id: self.product?.id,
        product: {
          id: self.product?.id,
          name: self.product?.name,
          product_code: self.product?.product_code,
        },
        _destroy: self._destroy,
      };
    },
    get formState() {
      const formState: { [k: string]: any } = {
        sort_order: 0,
      };

      if (self.id && isNumber(self.id)) formState.id = self.id;
      if (self.amount) formState.amount = self.amount;
      if (self.product) formState.product_id = self.product.id;
      if (self.source_category_id)
        formState.source_category_id = self.source_category_id;
      if (self._destroy) formState._destroy = self._destroy;

      return formState;
    },
    amountsRequired(categories: any) {
      return categories.some(
        (cat: any) =>
          cat.id === self.source_category_id && cat.amounts_required,
      );
    },
  }))
  .actions((self) => ({
    addData(props: SourceCategoryProductProps) {
      const { id, amount } = props;
      self.id = id;
      self.amount = amount;
    },
    setSourceCategoryId(sourceCategoryId: number) {
      self.source_category_id = sourceCategoryId;
    },
    setAmount(amount: string) {
      self.amount = amount;
    },
    markForDeletion() {
      self._destroy = true;
    },
  }));

export default SourceCategoryProduct;
