import { makeObservable, observable, action, computed } from 'mobx';

import { capitalizeString } from '../../utils';
import { CustomerGroup } from '../../models/CustomerGroup';
import { CustomerStore } from './customerStore';
import { StandingOrder } from './standingOrder';

export interface BaseCustomerProps {
  id: number;
  name: string;
  logo_url?: string;
  is_unlinked: boolean;
}

interface CustomerCustomerGroupProps {
  id: number;
  customer_group_id: number;
  customer_group_name: string;
  customer_group_set_id: number;
  customer_group_set_name: string;
}

export class CustomerCustomerGroup {
  @observable customer: Customer;
  @observable customerGroupId: number | '';
  @observable customerGroupName: string;

  constructor(customer: Customer, props: CustomerCustomerGroupProps) {
    this.customer = customer;

    const { customer_group_id, customer_group_name } = props;

    this.customerGroupId = customer_group_id;
    this.customerGroupName = customer_group_name;
  }

  @action set = (newGroup: CustomerGroup) => {
    this.customerGroupId = newGroup.id;
    this.customerGroupName = newGroup.name;
  };

  @action unset = () => {
    this.customerGroupId = '';
    this.customerGroupName = '';
  };
}

export interface CustomerProps extends BaseCustomerProps {
  about: string;
  email: string;
  phone?: string;
  website?: string;
  address?: string;
  default_address?: string;
  default_instructions?: string;
  notes?: string;
  can_edit_company_fields?: boolean;
  is_address_supplier_provided?: boolean;
  standing_order?: StandingOrder;
  customer_groups?: CustomerCustomerGroupProps[];
}

export class Customer {
  id: number;
  @observable store: CustomerStore;

  logo_url?: string;
  about?: string;
  address?: string;
  is_unlinked?: boolean;
  can_edit_company_fields?: boolean;
  is_address_supplier_provided?: boolean;

  @observable name?: string;
  @observable notes?: string;
  @observable email?: string;
  @observable phone?: string;
  @observable website?: string;
  @observable default_address?: string;
  @observable default_instructions?: string;
  @observable standingOrder?: StandingOrder;

  @observable customerGroupsByGroupSetId: {
    [id: number]: CustomerCustomerGroup;
  } = {};
  @observable formCustomerGroupsByGroupSetId: {
    [id: number]: CustomerCustomerGroup;
  } = {};

  @observable formName?: string;
  @observable formNotes?: string;
  @observable formEmail?: string;
  @observable formPhone?: string;
  @observable formWebsite?: string;
  @observable formAddress?: string;
  @observable formStandingOrder?: StandingOrder;

  @observable isChecked: boolean = false;
  @observable isCurrentCustomer: boolean = false;

  @observable isFullyLoaded: boolean = false;

  constructor(store: CustomerStore, props: BaseCustomerProps | CustomerProps) {
    this.store = store;
    const { id, name, logo_url, is_unlinked } = props;

    this.id = id;
    this.name = name;
    this.logo_url = logo_url;
    this.is_unlinked = is_unlinked;

    const isCustomerProps = 'email' in props;
    if (isCustomerProps) {
      this.addData(props);
    }

    makeObservable(this);
  }

  @computed get formState() {
    return {
      customer: {
        notes: this.formNotes || '',
        supplier_provided_address: this.formAddress,
      },
      company: {
        name: this.formName || '',
        email: this.formEmail || '',
        phone: this.formPhone || '',
        website: this.formWebsite || '',
        customer_group_ids:
          this.formCustomerGroupsByGroupSetId &&
          Object.values(this.formCustomerGroupsByGroupSetId).map(
            (group) => group.customerGroupId,
          ),
      },
      standing_order: this.formStandingOrder && {
        standing_order_items_attributes: this.formStandingOrder.items.map(
          (item: any) => {
            return {
              id: item.id,
              amount_mon: item.amountMon,
              amount_tue: item.amountTue,
              amount_wed: item.amountWed,
              amount_thu: item.amountThu,
              amount_fri: item.amountFri,
              amount_sat: item.amountSat,
              amount_sun: item.amountSun,
              buyable_type: capitalizeString(item.buyable.type),
              buyable_id: item.buyable[item.buyable.type].id,
              _destroy: item.destroy,
              unit_id: item.unit.id,
            };
          },
        ),
      },
    };
  }

  @action toggleChecked = () => {
    this.isChecked = !this.isChecked;
  };

  @action setIsCurrentCustomer = (value: boolean) => {
    this.isCurrentCustomer = value;
  };

  @action setName = (value: string) => (this.name = value);
  @action setFormName = (value: string) => (this.formName = value);

  @action setNotes = (value: string) => (this.notes = value);
  @action setFormNotes = (value: string) => (this.formNotes = value);

  @action setEmail = (value: string) => (this.email = value);
  @action setFormEmail = (value: string) => (this.formEmail = value);

  @action setPhone = (value: string) => (this.phone = value);
  @action setFormPhone = (value: string) => (this.formPhone = value);

  @action setWebsite = (value: string) => (this.website = value);
  @action setFormWebsite = (value: string) => (this.formWebsite = value);

  @action setFormAddress = (value: string) => (this.formAddress = value);

  @action addGroup = (props: CustomerCustomerGroupProps) => {
    this.formCustomerGroupsByGroupSetId[props.customer_group_set_id] =
      new CustomerCustomerGroup(this, props);
  };

  @action addData = (props: CustomerProps) => {
    const {
      name,
      about,
      email,
      phone,
      website,
      address,
      notes,
      is_unlinked,
      can_edit_company_fields,
      is_address_supplier_provided,
      standing_order,
      customer_groups,
      default_address,
      default_instructions,
    } = props;
    this.name = name;
    this.about = about;
    this.email = email;
    this.phone = phone;
    this.website = website;
    this.address = address;
    this.notes = notes;
    this.default_address = default_address;
    this.default_instructions = default_instructions;
    this.is_unlinked = is_unlinked;
    this.can_edit_company_fields = can_edit_company_fields;
    this.is_address_supplier_provided = is_address_supplier_provided;
    this.isFullyLoaded = true;

    this.customerGroupsByGroupSetId = {};
    this.formCustomerGroupsByGroupSetId = {};
    customer_groups?.forEach((cg: any) => {
      this.customerGroupsByGroupSetId[cg.customer_group_set_id] =
        new CustomerCustomerGroup(this, cg);
      this.formCustomerGroupsByGroupSetId[cg.customer_group_set_id] =
        new CustomerCustomerGroup(this, cg);
    });

    this.formNotes = notes;
    this.formName = name;
    this.formEmail = email;
    this.formAddress = default_address;
    this.formPhone = phone;
    this.formWebsite = website;

    if (standing_order) {
      this.standingOrder = new StandingOrder(this.store, standing_order);
      this.formStandingOrder = new StandingOrder(this.store, standing_order);
    } else {
      this.standingOrder = undefined;
      this.formStandingOrder = undefined;
    }
  };

  @action createEmptyStandingOrder = () => {
    this.standingOrder = new StandingOrder(this.store);
    this.formStandingOrder = new StandingOrder(this.store);
  };
}
