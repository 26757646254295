import { observer } from 'mobx-react-lite';

import { useCustomerGroups } from '../../../hooks/useStores';
import CustomerGroupSetBox from './CustomerGroupSetBox';

const CustomerGroupsList = (): JSX.Element => {
  const { customerGroupSets } = useCustomerGroups();

  return (
    <>
      {customerGroupSets.length !== 0 &&
        customerGroupSets.map((groupSet: any) => (
          <CustomerGroupSetBox key={groupSet.id} groupSet={groupSet} />
        ))}
    </>
  );
};

export default observer(CustomerGroupsList);
