import { useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AppWrapper from '../../components/Layouts/AppWrapper';
import TwoUpWrapper from '../../components/Layouts/TwoUpWrapper';
import { useCurrentUser } from '../../hooks/useStores';
import OrderDetail from './Detail/OrderDetail';
import BuyersOrdersList from './List/BuyersOrdersList';
import SupplierOrdersList from './List/SupplierOrdersList';
import OrdersPageActionBar from './OrdersPageActionBar';

const OrdersPage = ({
  match: {
    params: { orderId },
  },
}: RouteComponentProps<{ orderId: string }>): JSX.Element => {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const { isBuyer } = useCurrentUser();

  return (
    <AppWrapper>
      <TwoUpWrapper
        headerChildren={
          <>
            <OrdersPageActionBar errorContainerRef={errorContainerRef} />
          </>
        }
        leftChildren={
          isBuyer ? (
            <BuyersOrdersList errorContainerRef={errorContainerRef} />
          ) : (
            <SupplierOrdersList errorContainerRef={errorContainerRef} />
          )
        }
        rightChildren={
          <>
            <OrderDetail orderId={orderId} />
          </>
        }
      />
    </AppWrapper>
  );
};

export default OrdersPage;
