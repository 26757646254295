import { action, computed, makeObservable, observable } from 'mobx';
import * as Sentry from '@sentry/browser';

import { api } from '../api';
import { PaginationStore } from './paginationStore';

import { CustomerGroupSet } from '../models/CustomerGroupSet';
import { CustomerGroup } from '../models/CustomerGroup';

export interface CustomerProps {
  id: number;
  name: string;
  customer_id: number;
  sort_order: number;
}

export interface BaseCustomerGroupProps {
  id: number;
  name: string;
  sort_order: number;
  customer_count: number;
}

export interface CustomerGroupProps extends BaseCustomerGroupProps {
  customers: any[];
  customer_group_set_id: number;
  customer_group_set_name: string;
}

export class Customer {
  id: number;
  @observable name: string;
  @observable customerId: number;
  @observable sortOrder: number;
  @observable group: CustomerGroup;

  constructor(group: CustomerGroup, props: CustomerProps) {
    this.group = group;

    const { id, name, customer_id, sort_order } = props;
    this.id = id;
    this.name = name;
    this.customerId = customer_id;
    this.sortOrder = sort_order;
  }

  @computed
  get toFormInitialValues() {
    return {
      id: this.id,
      key: this.id,
      customer_id: this.customerId,
      name: this.name,
      sort_order: this.sortOrder,
      _destroy: false,
    };
  }

  @action
  addData = (props: CustomerProps) => {
    const { id, name, customer_id, sort_order } = props;
    this.id = id;
    this.name = name;
    this.customerId = customer_id;
    this.sortOrder = sort_order;
  };
}

export class CustomerGroupsStore {
  user: any = [];
  @observable customerGroupSetsList: { [id: number]: CustomerGroupSet } = {};
  @observable customerGroupSetsIndex: number[] = [];
  @observable pagination = new PaginationStore();
  @observable currentGroupId?: number;
  @observable currentGroupSetId?: number;

  constructor(user?: any) {
    this.user = user;
    makeObservable(this);
  }

  @computed
  get customerGroupSets() {
    return this.customerGroupSetsIndex.map((key) => {
      return this.customerGroupSetsList[key];
    });
  }

  @computed
  get currentGroup(): any {
    if (this.currentGroupSetId && this.currentGroupId) {
      return this.customerGroupSetsList[this.currentGroupSetId].groupsList[
        this.currentGroupId
      ];
    }
  }

  @computed
  get pricingTiersGroupSet(): any {
    return Object.values(this.customerGroupSetsList)?.find(
      (group) => group.set_type === 'pricing_tiers',
    );
  }

  @computed
  get currentGroupSet(): any {
    if (this.currentGroupSetId) {
      return this.customerGroupSetsList[this.currentGroupSetId];
    }
  }

  @action
  setCurrentGroupSet = (groupSetId: number) => {
    this.currentGroupSetId = groupSetId;
  };

  @action
  setCustomerGroupSets = (groupSets: any) => {
    this.pagination.setFromResponse(groupSets);
    groupSets.results.forEach((groupSet: any, index: number) => {
      if (!this.customerGroupSetsList[groupSet.id]) {
        this.customerGroupSetsList[groupSet.id] = new CustomerGroupSet(
          this,
          groupSet,
        );
      }
      this.customerGroupSetsIndex[index] = groupSet.id;
    });
  };

  @action
  setCurrentGroup = (group?: CustomerGroupProps) => {
    if (this.currentGroup) {
      this.currentGroup.setIsCurrent(false);
    }

    if (group) {
      const groupSetObject =
        this.customerGroupSetsList[group.customer_group_set_id];
      if (groupSetObject) {
        const groupObject = groupSetObject?.groupsList[group.id];
        groupObject.addData(group);
        groupObject.setIsCurrent(true);
      }

      this.currentGroupId = group.id;
      this.currentGroupSetId = group.customer_group_set_id;
    } else {
      this.currentGroupId = undefined;
    }
  };

  @action
  addCustomerGroupSet = (data: any) => {
    const groupSet = new CustomerGroupSet(this, data);
    this.customerGroupSetsList[groupSet.id] = groupSet;
    this.customerGroupSetsIndex[this.customerGroupSetsIndex.length] =
      groupSet.id;
  };

  @action
  createCustomerGroupSet = (formData: any) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          '/v4/customer_group_sets/?include_customer_count=true',
          JSON.stringify({
            customer_group_set: {
              ...formData,
            },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.addCustomerGroupSet(data);
            this.pagination.addToCount();
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };

  @action
  getCustomerGroupSets = (withCustomerGroupData = false) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/v4/customer_group_sets?include_customer_count=true&include_full_customer_groups=${withCustomerGroupData}`,
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.setCustomerGroupSets(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };

  @action
  getCustomerGroup = (groupId: string) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/v4/customer_groups/${groupId}`)
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.setCurrentGroup(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };
}
