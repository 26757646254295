import ProductsListView from "./ProductsListView";
import { observer } from 'mobx-react';
import { CrossIcon, StarIcon } from "../Icons/IconsNew";
import {
  Box,
  Input,
  HStack,
  InputGroup,
  Button,
  IconButton,
  InputRightAddon,
} from '@chakra-ui/react';
import { CheckIcon } from "@chakra-ui/icons";
import SelectUnit from "../SelectUnit/SelectUnit";
import { useState } from "react";
import FavouriteButton from "./FavouriteButton";

const OrderItemRow = observer(({ orderItem, remove, focusRef }: any) => {
  const {
    buyable: { product },
  } = orderItem;

  const [hovered, setHovered] = useState(false);

  return (
    <HStack
      justify="space-between"
      boxShadow="inset 0px -1px 0px #E5E7EB"
      px={6}
      py={3}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      _hover={{
        bg: 'gray.50',
      }}
    >
      <Box w="100%">
        <ProductsListView product={product} showFavourites={hovered} />
      </Box>
      {orderItem.id && (
        <Button
          variant="outline"
          width="100px"
          disabled={true}
          leftIcon={<CheckIcon />}
          mr="24px"
        >
          Added
        </Button>
      )}
      {!orderItem.id && (
        <HStack>
          {product.units.length > 1 ? (
            <InputGroup w={36} display="flex">
              <Input
                ref={focusRef}
                type="number"
                min={0}
                value={orderItem.amount || ''}
                onChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  orderItem.setAmount(Number(target.value));
                }}
                textAlign="right"
                placeholder="0"
                maxW={16}
              />
              <SelectUnit
                flex={1}
                ml={2}
                units={product.units}
                unit={orderItem.unit}
                onChange={(unit) => {
                  orderItem.setUnit(unit);
                }}
                w="72px"
              />
            </InputGroup>
          ) : (
            <InputGroup w={36} display="flex">
              <Input
                ref={focusRef}
                type="number"
                min={0}
                value={orderItem.amount  || ''}
                onChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  orderItem.setAmount(Number(target.value));
                }}
                maxW={20}
                textAlign="right"
                placeholder="0"
              />
              <InputRightAddon flex={1} justifyContent="center" children={orderItem.unit.name} />
            </InputGroup>
          )}

          <IconButton
            aria-label="Remove"
            variant="ghost"
            size="sm"
            icon={<CrossIcon />}
            isDisabled={orderItem.markedForDeletion}
            onClick={() => remove(orderItem)}
          />
        </HStack>
      )}
    </HStack>
  );
});


export default OrderItemRow;