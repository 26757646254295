import { useEffect, useState } from 'react';
import { useAccounting } from '../../contexts/accounting';
import { useAuth } from '../../contexts/auth';
import { useSuccessToast, useErrorToast } from '../toast';
import { PrimaryButton, TertiaryButton } from '../Button/Button';
import './legacyStyles.scss';

import {
  ButtonGroup,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';

const MatchContactsModal = ({
  contactId,
  contactName,
  isOpen,
  onSuccess,
  onClose,
}: any) => {
  const { user } = useAuth();
  const {
    currentAccountingConnection,
    getAccountingConnectorsContacts,
    accountingConnectorContacts,
    matchConnectorsContacts,
  } = useAccounting();
  const [selectedContactId, setSelectedContactId] = useState('');
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const accountingConnectorName =
    currentAccountingConnection && currentAccountingConnection.label;

  useEffect(() => {
    if (isOpen === true) {
      getAccountingConnectorsContacts(
        '/v4/accounting_connectors/contacts',
        user,
        () => {
          errorToast({
            description: `Could not retrieve ${accountingConnectorName} contacts. Please check your connection to ${accountingConnectorName}.`,
          });
          onClose();
        },
      );
    }
  }, [isOpen]);

  const selectActiveContactID = (id: string) => {
    setSelectedContactId(id);
    setSubmitButtonDisabled(false);
  };

  const matchSelectedContacts = (selectedContactId: string) => {
    onClose();
    setSubmitButtonLoading(true);

    matchConnectorsContacts(
      '/v4/accounting_connectors/contacts',
      user,
      selectedContactId,
      contactId,
      () => {
        setSubmitButtonLoading(false);
        if (onSuccess) {
          successToast({
            description: `Contact mapped. Sending Order to ${accountingConnectorName}.`,
          });
          onSuccess();
        } else {
          successToast({
            description: `Contact mapped. Please try sending to ${accountingConnectorName} again.`,
          });
        }
      },
    );
  };

  return (
    <Modal isOpen={isOpen && accountingConnectorContacts} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Match '{contactName}' to {accountingConnectorName}
        </ModalHeader>
        <ModalBody>
          <VStack spacing="3">
            <Text>
              Choose a {accountingConnectorName} contact to connect with{' '}
              {contactName}. (You'll only need to do this once, we'll save this
              match for all their future invoices.)
            </Text>
            <FormControl>
              <FormLabel>{accountingConnectorName} contacts</FormLabel>
              <Select
                defaultValue={''}
                onChange={(e) => {
                  selectActiveContactID(e.target.value);
                }}
              >
                <option value={''}>Select a contact</option>
                {accountingConnectorContacts &&
                  accountingConnectorContacts.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <TertiaryButton onClick={onClose}>Cancel</TertiaryButton>
            <PrimaryButton
              onClick={() => matchSelectedContacts(selectedContactId)}
              isLoading={submitButtonLoading}
              disabled={submitButtonDisabled}
            >
              Select
            </PrimaryButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MatchContactsModal;
