import { usePreview } from 'react-dnd-multi-backend';

import { DRAG_AND_DROP_TYPES } from '../constants';
import { EditCustomerRowPreview } from '../pages/CustomerGroupsPage/Detail/EditCustomerRow';
import { DraggableListRowPreview } from '../components/DraggableListRow/DraggableListRow';
import { EditSourceCategoryProductRowPreview } from '../pages/SourceCategoriesPage/Detail/EditSourceCategoryProductRow';

const DragAndDropPreview = () => {
  const preview = usePreview();
  if (!preview.display) {
    return null;
  }
  const { itemType, item, style, ref } = preview;
  switch (itemType) {
    case DRAG_AND_DROP_TYPES.SOURCE_CATEGORY_PRODUCT_ROW:
      return (
        <EditSourceCategoryProductRowPreview
          item={item}
          ref={ref}
          style={style}
        />
      );
    case DRAG_AND_DROP_TYPES.CUSTOMER_GROUP_CUSTOMER_ROW:
      return <EditCustomerRowPreview item={item} ref={ref} style={style} />;
    default:
      return <DraggableListRowPreview item={item} ref={ref} style={style} />;
  }
};

export default DragAndDropPreview;
