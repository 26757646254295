import Address from '../../../models/Address';
import { SnapshotOrInstance } from 'mobx-state-tree';
import {
  Body,
  Footnote,
  Label,
} from '../../../components/Typography/Typography';
import { Box, Divider, Flex, HStack, Text, Textarea } from '@chakra-ui/react';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import Tag from '../../../components/Tag/Tag';
import MapComponent from '../../../components/GoogleMap/MapComponent';
import EditableFormWrapper from '../../../components/Form/EditableFormWrapper';
import AddressAutocomplete from './AddressAutocomplete';
import { useState } from 'react';
import { Button } from '../../../components/Button/Button';
import FormikEditableFormWrapper from '../../../components/Form/FormikEditableFormWrapper';
import { SearchIcon, PhotoIcon } from '../../../components/Icons/IconsNew';

const DefaultTag = () => (
  <Tag
    bg="transparant"
    color="blue.500"
    colorScheme="blue"
    variant="outline"
    mt={2}
  >
    Default
  </Tag>
);

const manualAddressOverrides = {
  lat: null,
  lng: null,
  place_id: null,
};

const AddressField = ({
  fieldName,
  address,
  title,
  onChange = () => {},
  onRemove = () => {},
  onSetDefault = () => {},
  isEditable = false,
}: {
  fieldName?: string;
  address: SnapshotOrInstance<typeof Address>;
  title?: string;
  onChange?: (address: SnapshotOrInstance<typeof Address>) => void;
  onRemove?: () => void;
  onSetDefault?: () => void;
  isEditable?: boolean;
}) => {
  const isManualAddress = address.id && !(address.lat && address.lng);
  const [useManualAddress, setUseManualAddress] = useState(isManualAddress);
  const [autoFocus, setAutoFocus] = useState(false);

  const setManualValue = (fieldId: string, value: string) => {
    onChange({
      ...address,
      ...manualAddressOverrides,
      [fieldId]: value,
    });
  };

  const switchToManual = () => {
    setUseManualAddress(false);
    setAutoFocus(true);
    let fullAddress = [address.street, address.suburb, address.city]
      .filter((val) => !!val)
      .join(', ');
    if (address.post_code) {
      fullAddress = `${fullAddress} ${address.post_code}`;
    }
    onChange({
      ...address,
      full_address: fullAddress,
    });
  };

  return (
    <Box>
      {title && (
        <Label as={Text} fontSize="sm" fontWeight="500" mb={2}>
          {title}
        </Label>
      )}
      <Box
        border="solid 1px"
        borderRadius="8px"
        borderColor="gray.150"
        padding={4}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Flex
            flexDirection="column"
            gap={3}
            mr={4}
            width="100%"
            flex="1"
            alignItems="flex-start"
          >
            {useManualAddress ? (
              <>
                <FormikEditableFormWrapper
                  fieldLabel="Apartment / Unit"
                  fieldName={`${fieldName}.unit`}
                  subLabel="Optional"
                  placeholder="-"
                  valueWrapperProps={{ color: 'gray.600', fontSize: 'md' }}
                  fullWidth="160px"
                  value={address.unit || ''}
                  isEditable={isEditable}
                  onChange={(e) => setManualValue('unit', e.target.value)}
                />
                <FormikEditableFormWrapper
                  fieldLabel="Street address"
                  fieldName={`${fieldName}.street`}
                  placeholder="-"
                  valueWrapperProps={{ color: 'gray.600', fontSize: 'md' }}
                  fullWidth="160px"
                  value={address.street || ''}
                  isEditable={isEditable}
                  onChange={(e) => setManualValue('street', e.target.value)}
                />
                <FormikEditableFormWrapper
                  fieldLabel="Suburb"
                  fieldName={`${fieldName}.suburb`}
                  subLabel="Optional"
                  placeholder="-"
                  valueWrapperProps={{ color: 'gray.600', fontSize: 'md' }}
                  fullWidth="160px"
                  value={address.suburb || ''}
                  isEditable={isEditable}
                  onChange={(e) => setManualValue('suburb', e.target.value)}
                />
                <FormikEditableFormWrapper
                  fieldLabel="City / Town"
                  fieldName={`${fieldName}.city`}
                  placeholder="-"
                  valueWrapperProps={{ color: 'gray.600', fontSize: 'md' }}
                  fullWidth="160px"
                  value={address.city || ''}
                  isEditable={isEditable}
                  onChange={(e) => setManualValue('city', e.target.value)}
                />
                <FormikEditableFormWrapper
                  fieldLabel="Postcode"
                  fieldName={`${fieldName}.post_code`}
                  placeholder="-"
                  valueWrapperProps={{ color: 'gray.600', fontSize: 'md' }}
                  fullWidth="160px"
                  subLabel={address.is_default ? <DefaultTag /> : undefined}
                  value={address.post_code || ''}
                  isEditable={isEditable}
                  onChange={(e) => setManualValue('post_code', e.target.value)}
                />
                {isEditable && (
                  <Button
                    left="184px"
                    leftIcon={<SearchIcon w="24px" />}
                    onClick={() => switchToManual()}
                  >
                    Search for address
                  </Button>
                )}
                <Divider borderColor="gray.300" my={5} borderWidth="1.5px" />
              </>
            ) : (
              <>
                {isEditable ? (
                  <FormFieldWrapper
                    fieldLabel="Location"
                    fieldName="full_address"
                    subLabel={address.is_default ? <DefaultTag /> : undefined}
                    valueWrapperProps={{ w: '100%' }}
                    fullWidth="160px"
                  >
                    <AddressAutocomplete
                      initialValue={address.full_address || ''}
                      autoFocus={autoFocus}
                      onChange={(values) => onChange({ ...address, ...values })}
                      setUseManualAddress={(val) => setUseManualAddress(val)}
                    />
                  </FormFieldWrapper>
                ) : (
                  <EditableFormWrapper
                    fieldLabel="Location"
                    fieldName="full_address"
                    subLabel={address.is_default ? <DefaultTag /> : undefined}
                    valueWrapperProps={{ color: 'gray.600', fontSize: 'md' }}
                    fullWidth="160px"
                    value={address.full_address || ''}
                    isEditable={false}
                  />
                )}
              </>
            )}
            <FormikEditableFormWrapper
              fieldLabel="Instructions"
              fieldName={`${fieldName}.instructions`}
              EditComponent={Textarea}
              valueWrapperProps={{ color: 'gray.600', fontSize: 'md' }}
              fullWidth="160px"
              value={address.instructions || undefined}
              isEditable={isEditable}
              onChange={(e) =>
                onChange({ ...address, instructions: e.target.value })
              }
            />
          </Flex>

          {isManualAddress || !(address.lat && address.lng) ? (
            <Flex
              border="dashed 3px"
              borderRadius="16px"
              borderColor="gray.200"
              bgColor="gray.50"
              w="288px"
              h="288px"
              minW="288px"
              minH="288px"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              textAlign="center"
              padding={6}
            >
              <PhotoIcon w="40px" h="40px" color="gray.500" />
              <Body fontWeight="semibold" color="gray.500" mt={2}>
                Map unavailable
              </Body>
              <Footnote color="gray.500">
                Maps give suppliers more accurate delivery information
              </Footnote>
            </Flex>
          ) : (
            <Flex
              borderRadius="16px"
              borderColor="gray.200"
              bgColor="gray.50"
              w="288px"
              h="288px"
              minW="288px"
              minH="288px"
              justifyContent="center"
              alignItems="center"
            >
              <MapComponent
                lat={Number(address.lat)}
                lng={Number(address.lng)}
              />
            </Flex>
          )}
        </Box>
        {isEditable && (
          <>
            <Divider my={4} borderColor="gray.300" borderWidth="1.5px" />
            <HStack justifyContent="flex-end">
              {!address.is_default && (
                <Button
                  onClick={() => onSetDefault()}
                  colorScheme="gray"
                  minW="140px"
                >
                  Make default
                </Button>
              )}
              <Button colorScheme="red" minW="140px" onClick={() => onRemove()}>
                Remove
              </Button>
            </HStack>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AddressField;
